'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Specialties from './Specialties';
import store from '../../store';
import { Router, Route, browserHistory } from '../../../vendor/react-router.js';

(() => {
  let specialtiesElem = document.getElementById('js-specialties-search');

  if (!specialtiesElem) return;

  let props = {};
  props['keywordLabel'] = specialtiesElem.getAttribute('data-keyword-label');
  props['keywordPlaceholder'] = specialtiesElem.getAttribute('data-keyword-placeholder');
  props['alphabetLabel'] = specialtiesElem.getAttribute('data-alphabet-label');
  props['searchOr'] = specialtiesElem.getAttribute('data-search-or');
  props['paginationShowing'] = specialtiesElem.getAttribute('data-pagination-showing');
  props['paginationAll'] = specialtiesElem.getAttribute('data-pagination-all');
  props['paginationOf'] = specialtiesElem.getAttribute('data-pagination-of');
  props['paginationResults'] = specialtiesElem.getAttribute('data-pagination-results');
  props['linkName'] = specialtiesElem.getAttribute('data-link');
  props['noResults'] = specialtiesElem.getAttribute('data-no-results');

  const App = appProps => <Specialties {...{...appProps, ...props}} />

  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="*" component={App} />
      </Router>
    </Provider>,
    specialtiesElem
  );
})();
