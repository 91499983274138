'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import ResearcherListing from './ResearcherListing';
import reducer from '../../reducers/index';
import store from '../../store';
import { browserHistory, Route, Router, IndexRoute } from '../../../vendor/react-router.js';
import { fetchList } from '../../actions/researcherListing.js'
import UrlState from './urlState.js';

const { getUrlState, createBrowserHistoryChangeListener } = UrlState;

(() => {

  let dynamicElem = document.getElementById('js-researcher-listing');

  if (!dynamicElem) {
    return;
  }

  let props = {};
  props['taxonomyParent'] = dynamicElem.getAttribute('data-taxonomy-parent');
  props['contentType'] = JSON.parse(dynamicElem.getAttribute('data-content-types'));
  props['sortOrder'] = dynamicElem.getAttribute('data-sort-order');
  props['noResults'] = dynamicElem.getAttribute('data-no-results');
  props['linkName'] = dynamicElem.getAttribute('data-link-name');
  props['paginationShowing'] = dynamicElem.getAttribute('data-pagination-showing');
  props['paginationAll'] = dynamicElem.getAttribute('data-pagination-all');
  props['paginationOf'] = dynamicElem.getAttribute('data-pagination-of');
  props['filterLabel'] = dynamicElem.getAttribute('data-filter-label');
  props['filterDefaultValue'] = dynamicElem.getAttribute('data-filter-default-value');
  props['paginationResults'] = dynamicElem.getAttribute('data-pagination-results');

  // filter type is options, so we want to ensure a falsy value
  // if not provided
  let filterContentType = dynamicElem.getAttribute('data-filter-content-type');

  if (!filterContentType || filterContentType.trim() === "") {
    props['filterContentType'] = null;
  } else {
    props['filterContentType'] = JSON.parse(filterContentType);
  }

  const App = routerProps => {
    return <ResearcherListing {...props} location={routerProps.location} />
  }

  browserHistory.listen(createBrowserHistoryChangeListener(
    () => store.dispatch(fetchList())
  ));

  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="*" component={App} />
      </Router>
    </Provider>,
    dynamicElem
  );
})();
