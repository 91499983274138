'use strict';

import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from "prop-types";
import CompareButton from '../shared/CompareButton';
import Mlhpp from '../shared/Mlhpp';

export default class DoctorsItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      profile: PropTypes.shape({
        name: PropTypes.string.isRequired,
        picture: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        specialties: PropTypes.array.isRequired,
        referable: PropTypes.bool.isRequired,
        isMLHSDoc: PropTypes.bool.isRequired,
        isMLHPP: PropTypes.bool.isRequired,
        hasScheduling: PropTypes.bool
      }).isRequired,
      locations: PropTypes.array.isRequired,
      phone: PropTypes.string.isRequired,
      phoneLink: PropTypes.string.isRequired,
      appointmentLink: PropTypes.string.isRequired,
      appointmentLabel: PropTypes.string.isRequired
    }).isRequired,
    compareLink: PropTypes.string.isRequired,
    labels: PropTypes.shape({
      more: PropTypes.string.isRequired,
      less: PropTypes.string.isRequired,
      office: PropTypes.string.isRequired,
      compare: PropTypes.string.isRequired,
      add: PropTypes.string.isRequired,
      remove: PropTypes.string.isRequired,
      view: PropTypes.string.isRequired,
      appointmentCall: PropTypes.string.isRequired,
      appointmentFull: PropTypes.string.isRequired
    }).isRequired
  };

  constructor() {
    super();
    this.toggleClass = this.toggleClass.bind(this);
    this.state = { expanded: false };
  }

  toggleClass(e) {
    e.preventDefault();

    this.setState({ expanded: !this.state.expanded });
  }

  renderSpecialties(specialties) {
    return specialties.map((item, index) => {
      return (
        <li key={index}>
          {item.link ?
            <a href={item.link}>
              {item.label}
            </a> :
            <span>{item.label}</span>
          }
        </li>
      );
    });
  }

  renderLocations(locations) {
    return locations.map((item, index) => {
      return (
        <li key={index}>
          {item.link ?
            <a href={item.link}>{item.label}</a> :
            <span>{item.label}</span>
          }
        </li>
      );
    });
}

    renderAppointmentLink(item, labels)
    {
        // MLHS-815 temporarily disable online scheduling due to protocols following COVID-19
        if (item.profile.hasScheduling)
        {
            return (
                <span>
                    <a className='button' href={ item.profile.link + '#appointment'}>{item.appointmentLabel}</a>
                    <span className='tel'>
                        {labels.appointmentCall}&nbsp; <a href={item.phoneLink}>{item.phone}</a>
                    </span>
                </span>
            );
        }else{
            return (
                <span className='tel'>
                    <a href={item.phoneLink}>Call {item.phone}</a>
                    <br/>
                    to schedule an appointment
                </span>
            );
        }

        // MLHS-819 Re-enable Online Appointment
        //return (
        //    <span className='tel'>
        //        <a href={item.phoneLink}>Call {item.phone}</a>
        //        <br />
        //        to schedule an appointment
        //        </span>
        //);

    }


  render() {
    const { item, compareLink, labels } = this.props;

    return (
      <div className={'results__item' + (this.state.expanded ?
        ' is-expanded' : '')}>
        <div className='results__bio'>
          <div className='doctor-bio'>
            <div className='doctor-bio__image'>
              <a href={item.profile.link}>
                <img src={item.profile.picture}
                  alt={item.profile.name + ' Picture'} />
              </a>
              <span className='doctor-bio__more' onClick={this.toggleClass}>
                {this.state.expanded ? labels.less : labels.more}
              </span>
            </div>
            <div className='doctor-bio__info'>
              <h2 className='doctor-bio__title'>
                <a href={item.profile.link}>{item.profile.name}</a>
                {item.profile.isMLHPP && <Mlhpp labels={labels} />}
              </h2>
              <ul className='doctor-bio__specialties'>
                {this.renderSpecialties(item.profile.specialties)}
              </ul>
              <div className="ds-summary providersList" data-ds-id={item.profile.npi}></div> 
            </div>
          </div>
        </div>
        <div className='results__content'>
          <div className='results__locations'>
            <h4 className='results__label'>{labels.office}</h4>
            <ul className='results__locations__list'>
              {this.renderLocations(item.locations)}
            </ul>
          </div>
          <div className='results__compare'>
            <h4 className='results__label'>{labels.compare}</h4>
            <CompareButton addLabel={labels.add}
              removeLabel={labels.remove}
              doctorId={item.id} />
            <a className='button button--secondary'
              href={compareLink}>
              <i className='fa fa-list-ul'></i> {labels.view}
            </a>
            {item.profile.isMLHSDoc ?
              <div>
                <figure className='results__MLHSEmployee'>
                  <img src='/assets/images/mlhc.png' alt='employed by Main Line HealthCare' />
                  <figcaption>This doctor is employed by Main Line HealthCare, the multispecialty physician network of Main Line Health, including more than 350 community and hospital-based physicians.</figcaption>
                </figure>
              </div> :
              <div>
                &nbsp;
                </div>
            }
 
          </div>
        </div>
          {/* appointment section */}
          {item.profile.referable ?
            <div className='results__contact'>
                {this.renderAppointmentLink(item, labels)}
            </div> :
            <div className='results__contact inactive'>
                <span className='inactive__text'>{labels.appointmentFull}</span>
            </div>
          }
      </div>
    );
  }

    componentDidMount() {
        const script = document.createElement("script");

        script.src = "https://transparency.nrchealth.com/widget/v2/main-line-health/npi/lotw.js";
        script.async = true;

        document.body.appendChild(script);
    }

};
