'use strict';

import Actions from '../constants/actions';
import Application from '../constants/application';

const {
    PATIENT_CONDITION_CHANGED,
    PATIENT_TREATMENT_CHANGED,
    PATIENT_REQUEST_SUCCESS,
    PATIENT_REQUEST_FILTERS,
    PATIENT_REQUEST_SUCCESS_MORE,
    PATIENT_LOAD_MORE_UPDATE
} = Actions;

const { DefaultPagination } = Application;

const initialState = {
    list: [],
    listTotal: 0,
    pagination: {
        currentStart: 1,
        currentEnd: DefaultPagination
    },
    defaultCondition: "00000000-0000-0000-0000-000000000000",
    defaultTreatment: "00000000-0000-0000-0000-000000000000",
    filters: {
        condition: "00000000-0000-0000-0000-000000000000",
        treatment: "00000000-0000-0000-0000-000000000000"
    },
    loadMore: false,
    
    //"00000000-0000-0000-0000-000000000000"
};

export default (state = initialState, action) => {
    switch (action.type) {
        /*
        case PATIENT_FILTERS_CHANGED:
            return Object.assign({}, state, {
                filters: {
                    condition: action.condition,
                    treatment: action.treatment
                },
                pagination: {
                    currentStart: 1,
                    currentEnd: DefaultPagination
                },
                loadMore: true
            });
        */
        case PATIENT_CONDITION_CHANGED:
            return Object.assign({}, state, {
                filters: {
                    condition: action.condition,
                    treatment: action.treatment
                },
                pagination: {
                    currentStart: 1,
                    currentEnd: DefaultPagination
                },
                loadMore: true
            });
        case PATIENT_TREATMENT_CHANGED:
            return Object.assign({}, state, {
                filters: {
                    condition: action.condition,
                    treatment: action.treatment
                },
                pagination: {
                    currentStart: 1,
                    currentEnd: DefaultPagination
                },
                loadMore: true
            });
        case PATIENT_REQUEST_SUCCESS:
            return Object.assign({}, state, {
                list: action.list,
                listTotal: action.listTotal,
                loadMore: action.loadMore
            });

        case PATIENT_REQUEST_SUCCESS_MORE:
            return Object.assign({}, state, {
                list: state.list.concat(action.list),
                listTotal: action.listTotal,
                pagination: {
                    currentStart: action.start,
                    currentEnd: action.end
                },
                loadMore: action.loadMore
            });

        case PATIENT_LOAD_MORE_UPDATE:
            return Object.assign({}, state, {
                loadMore: action.LoadMore
            });

        case PATIENT_REQUEST_FILTERS:
            return Object.assign({}, state, {
                conditions: action.conditions,
                treatments: action.treatments, 
                defaultCondition: action.defaultCondition,
                defaultTreatment: action.defaultTreatment
            });

        default:
            return state;
    }
}
