'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Link, browserHistory} from '../../../vendor/react-router.js';
import Application from '../../constants/application';
import URL from '../../../common/urlManipulator';
import Pagination from '../shared/Pagination';
import SearchBar from './SearchBar';
import Select from './Select';
import DatePicker from './DatePicker';

const {
  DefaultPagination, 
  DefaultSmartphonePagination,
  DefaultSmartphoneBreakpoint, 
  DefaultKeywordFetchLength 
} = Application;

export default class NewsBlogArticleSearch extends Component {
  static propTypes = {
    searchState: PropTypes.shape({
      list: PropTypes.array.isRequired,
      listTotal: PropTypes.number.isRequired,
      pagination: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        currentStart: PropTypes.number.isRequired,
        currentEnd: PropTypes.number.isRequired

      }).isRequired,
      filters: PropTypes.shape({
        keyword: PropTypes.string,
        location: PropTypes.string,
        category: PropTypes.string
      }).isRequired
    }),
    locationsDictionary: PropTypes.array,
    collectionTypes: PropTypes.array.isRequired,
    fetchPaginatedList: PropTypes.func.isRequired,
    onKeywordChanged: PropTypes.func.isRequired,
    onLocationSelected: PropTypes.func,
    onLocationDeselected: PropTypes.func,
    onCategoryChanged: PropTypes.func.isRequired,
    onCategoryDeselected: PropTypes.func.isRequired,
    onDateChanged: PropTypes.func,
    onPaginationPageChanged: PropTypes.func.isRequired,
    keywordLabel: PropTypes.string.isRequired,
    keywordPlaceholder: PropTypes.string.isRequired,
    locationsLabel: PropTypes.string,
    locationsDefault: PropTypes.string,
    searchButton: PropTypes.string.isRequired,
    noResults: PropTypes.string.isRequired,
    categoryLabel: PropTypes.string.isRequired,
    fromDateLabel: PropTypes.string,
    toDateLabel: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.categorySelected = this.categorySelected.bind(this);
    this.locationSelected = this.locationSelected.bind(this);
    this.search = this.search.bind(this);
    this.restructureLocationsDictionary =
      this.restructureLocationsDictionary.bind(this);

    this.locationsDictionaryRestructure = this.restructureLocationsDictionary();
  }

  componentDidMount() {
    this.handleUrlChange(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((this.props.location.query !== nextProps.location.query) && !nextProps.searchState.fetching) {
      this.handleUrlChange(nextProps);
      return true;
    } else if (nextProps.searchState.fetching || !nextProps.searchState.fetching) {
      return true;
    } else {
      return false;
    }
  }

  handleUrlChange(nextProps) {
    const {fetchPaginatedList, onKeywordChanged, onCategoryChanged, onDateChanged, onPaginationPageChanged} = this.props;

    const query = nextProps.location.query;
    const keyword = query.keyword;
    const category = query.category;
    const location = query.location;
    const startDate = query.startDate;
    const endDate = query.endDate;
    const page = query.page || 1;
    const start = (page * DefaultPagination) - (DefaultPagination - 1);
    const end = page * DefaultPagination;


    onPaginationPageChanged(page, start, end);

    fetchPaginatedList(start, end, keyword, location, category, startDate, endDate, () => {
      if (keyword) { onKeywordChanged(keyword); }
      if (location) { this.locationSelected(location); }
      if (startDate) { onDateChanged('start', startDate); }
      if (endDate) { onDateChanged('end', endDate); }

      // null / undefined is an acceptable value for category
      onCategoryChanged(category);
    });
  }

  restructureLocationsDictionary() {
    const {locationsDictionary, locationsDefault} = this.props;

    if (!locationsDictionary) return false;
    let temp = locationsDictionary.map((item) => {
      return {
        name: item.Value,
        value: item.Key
      };
    });
    temp.unshift({
      name: locationsDefault,
      value: ''
    });
    return temp;
  }



  checkValidURLDate(date) {
    return date.match(/^\d{4}-\d{1,2}-\d{1,2}$/) && !!Date.parse(date);
  }

  getUrlParams() {
    const {collectionTypes, onDateChanged, fromDateLabel, toDateLabel}
      = this.props;
    let queryString = URL.getQueryString();
    let obj = {};
    let page, keyword, location, category, startDate, endDate;

    if (queryString === null) return obj;

    keyword = URL.getParameterByName('keyword', queryString);
    location = URL.getParameterByName('location', queryString);
    category = URL.getParameterByName('category', queryString);
    startDate = URL.getParameterByName('startDate', queryString);
    endDate = URL.getParameterByName('endDate', queryString);
    page = URL.getParameterByName('page', queryString);

    if (!!keyword) obj['keyword'] = keyword;

    obj['page'] = page && parseInt(page, 10) > 0 ? page : 1;

    if (this.locationsDictionaryRestructure) {
      for (let i=0; i < this.locationsDictionaryRestructure.length; i++) {
        if (this.locationsDictionaryRestructure[i].value === location) {
          obj['location'] = location;
          break;
        }
      }
    }

    for (let i=0; i < collectionTypes.length; i++) {
      if (collectionTypes[i].Key === category) obj['category'] = category;
    }

    if (fromDateLabel && onDateChanged && this.checkValidURLDate(startDate)) {
      obj['startDate'] = startDate;
    }
    if (toDateLabel && onDateChanged && this.checkValidURLDate(endDate)) {
      obj['endDate'] = endDate;
    }

    return obj;
  }

  locationSelected(location) {
    const {onLocationSelected, onLocationDeselected} = this.props;

    if (!!location) {
      return onLocationSelected(location);
    }

    onLocationDeselected();
  }

  getFilters() {
    const {searchState} = this.props;

    return {
      keyword: searchState.filters.keyword,
      location: searchState.filters.location,
      category: searchState.filters.category,
      startDate: searchState.filters.startDate,
      endDate: searchState.filters.endDate
    }
  }

  search(e) {
    if (e) e.preventDefault();
    const {fetchPaginatedList, onLoadMoreReset} = this.props;

    browserHistory.push({ pathname: this.props.location.pathname, query: { ...this.filtersWithValues(), page: 1 }});
  }

  categorySelected(index, e) {
    e.preventDefault();
    const {fetchPaginatedList, onCategoryChanged, onCategoryDeselected,
      onLoadMoreReset, collectionTypes} = this.props;

    let f = this.getFilters();

    if (f.category === collectionTypes[index].Key) {
      onCategoryDeselected();
    }

    onCategoryChanged(collectionTypes[index].Key);
    this.search();

  }

  onPaginationPageChanged(start, end, page) {
    getPaginatedList(start, end, letter, keyword, () => {
      onPaginationPageChanged(page, start, end);
    });
  }

  filtersWithValues() {
    // copy props.filters
    let filters = Object.assign({}, this.getFilters());
    Object.keys(filters).forEach( (k => {
      (filters[k] === null
      || filters[k] === undefined
      || filters[k] === "")
      && delete filters[k];
    }));

    return filters;

  }

  renderNewsByCategory(list) {
    const {searchState, collectionTypes} = this.props;
    return list.map((item, index) => {
      const isActive = collectionTypes[index].Key === searchState.filters.category;
      const href = {
        pathname: this.props.location.pathname,
        query: {
          ...this.filtersWithValues(),
          page: 1,
          category: collectionTypes[index].Key
        }
      };

      if (isActive) {
        delete href.query.category;
      }

      return (
        <li className='list__item list__item--term' key={index}>
          <Link to={href} className={isActive ? 'active' : ''}>{item.Value}</Link>
        </li>
      );
    });
  }

  renderPagination(position) {
    const {searchState, paginationShowing, paginationAll, paginationOf,
      paginationResults } = this.props;

    return <Pagination collectionTotal={searchState.listTotal}
                       currentPage={searchState.pagination.currentPage}
                       currentStart={searchState.pagination.currentStart}
                       currentEnd={searchState.pagination.currentEnd}
                       onChangePage={this.onPaginationPageChanged}
                       paginationCount={DefaultPagination}
                       paginationPosition={position}
                       paginationShowing={paginationShowing}
                       paginationAll={paginationAll}
                       paginationOf={paginationOf}
                       paginationResults={paginationResults}
                       />
  }

  render() {
    const {searchState, collectionTypes, onKeywordChanged, onDateChanged,
      keywordLabel, keywordPlaceholder, locationsLabel, searchButton, noResults,
      categoryLabel, fromDateLabel, toDateLabel, children} =
      this.props;

    return (
      <div className='l-block'>
        <div className='l-block--gray l-block--mobile sidebar--block sidebar--fields'>
          <div className='sidebar-fieldgroup'>
            <SearchBar labelName={keywordLabel}
                       placeholderName={keywordPlaceholder}
                       onTextChange={onKeywordChanged}
                       fetchWithText={() => this.search()}
                       inputValue={searchState.filters.keyword || ''}
                       hideSearchButton={true}
                       noAutomaticFetch={true} />
          </div>
            { (fromDateLabel && onDateChanged) &&
              <div className='sidebar-fieldgroup sidebar-fieldgroup--left'>
                <DatePicker labelName={fromDateLabel}
                            dateValue={searchState.filters.startDate || ''}
                            minDate='now'
                            maxDate={searchState.filters.endDate || null}
                            onDateChange={(value) =>
                              onDateChanged('start', value)} />
              </div>
            }
            { (toDateLabel && onDateChanged) &&
              <div className='sidebar-fieldgroup sidebar-fieldgroup--right'>
                <DatePicker labelName={toDateLabel}
                            dateValue={searchState.filters.endDate || ''}
                            minDate={searchState.filters.startDate || 'now'}
                            onDateChange={(value) =>
                              onDateChanged('end', value)} />
              </div>
            }
            { this.locationsDictionaryRestructure &&
              <div className='sidebar-fieldgroup'>
                <Select options={this.locationsDictionaryRestructure}
                        optionSelected={searchState.filters.location ||
                          this.locationsDictionaryRestructure[0].value}
                        onOptionSelect={this.locationSelected}
                        labelName={locationsLabel} />
              </div>
            }
          <button onClick={this.search}>{searchButton}</button>
        </div>
        <div className='container'>
          <main className='l-content'>
            { searchState.fetching &&
              <img className='spinner' src='/assets/images/gif/squares.gif' />
            }

            { this.renderPagination('top') }

            { searchState.list.length > 0 &&
              <div className='list list--lg'>
                <ul className='list__items'>
                  {children}
                </ul>
                { searchState.listTotal !== searchState.list.length &&
                  !searchState.loadMoreFetching &&
                  <div>
                    { this.renderPagination('bottom') }
                  </div>
                }
              </div>
            }
            { searchState.list.length === 0 && searchState.listTotal === 0 &&
              <p>{noResults}</p>
            }
          </main>

          <aside className='l-sidebar'>
            <div className='l-block--desktop sidebar--block sidebar--fields'>
              <div className='sidebar-fieldgroup'>
                <SearchBar labelName={keywordLabel}
                           placeholderName={keywordPlaceholder}
                           onTextChange={onKeywordChanged}
                           fetchWithText={() => this.search()}
                           inputValue={searchState.filters.keyword || ''}
                           hideSearchButton={true}
                           noAutomaticFetch={true} />
              </div>
                { (fromDateLabel && onDateChanged) &&
                  <div className='sidebar-fieldgroup sidebar-fieldgroup--left'>
                    <DatePicker labelName={fromDateLabel}
                                dateValue={searchState.filters.startDate || ''}
                                minDate='now'
                                maxDate={searchState.filters.endDate || null}
                                onDateChange={(value) =>
                                  onDateChanged('start', value)} />
                  </div>
                }
                { (toDateLabel && onDateChanged) &&
                  <div className='sidebar-fieldgroup sidebar-fieldgroup--right'>
                    <DatePicker labelName={toDateLabel}
                                dateValue={searchState.filters.endDate || ''}
                                minDate={searchState.filters.startDate || 'now'}
                                onDateChange={(value) =>
                                  onDateChanged('end', value)} />
                  </div>
                }
                { this.locationsDictionaryRestructure &&
                  <div className='sidebar-fieldgroup'>
                    <Select options={this.locationsDictionaryRestructure}
                            optionSelected={searchState.filters.location ||
                              this.locationsDictionaryRestructure[0].value}
                            onOptionSelect={this.locationSelected}
                            labelName={locationsLabel} />
                  </div>
                }
              <Link className="button" to={ { pathname: this.props.location.pathname, query: { ...this.filtersWithValues(), page: 1 }} }>{searchButton}</Link>
            </div>
            { collectionTypes.length !== 0 &&
                <div className='list list--sm sidebar--block'>
                  <div className='list__header'>
                    <h3 className='list__header__title'>{categoryLabel}</h3>
                  </div>
                  <ul className='list__items'>
                    {this.renderNewsByCategory(collectionTypes)}
                  </ul>
                </div>
            }
          </aside>
        </div>
      </div>
    );
  }
};
