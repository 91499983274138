'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';
import Application from '../constants/application.js';

const {
  REQUEST_CONDITIONS_AND_TREATMENTS_SUCCESS,
  REQUEST_CONDITIONS_AND_TREATMENTS_FETCHING,
  CONDITIONS_AND_TREATMENTS_LETTER_CHANGED,
  CONDITIONS_AND_TREATMENTS_KEYWORD_CHANGED,
  CONDITIONS_AND_TREATMENTS_PAGINATION_CHANGED,
  CONDITIONS_AND_TREATMENTS_KEYWORD_INITIATED,
  CONDITIONS_AND_TREATMENTS_RESET_SEARCH 
} = Actions;

const {
  SearchComponentClass
} = Application;

function conditionsAndTreatmentsSuccess(data) {
  return {
    type: REQUEST_CONDITIONS_AND_TREATMENTS_SUCCESS,
    list: data.results,
    listTotal: data.totalResults
  };
}

function conditionsAndTreatmentsFetching() {
  return {
    type: REQUEST_CONDITIONS_AND_TREATMENTS_FETCHING
  };
}

export function letterChanged(letter) {
  return {
    type: CONDITIONS_AND_TREATMENTS_LETTER_CHANGED,
    letter: letter
  };
};

export function keywordChanged(keyword) {
  return {
    type: CONDITIONS_AND_TREATMENTS_KEYWORD_CHANGED,
    keyword: keyword
  };
};

export function keywordSearchInitiated() {
  return {
    type: CONDITIONS_AND_TREATMENTS_KEYWORD_INITIATED
  };
};

export function paginationPageChanged(page, start, end) {
  return {
    type: CONDITIONS_AND_TREATMENTS_PAGINATION_CHANGED,
    page: page,
    start: start,
    end: end
  };
};

export function resetSearch() {
  return {
    type: CONDITIONS_AND_TREATMENTS_RESET_SEARCH
  };
}

// counter to track request responses and dissmiss all responses except the one
// from first request
let counter = 0;
export function fetchConditionsAndTreatmentsList(Start, End, StartsWith = null,
  Keyword = null, callback = null) {
  return dispatch => {
    let params = {
      Start: Start,
      End: End
    };

    if (StartsWith && !Keyword) params['StartsWith'] = StartsWith;
    if (Keyword && !StartsWith) params['Keyword'] = Keyword;

    // tell other components that results are being fetched
    $(SearchComponentClass).trigger('fetching');

    dispatch(conditionsAndTreatmentsFetching());
    request(++counter);

    function request(i) {
      $.ajax({
        url: '/api/search/conditionsandtreatments/get',
        cache: false,
        data: params,
        method: 'POST'
      }).done((response) => {
        if (i !== counter) return;
        dispatch(conditionsAndTreatmentsSuccess(response));
        // tell other components that results have been fetched
        $(SearchComponentClass).trigger('fetched');

        if (typeof callback === 'function') {
          callback();
        }
      });
    }
  };
}
