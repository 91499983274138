import Common                     from './common/common';
import Doctors                    from './redux/components/doctors';
import CompareButton              from './redux/components/compare-button';
import Locations                  from './redux/components/locations';
import DynamicListing             from './redux/components/dynamic-listing';
import Specialties                from './redux/components/specialties';
import ConditionsAndTreatments    from './redux/components/conditions-and-treatments';
import NewsArticle                from './redux/components/news-article';
import BlogArticle                from './redux/components/blog-article';
import Events                     from './redux/components/events';
import MegaMenu                   from './redux/components/mega-menu';
import MainSearch                 from './redux/components/main-search';
import PatientSpotlight           from './redux/components/patient-spotlight';
import RelatedSpecialties         from './common/relatedSpecialties.js';
import AddToGoogleCal             from "./google/AddToCalendar.js";
import TaskNavigation             from './task-navigation/index';
import Lib                        from './common/lib';
import multipageForm              from './common/multipageForm.js';
import LimrCommon                 from './common/limrCommon.js';
import epicDoctorAppointmentForm  from './common/epicDoctorAppointmentForm.js';
import ResearcherListing          from './redux/components/researcher-listing/index.js';
import ClinicalTrialListing       from './redux/components/clinicalTrial-listing';
import epicOpenSchedule from './common/epicOpenSchedule';
import vaccineOpenSchedule from './common/vaccineOpenSchedule';
import MLHCoveo                   from './common/MLHCoveo.js';
Common.init();
