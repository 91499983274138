"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Application from "../../constants/application";
import {
  fetchLocations,
  keywordChanged,
  loadMoreReset,
  zipCodeChanged,
  distanceChanged,
  incrementLoadMoreCounter,
  selectedItemChanged
} from "../../actions/locations";
import LocationsItem from "./LocationsItem";
import URL from "../../../common/urlManipulator";
import ZipCodeBar from "../shared/ZipCodeBar";
import Select from "../shared/Select";
import SearchBar from "../shared/SearchBar";

const {
  DefaultPagination,
  DefaultDistanceDropdown,
  DefaultZipCodeLength,
  DefaultKeywordFetchLength
} = Application;

@connect(state => ({
  locations: state.locations
}))
export default class Locations extends Component {
  static propTypes = {
    zipLabel: PropTypes.string.isRequired,
    zipPlaceholder: PropTypes.string.isRequired,
    zipLocation: PropTypes.string.isRequired,
    keywordLabel: PropTypes.string.isRequired,
    keywordPlaceholder: PropTypes.string.isRequired,
    listShowing: PropTypes.string.isRequired,
    listResults: PropTypes.string.isRequired,
    listDetails: PropTypes.string.isRequired,
    listDirections: PropTypes.string.isRequired,
    noResults: PropTypes.string.isRequired,
    listLoad: PropTypes.string.isRequired
  };

  constructor() {
    super();
    this.keywordChanged = this.keywordChanged.bind(this);
    this.keywordFetch = this.keywordFetch.bind(this);
    this.zipChanged = this.zipChanged.bind(this);
    this.zipFetch = this.zipFetch.bind(this);
    this.optionSelected = this.optionSelected.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.renderMapiFrame = this.renderMapiFrame.bind(this);
  }

  componentDidMount() {
    let params = this.getUrlParams();
    const { dispatch } = this.props;

    this.fetchPaginatedLocations(
      1,
      DefaultPagination,
      params.zip,
      params.distance,
      params.keyword,
      () => {
        if (params.keyword) this.keywordChanged(params.keyword);
        if (params.zip) this.zipChanged(params.zip);
        if (params.distance) dispatch(distanceChanged(params.distance));
      }
    );
  }

  renderMapiFrame(locationObj) {
    const apiKey = document.querySelector("#js-locations-search").getAttribute("data-map-key");
    // "AIzaSyDSBOetgyNIKj6gn5y_wp1_l4DIN7boAks";
    if (locationObj) {
      const location =
        locationObj.address1 +
        "++" +
        locationObj.city +
        "++" +
        locationObj.state +
        "++" +
        locationObj.zip;

      const src =
        "https://www.google.com/maps/embed/v1/place?key=" +
        apiKey +
        "&q=" +
        location;
      return (
        <div className="directions-map">
          <iframe frameBorder="0" src={src} allowFullScreen />
        </div>
      );
    } else {
      return;
    }
  }

  getUrlParams() {
    let queryString = URL.getQueryString(),
      keyword,
      zip,
      distance,
      obj = {},
      isZipValid = false,
      isDistanceValid = false;
    if (queryString === null) return obj;

    keyword = URL.getParameterByName("keyword", queryString);
    zip = URL.getParameterByName("zip", queryString);
    distance = URL.getParameterByName("distance", queryString);

    if (keyword && keyword.length >= DefaultKeywordFetchLength) {
      obj["keyword"] = keyword;
    }

    if (zip && zip.length === DefaultZipCodeLength && zip.match(/^\d+$/)) {
      isZipValid = true;
    }
    for (let i = 0; i < DefaultDistanceDropdown.length; i++) {
      if (DefaultDistanceDropdown[i].value == distance) isDistanceValid = true;
    }

    if (isZipValid && isDistanceValid) {
      obj["zip"] = zip;
      obj["distance"] = distance;
    }

    return obj;
  }

  fetchPaginatedLocations(start, end, zip, distance, keyword, callback) {
    const { dispatch } = this.props;

    dispatch(fetchLocations(start, end, zip, distance, keyword, callback));
  }

  composeQueryString(keyword, zip, distance) {
    let obj = {};
    if (keyword) obj["keyword"] = keyword;
    if (zip && zip.length === DefaultZipCodeLength && distance) {
      obj["zip"] = zip;
      obj["distance"] = distance;
    }
    let qs = URL.composeQueryString(obj);
    URL.addNewQueryString(qs);
  }

  getFilters() {
    const { locations } = this.props;

    let zip = locations.filters.radius.zipValidity
        ? locations.filters.radius.zip
        : null,
      keyword = locations.filters.keyword;

    if (keyword) {
      keyword = keyword.length >= DefaultKeywordFetchLength ? keyword : null;
    }

    return {
      zip: zip,
      distance: locations.filters.radius.distance,
      keyword: keyword
    };
  }

  keywordChanged(keyword) {
    const { dispatch } = this.props;

    dispatch(keywordChanged(keyword));
  }

  keywordFetch(keyword) {
    const { dispatch } = this.props;

    let f = this.getFilters();

    this.fetchPaginatedLocations(
      1,
      DefaultPagination,
      f.zip,
      f.distance,
      keyword,
      () => {
        dispatch(loadMoreReset());
        this.composeQueryString(keyword, f.zip, f.distance);
      }
    );
  }

  zipChanged(zip, zipValidity) {
    const { dispatch } = this.props;

    dispatch(zipCodeChanged(zip, zipValidity));
  }

  zipFetch() {
    const { dispatch } = this.props;

    let f = this.getFilters();

    this.fetchPaginatedLocations(
      1,
      DefaultPagination,
      f.zip,
      f.distance,
      f.keyword,
      () => {
        dispatch(loadMoreReset());
        this.composeQueryString(f.keyword, f.zip, f.distance);
      }
    );
  }

  optionSelected(option) {
    const { dispatch } = this.props;

    let f = this.getFilters();

    dispatch(distanceChanged(option));
    this.fetchPaginatedLocations(
      1,
      DefaultPagination,
      f.zip,
      option,
      f.keyword,
      () => {
        dispatch(loadMoreReset());
        this.composeQueryString(f.keyword, f.zip, option);
      }
    );
  }

  isZipDisabled() {
    const { locations } = this.props;

    return !(
      locations.filters.radius.zip && locations.filters.radius.zipValidity
    );
  }

  loadMore(e) {
    e.preventDefault();
    const { dispatch, locations } = this.props;

    let start = locations.loadMoreCounter * DefaultPagination + 1,
      end = locations.loadMoreCounter * DefaultPagination + DefaultPagination,
      f = this.getFilters();

    this.fetchPaginatedLocations(
      start,
      end,
      f.zip,
      f.distance,
      f.keyword,
      () => {
        dispatch(incrementLoadMoreCounter());
      }
    );
  }

  selectItem(index) {
    const { dispatch } = this.props;

    dispatch(selectedItemChanged(index));
  }

  renderList(list) {
    const { listDirections, listDetails, locations } = this.props;

    return list.map((item, index) => {
      return (
        <li
          className={
            "list__item list__item--location js-list__item--location" +
            (locations.selectedListItem === index ? " active" : "")
          }
          onClick={() => this.selectItem(index)}
          key={index}>
          <LocationsItem
            item={item}
            listDirections={listDirections}
            listDetails={listDetails}
          />
        </li>
      );
    });
  }

  render() {
    const {
      locations,
      zipLabel,
      zipPlaceholder,
      zipLocation,
      keywordLabel,
      keywordPlaceholder,
      noResults,
      listShowing,
      listResults,
      listLoad,
      listDirections
    } = this.props;

    let locationObj = locations.list[locations.selectedListItem]
      ? locations.list[locations.selectedListItem]
      : locations.list[0];

    return (
      <div>
        <div className="l-block">
          <div className="container">
            <div className="form-center">
              <div className="form-inline form-inline--last">
                <div className="form-left">
                  <span className="label">{zipLabel}</span>
                  <div className="form-item form-item--left">
                    <ZipCodeBar
                      placeholder={zipPlaceholder}
                      location={zipLocation}
                      zipValue={locations.filters.radius.zip || ""}
                      onZipChange={this.zipChanged}
                      fetchWithZip={this.zipFetch}
                    />
                  </div>
                  <div className="form-item form-item--right">
                    <Select
                      options={DefaultDistanceDropdown}
                      optionSelected={locations.filters.radius.distance}
                      onOptionSelect={this.optionSelected}
                      isDisabled={this.isZipDisabled()}
                    />
                  </div>
                </div>

                <div className="form-right">
                  <SearchBar
                    labelName={keywordLabel}
                    placeholderName={keywordPlaceholder}
                    onTextChange={this.keywordChanged}
                    fetchWithText={this.keywordFetch}
                    inputValue={locations.filters.keyword || ""}
                    hideSearchButton={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="l-block l-block--gray l-block--border-top directions">
          <div className="container">
            {locations.fetching && (
              <img src="/assets/images/gif/squares.gif" className="spinner" />
            )}
            {locations.list.length > 0 && (
              <div className="directions-result">
                <div className="pager pager--top">
                  <div className="pager__amount">
                    {listShowing} {locations.list.length} {listResults}
                  </div>
                </div>

                <div className="list list--sm">
                  <ol className="list__items">
                    {this.renderList(locations.list)}
                  </ol>
                </div>

                {locations.loadMoreFetching && (
                  <img
                    src="/assets/images/gif/squares.gif"
                    className="spinner"
                  />
                )}
                {locations.listTotal !== locations.list.length &&
                  !locations.loadMoreFetching && (
                  <div className="load-more">
                    <a className="button" href onClick={this.loadMore}>
                      {listLoad}
                    </a>
                  </div>
                )}
              </div>
            )}
            {locations.list.length === 0 && !locations.fetching && (
              <p>{noResults}</p>
            )}
          </div>
          {/* <GoogleMap
            mapClass="directions-map"
            place={locations.list[locations.selectedListItem]}
            label={listDirections}
          /> */}
          {this.renderMapiFrame(locationObj)}
        </div>
      </div>
    );
  }
}
