'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';

const {
  REQUEST_LOCATIONS_SUCCESS, 
  REQUEST_LOCATIONS_LOAD_MORE_SUCCESS,
  REQUEST_LOCATIONS_FETCHING, 
  REQUEST_LOCATIONS_LOAD_FETCHING,
  LOCATIONS_KEYWORD_CHANGED, 
  LOCATIONS_ZIP_CODE_CHANGED,
  LOCATIONS_LOAD_MORE_RESET, 
  LOCATIONS_DISTANCE_CHANGED,
  LOCATIONS_INCREMENT_LOAD_MORE, 
  LOCATIONS_SELECT_ITEM_CHANGED
} = Actions;

function locationsGetSuccess(data) {
  return {
    type: REQUEST_LOCATIONS_SUCCESS,
    list: data.results,
    listTotal: data.totalResults
  };
}

function locationsLoadMoreSuccess(data) {
  return {
    type: REQUEST_LOCATIONS_LOAD_MORE_SUCCESS,
    list: data.results,
    listTotal: data.totalResults
  };
}

function locationsFetching() {
  return {
    type: REQUEST_LOCATIONS_FETCHING
  };
}

function locationsLoadMoreFetching() {
  return {
    type: REQUEST_LOCATIONS_LOAD_FETCHING
  };
}

export function keywordChanged(keyword) {
  return {
    type: LOCATIONS_KEYWORD_CHANGED,
    keyword: keyword
  };
};

export function loadMoreReset() {
  return {
    type: LOCATIONS_LOAD_MORE_RESET
  };
};

export function zipCodeChanged(zipCode, zipValidity) {
  return {
    type: LOCATIONS_ZIP_CODE_CHANGED,
    zipCode: zipCode,
    zipValidity: zipValidity
  };
};

export function distanceChanged(distance) {
  return {
    type: LOCATIONS_DISTANCE_CHANGED,
    distance: distance
  };
};

export function incrementLoadMoreCounter() {
  return {
    type: LOCATIONS_INCREMENT_LOAD_MORE
  }
};

export function selectedItemChanged(index) {
  return {
    type: LOCATIONS_SELECT_ITEM_CHANGED,
    index: index
  }
};

// counter to track request responses and dissmiss all responses except the one
// from first request
let counter = 0;
export function fetchLocations(Start, End, Zip = null, Distance = null,
  Keyword = null, callback = null) {
  return dispatch => {
    let params = {
      Start: Start,
      End: End
    };

    if (Zip && Distance) {
      params['Zip'] = Zip;
      params['Radius'] = Distance
    }
    if (Keyword) params['Keyword'] = Keyword;

    if (Start !== 1) {
      dispatch(locationsLoadMoreFetching());
    } else {
      dispatch(locationsFetching());
    }
    request(++counter);

    function request(i) {
      $.ajax({
        url: '/api/search/locations/get',
        cache: false,
        data: params,
        method: 'POST'
      }).done((response) => {
        if (i !== counter) return;
        if (Start !== 1) {
          dispatch(locationsLoadMoreSuccess(response));
        } else {
          dispatch(locationsGetSuccess(response));
        }
        if (typeof callback === 'function') {
          callback();
        }
      });
    }
  };
};
