'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import Application from '../../constants/application';
import URL from '../../../common/urlManipulator';
import ResultsItem from '../shared/ResultsItem';
import SearchBar from '../shared/SearchBar';
import AlphabetPicker from '../shared/AlphabetPicker';
import Pagination from '../shared/Pagination';
import {browserHistory} from '../../../vendor/react-router.js';

const { 
  DefaultPagination, 
  DefaultKeywordFetchLength
} = Application;

export default class SpecialtiesConditionsTreatmentsSearch extends Component {
  static propTypes = {
    searchState: PropTypes.shape({
      list: PropTypes.array.isRequired,
      listTotal: PropTypes.number.isRequired,
      pagination: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        currentStart: PropTypes.number.isRequired,
        currentEnd: PropTypes.number.isRequired
      }).isRequired,
      filters: PropTypes.object.isRequired,
    }).isRequired,
    getPaginatedList: PropTypes.func.isRequired,
    onKeywordChanged: PropTypes.func.isRequired,
    onLetterChanged: PropTypes.func.isRequired,
    onKeywordSearchInitiated: PropTypes.func.isRequired,
    onResetSearch: PropTypes.func.isRequired,
    onPaginationPageChanged: PropTypes.func.isRequired,
    keywordLabel: PropTypes.string.isRequired,
    keywordPlaceholder: PropTypes.string.isRequired,
    alphabetLabel: PropTypes.string.isRequired,
    searchOr: PropTypes.string.isRequired,
    paginationShowing: PropTypes.string.isRequired,
    paginationAll: PropTypes.string.isRequired,
    paginationOf: PropTypes.string.isRequired,
    paginationResults: PropTypes.string.isRequired,
    linkName: PropTypes.string.isRequired,
    noResults: PropTypes.string.isRequired
  };

  constructor() {
    super();
    this.letterSelected = this.letterSelected.bind(this);
    this.fetchWithKeyword = this.fetchWithKeyword.bind(this);
    this.paginationPageChanged = this.paginationPageChanged.bind(this);
  }

  componentDidMount() {
    this.handleUrlChange(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((this.props.location.query !== nextProps.location.query) && !nextProps.searchState.fetching) {
      this.handleUrlChange(nextProps);
      return true;
    } else if (nextProps.searchState.fetching || !nextProps.searchState.fetching) {
      return true;
    } else {
      return false;
    }
  }

  handleUrlChange(nextProps) {
    const {getPaginatedList, onKeywordChanged, onLetterChanged, onPaginationPageChanged} = this.props;

    let params = {
      letter: nextProps.location.query.letter,
      keyword: nextProps.location.query.keyword
    };

    let page = Number(nextProps.location.query.page || 1);

    if (!params.letter && !params.keyword) {
      return;
    }

    if (params.keyword) {
      onKeywordChanged(params.keyword);
    }

    if (params.letter) {
      onLetterChanged(params.letter);
    }

    let start = (page * 10) - 9;
    let end = page * 10;

    onPaginationPageChanged(page, start, end);

    getPaginatedList(start, end, params.letter, params.keyword, () => { 
      window.scrollTo(0, 200);
    });
  }

  getUrlParams() {
    let url = URL.getQueryString(), keyword, letter;
    if (url === null) return {};

    keyword = this.props.location.query.keyword;
    letter = this.props.location.query.url;

    if (keyword && !letter) {
      let obj = {};

      if (keyword.length >= DefaultKeywordFetchLength) {
        obj['keyword'] = keyword;
      }

      return obj;
    }

    if (letter && !keyword) {
      let obj = {};

      if (letter.length === 1 && letter.toUpperCase().match(/[A-Z]/i)) {
        obj['letter'] = letter.toUpperCase();
      }

      return obj;
    }

    return {};
  }

  keywordComposeQueryString(value) {
    let obj = {};

    if (!!value) {
      obj['keyword'] = value;
    }

    let qs = URL.composeQueryString(obj);
  }

  letterSelected(letter) {
    const {getPaginatedList, onLetterChanged} = this.props;

    onLetterChanged(letter);

    getPaginatedList(1, DefaultPagination, letter, undefined, () => {
      let qs = URL.composeQueryString({letter: letter});
    });
  }

  fetchWithKeyword(value) {
    const {getPaginatedList, onKeywordSearchInitiated, onResetSearch} =
      this.props;

    if (value.length === 0) {
      onResetSearch();
      return this.keywordComposeQueryString(value);
    }

    onKeywordSearchInitiated();

    getPaginatedList(1, DefaultPagination, undefined, value, () => {
      this.props.router.push({
        pathname: this.props.location.pathname,
        query: {
          keyword: value,
          page: 1
        }
      });
    });
  }

  paginationPageChanged(start, end, page) {
    const {searchState, getPaginatedList, onPaginationPageChanged} = this.props;
    let letter = searchState.filters.selectedLetter,
        keyword = searchState.filters.keyword;

    // this shouldn't happen, this is just another safety code
    if (letter && keyword) {
      letter = keyword = null;
    }

    getPaginatedList(start, end, letter, keyword, () => {
      onPaginationPageChanged(page, start, end);
    });
  }

  renderSpecialtyList(list) {
    const {linkName} = this.props;

    return list.map((item, index) => {
      return (
        <li className='list__item' key={index}>
          <ResultsItem item={item} readMoreLabel={linkName} />
        </li>
      );
    });
  }

  renderPagination(position) {
    const {searchState, paginationShowing, paginationAll, paginationOf,
      paginationResults} = this.props;

    return <Pagination collectionTotal={searchState.listTotal}
                       currentPage={searchState.pagination.currentPage}
                       currentStart={searchState.pagination.currentStart}
                       currentEnd={searchState.pagination.currentEnd}
                       onChangePage={this.paginationPageChanged}
                       paginationCount={DefaultPagination}
                       paginationPosition={position}
                       paginationShowing={paginationShowing}
                       paginationAll={paginationAll}
                       paginationOf={paginationOf}
                       paginationResults={paginationResults}
				/>;
  }

  render() {
    const {searchState, onKeywordChanged, keywordLabel, keywordPlaceholder,
      alphabetLabel, searchOr, linkName, noResults} = this.props;

    return (
      <div>
        <div className='l-block'>
          <div className='container'>
            <div className='form-center'>
              <div className='form-inline--last'>
                <div className='form-left'>
                  <SearchBar labelName={keywordLabel}
                             placeholderName={keywordPlaceholder}
                             onTextChange={onKeywordChanged}
                             fetchWithText={this.fetchWithKeyword}
                             inputValue={searchState.filters.keyword || ''} />
                </div>
                <div className='form-middle'>
                  <span>{searchOr}</span>
                </div>
                <div className='form-right'>
                  <AlphabetPicker
                    labelName={alphabetLabel}
                    onLetterSelect={this.letterSelected}
                    selectedLetter={searchState.filters.selectedLetter || false} 
                    path={this.props.location.pathname} />
                </div>
              </div>
            </div>
          </div>
        </div>

        { searchState.fetching &&
          <div className='l-block l-block--gray l-block--border-top'>
            <img className='spinner' src='/assets/images/gif/squares.gif' />
          </div>
        }
        { searchState.list.length > 0 &&
          <div className='l-block l-block--gray l-block--border-top'>
            <div className='container'>
              <div className='search-results'>
                <div>
                  { this.renderPagination('top') }

                  <div className='list list--lg search-results__wrap'>
                    <ul className='list__items'>
                      {this.renderSpecialtyList(searchState.list)}
                    </ul>
                  </div>

                  { this.renderPagination('bottom') }
                  </div>
                </div>
              </div>
            </div>
          }
          { searchState.listTotal === 0 && (searchState.filters.keyword ||
            searchState.filters.selectedLetter) &&
            <div className='l-block l-block--gray l-block--border-top'>
              <div className='container'>
                <div className='search-results'>
                  <div className='list list--lg search-results__wrap'>
                    <p>{noResults}</p>
                  </div>
                </div>
              </div>
            </div>
          }
      </div>
    );
  }
}
