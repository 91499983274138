'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';
import Application from '../constants/application';

const { SearchComponentClass } = Application;

const {
  REQUEST_SPECIALTIES_SUCCESS, 
  REQUEST_SPECIALTIES_FETCHING,
  REQUEST_DOCTOR_SPECIALTIES_SUCCESS, 
  REQUEST_DOCTOR_SPECIALTIES_FETCHING,
  SPECIALTIES_LETTER_CHANGED, 
  SPECIALTIES_KEYWORD_CHANGED,
  SPECIALTIES_PAGINATION_CHANGED, 
  SPECIALTIES_KEYWORD_INITIATED,
  SPECIALTIES_RESET_SEARCH
} = Actions;

function specialtiesGetSuccess(data) {
  return {
    type: REQUEST_SPECIALTIES_SUCCESS,
    list: data.results,
    listTotal: data.totalResults
  };
}

function specialtiesFetching() {
  return {
    type: REQUEST_SPECIALTIES_FETCHING
  };
}

function doctorSpecialtiesGetSuccess(data) {
  return {
    type: REQUEST_DOCTOR_SPECIALTIES_SUCCESS,
    list: data.results,
    listTotal: data.totalResults
  };
}

function doctorSpecialtiesFetching() {
  return {
    type: REQUEST_DOCTOR_SPECIALTIES_FETCHING
  };
}

export function letterChanged(letter) {
  return {
    type: SPECIALTIES_LETTER_CHANGED,
    letter: letter
  };
};

export function keywordChanged(keyword) {
  return {
    type: SPECIALTIES_KEYWORD_CHANGED,
    keyword: keyword
  };
};

export function keywordSearchInitiated() {
  return {
    type: SPECIALTIES_KEYWORD_INITIATED
  };
};

export function paginationPageChanged(page, start, end) {
  return {
    type: SPECIALTIES_PAGINATION_CHANGED,
    page: page,
    start: start,
    end: end
  };
};

export function resetSearch() {
  return {
    type: SPECIALTIES_RESET_SEARCH
  };
}

// counter to track request responses and dissmiss all responses except the one
// from first request
let counter = 0;
export function fetchSpecialtiesList(Start, End, StartsWith = null,
  Keyword = null, callback = null) {
  return dispatch => {
    let params = {
      Start: Start,
      End: End
    };

    if (StartsWith && !Keyword) params['StartsWith'] = StartsWith;
    if (Keyword && !StartsWith) params['Keyword'] = Keyword;

    $(SearchComponentClass).trigger('fetching');

    dispatch(specialtiesFetching());
    request(++counter);

    function request(i) {
      $.ajax({
        url: '/api/search/specialties/get',
        cache: false,
        data: params,
        method: 'POST'
      }).done((response) => {
        if (i !== counter) return;
        dispatch(specialtiesGetSuccess(response));

        $(SearchComponentClass).trigger('fetched');

        if (typeof callback === 'function') {
          callback();
        }
      });
    }
  };
};

// docCounter to track request responses and dissmiss all responses except the one
// from first request
let docCounter = 0;
export function fetchDoctorSpecialtiesList(Start, End, StartsWith = null,
  Keyword = null, callback = null) {
  return dispatch => {
    let params = {
      Start: Start,
      End: End
    };

    if (StartsWith && !Keyword) params['StartsWith'] = StartsWith;
    if (Keyword && !StartsWith) params['Keyword'] = Keyword;

    $(SearchComponentClass).trigger('fetching');

    dispatch(doctorSpecialtiesFetching());
    request(++docCounter);

    function request(i) {
      $.ajax({
        url: '/api/search/specialties/GetDoctorSpecialties',
        cache: false,
        data: params,
        method: 'POST'
      }).done((response) => {
        if (i !== docCounter) return;
        dispatch(doctorSpecialtiesGetSuccess(response));

        $(SearchComponentClass).trigger('fetched');

        if (typeof callback === 'function') {
          callback();
        }
      });
    }
  };
};
