﻿'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';
import { calculatePagination } from '../urlState.js';
import UrlState from '../components/clinicalTrial-listing/urlState.js';

const { getUrlState } = UrlState;

const { 
  REQUEST_CLINICALTRIAL_LIST_SUCCESS,
  REQUEST_CLINICALTRIAL_LIST_FETCHING,
  REQUEST_CLINICALTRIAL_FILTER_SUCCESS,
  REQUEST_CLINICALTRIAL_FILTER_FETCHING
} = Actions;

function listGetSuccess(data) {
  return {
    type: REQUEST_CLINICALTRIAL_LIST_SUCCESS,
    list: data.results,
    listTotal: data.totalResults
  };
}

function listFetching() {
  return {
    type: REQUEST_CLINICALTRIAL_LIST_FETCHING
  };
}

function filterGetSuccess(data) {
  return {
    type: REQUEST_CLINICALTRIAL_FILTER_SUCCESS,
    filter: data
  }
}

function filterFetching() {
  return {
    type: REQUEST_CLINICALTRIAL_FILTER_FETCHING,
  }
}

export function fetchList() {
  return (dispatch, getState) => {

    const { page, condition, specialty } = getUrlState();
    const { currentStart, currentEnd } = calculatePagination(page);

    const params = {
      Start: currentStart,
      End: currentEnd,
      ConditionType: condition,
      SpecialtyType: specialty
    };

    dispatch(listFetching());

    $.ajax({
      url: '/api/search/clinicaltriallisting/get',
      cache: false,
      data: $.param(params),
      method: 'POST'
    }).done((response) => {
      dispatch(listGetSuccess(response));
    }).fail(() => {
      dispatch(listGetSuccess({"totalResults":0,"results":[]}));
    });
  };
}

export function fetchFilter() {
  return (dispatch) => {
    dispatch(filterFetching());

    $.ajax({
      url: '/api/search/clinicaltriallisting/getfilters',
      cache: false,
      method: 'POST'
    }).done(response => {
      // convert label field to "name" in response
      const conditionData = response.filters.conditionTypes.map( f => ({ name: f.label, value: f.value }));

      // typo in "specialtyTypes" is part of json response
      const specialtyData = response.filters.specialtyTypes.map( f => ({ name: f.label, value: f.value }));

      dispatch(filterGetSuccess({ filters: { specialtyTypes: specialtyData, conditionTypes: conditionData } }));
    }).fail(() => {
      dispatch(filterGetSuccess({}));
    });

  }
}
