'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class LocationsItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      thumbnail: PropTypes.string,
      link: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      phone: PropTypes.string,
      phoneLink: PropTypes.string,
      lat: PropTypes.string.isRequired,
      lon: PropTypes.string.isRequired
    }).isRequired,
    listDirections: PropTypes.string.isRequired,
    listDetails: PropTypes.string.isRequired
  }

  getDirections() {
    const {item} = this.props;

    return 'https://www.google.com/maps/dir/Current+Location/' +
      item.lat + ',' + item.lon;
  }

  render() {
    const {item, listDirections, listDetails} = this.props;

    return(
      <div>
        { item.thumbnail &&
          <a href={item.link} className='list__img'>
            <img src={item.thumbnail}
                 alt={item.title + ' Picture'} />
          </a>
        }
        <div className={'list__content' + (item.thumbnail ? ' has-img' : '')}>
          <h4 className='list__title'>
            <a href={item.link}>{item.title}</a>
          </h4>
          <div className='list__address'>
            <div>{item.address1}</div>
            <div>{item.address2}</div>
            <div>{item.city}, {item.state} {item.zip}</div>
          </div>
          <div className='list__contact'>
            <a className='list__tel' href={'tel:+' + item.phoneLink}>
              <i className='fa fa-phone'></i> {item.phone}
            </a>
            <a className='list__location' href={item.link}>
              <i className='fa fa-building-o'></i> {listDetails}
            </a>
            <a className='list__directions' href={this.getDirections()}>
              <i className='fa fa-map-marker'></i> {listDirections}
            </a>
          </div>
        </div>
      </div>
    );
  }
};
