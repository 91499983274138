'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

import Mlhpp from "./Mlhpp.js";

export default class DoctorListType extends Component {
    static propTypes = {
        doctor: PropTypes.shape({
            title: PropTypes.string.isRequired,
            specialties: PropTypes.shape.isRequired,
            readMoreLink: PropTypes.string.isRequired,
            thumbnailImage: PropTypes.string,
            address1: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zip: PropTypes.string,
            phone: PropTypes.string,
            otherLocations: PropTypes.string,
            isMLHPP: PropTypes.bool.isRequired,
            npi: PropTypes.string,
            hasOnlineScheduling: PropTypes.bool,
        }).isRequired,
        linkName: PropTypes.string.isRequired,
        labels: PropTypes.object.isRequired
    };

    getAltImageValue(title) {
        return `Doctor ${title} Picture`;
    }

    renderSpecialties(item) {
        const specialtiesArr = item.specialties.filter(item => item.searchable);
        const length = specialtiesArr.length;
        return (
            <div className="list__specialties">
                <span>Specialties:&nbsp;</span>
                { // looping through specialties
                    specialtiesArr.reduce((array, item, i) => {
                        const classname = item.accepting ? "is-accepting" : "is-not-accepting";
                        const separator = i < length - 1 ? "," : "";
                        array.push(<span key={i} className="list__specialties-item">{item.label}<span title={item.tooltip} className={classname}>+</span>{separator}&nbsp;</span>);
                        return array;
                    }, [])
                }
                {item.services}
            </div>
        );
    }

    renderPrimaryOfficeDetails(item) {
        return (
            (!!item.address1 || !!item.address2 || !!item.city || !!item.state ||
                !!item.zip) &&
            <div>
                <p className='list__content-list'>
                    <span className='list__content-label'>Primary Office:</span>
                </p>
                <p>
                    {!!item.address1 && <span>{item.address1}<br /></span>}
                    {!!item.address2 &&
                        <span>{item.address2}
                            {(!!item.city || !!item.state || !!item.zip) &&
                                <span>,<br /></span>
                            }
                        </span>
                    }
                    {!!item.city &&
                        <span>{item.city}
                            {(!!item.state || !!item.zip) &&
                                <span>, </span>
                            }
                        </span>
                    }
                    {!!item.state && <span>{item.state} </span>}
                    {!!item.zip && <span>{item.zip}</span>}
                    {!!item.phone &&
                        <span><br />Phone:&nbsp;
                            <a href={'tel:+' + item.phone.replace(/\./g, '')}>
                                {item.phone}
                            </a>
                        </span>
                    }
                </p>
            </div>
        );
    }

    roundHalf(num) {
        return Math.round(num * 2) / 2;
    }

    starClassSuffix(num) {
        return this.roundHalf(num).toString().split('.').join('');
}

    renderStarRating(item) {
        return (
            <div className='nrcRating'>
                <div className='starsArea'><span className={'ds-stars ds-stars' + this.starClassSuffix(item.rating)}></span></div>
                <div className='nrc-xofy'><span className='nrc-average' itemProp='ratingValue'>{item.rating}</span><span className='nrc-average-max'> out of 5</span></div>
                <div className='nrc-ratings'><span className='nrc-ratingcount' itemProp='ratingCount'>{item.ratingsCount}</span> Ratings</div>
                <div className='nrc-comments'><span className='nrc-commentcount'>{item.commentsCount}</span> Comments</div>
            </div>
        );
    }

    render() {
        const { doctor, linkName, labels } = this.props;

        return (
            <div className='list__item--doctor'>
                <div className='list__meta'>
                    <span className='list__type'>Provider</span>
                </div>
                {doctor.thumbnailImage &&
                    <a href={doctor.readMoreLink + '?source=dL-providers'} className='list__img'>
                        <img src={doctor.thumbnailImage}
                            alt={this.getAltImageValue(doctor.title)} />
                    </a>
                }
                <div className={'list__content' + (doctor.thumbnailImage ? ' has-img' : '')}>
                    <h4 className='list__title'>
                        <a href={doctor.readMoreLink + '?source=dL-providers'}>{doctor.title}</a>
                        {doctor.isMLHPP && <Mlhpp labels={labels} />}
                    </h4>
                    {doctor.hasOnlineScheduling &&
                        <div className="hasOnlineScheduleText" style={{ marginTop: "-10px"}}><span className="fa fa-calendar hasOnlineScheduleIcon">&nbsp;</span>Schedule Online</div>
                    }
                    {doctor.rating && this.renderStarRating(doctor)}

                    {this.renderSpecialties(doctor)}
                    {this.renderPrimaryOfficeDetails(doctor)}
                    {doctor.otherLocations &&
                        <div>
                            <p className='list__content-list'>
                                <span className='list__content-label'>Other Locations:</span>
                            </p>
                            <p>{doctor.otherLocations}</p>
                        </div>
                    }
                    <a className='link' href={doctor.readMoreLink + '?source=dL-providers'}>{linkName}</a>
                </div>
            </div>
        );
    }

    componentDidMount() {
        const script = document.createElement("script");

        script.src = "https://transparency.nrchealth.com/widget/v2/main-line-health/npi/lotw.js";
        script.async = true;

        document.body.appendChild(script);
    }
};
