export default function removeCookieValue(name, value) {
	// get the current value of the cookie
	let cookieValues = Cookies.get(name) || '';
	// split the value of the cookie
	cookieValues = cookieValues.split(',');
	// search through the values
	// return all values that don't match
	cookieValues = cookieValues.filter(item => item !== value);
	// set the cookie to the new value
	Cookies.set(name, cookieValues.join(','), {path:'/'});
}
