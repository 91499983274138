'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import { Link, withRouter } from '../../../vendor/react-router.js';

import Application from '../../constants/application';
import {fetchMainSearch, keywordChanged, keywordInitiated, contentTypesChanged,
  paginationChanged}  from '../../actions/mainSearch';
import URL from '../../../common/urlManipulator';
import DynamicListMainSearch from '../shared/DynamicListMainSearch';
import SearchBar from '../shared/SearchBar';
import UrlState from "./urlState.js";
import debounce from 'debounce';

const { getUrlState, mergeUrlQuery } = UrlState;

const {
  DefaultPagination, 
  DefaultKeywordFetchLength
} = Application;

@connect(state => ({ mainSearch: state.mainSearch }))
@withRouter
export default class MainSearch extends Component {
  static propTypes = {
    contentTabs: PropTypes.array.isRequired,
    filterLabel: PropTypes.string.isRequired,
    allResultsLabel: PropTypes.string.isRequired,
    keywordPlaceholder: PropTypes.string.isRequired,
    linkName: PropTypes.string.isRequired,
    noResults: PropTypes.string.isRequired,
    paginationShowing: PropTypes.string.isRequired,
    paginationAll: PropTypes.string.isRequired,
    paginationOf: PropTypes.string.isRequired,
    paginationResults: PropTypes.string.isRequired
  };

  constructor() {
    super();
    this.keywordChanged = this.keywordChanged.bind(this);
    this.fetchWithKeyword = this.fetchWithKeyword.bind(this);
	  this.tabChanged = this.tabChanged.bind(this);
	  this.getPaginatedList = debounce(this.getPaginatedList, 300);
  }

  componentDidMount() {
    this.handleUrlChange(this.props, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if ((this.props.location.query !== nextProps.location.query) && !nextProps.mainSearch.fetching) {
      this.handleUrlChange(nextProps);
      return true;
    } else if (nextProps.mainSearch.fetching || !nextProps.mainSearch.fetching) {
      return true;
    } else {
      return false;
    }
  }

  handleUrlChange(nextProps, scroll = true) {
    let parm = {
      keyword: nextProps.location.query.keyword, 
      contentTypes: nextProps.location.query['content-type'] || null
    };

    const {dispatch} = this.props;

    let contentAPI = [];

    if (parm.contentTypes) {
      this.splitContentTypes(contentAPI, parm.contentTypes);
    } else {
      contentAPI= this.getFilters(true).contentTypesAPI;
    }

    let page = Number(nextProps.location.query.page || 1);
    let start = (page * 10) - 9;
    let end = page * 10;

    if (scroll) {
      window.scrollTo(0, 0);
    }

    this.getPaginatedList(start, end, contentAPI, parm.keyword, () => {
      dispatch(contentTypesChanged(parm.contentTypes));
    });
  }

  getUrlParams() {
    const {contentTabs} = this.props;

    let queryString = URL.getQueryString(), keyword, contentTypes, obj = {};

    if (queryString === null) {
      return obj;
    }

    keyword = this.props.location.query.keyword;
    contentTypes = this.props.location.query['content-type'];

    if (keyword && keyword.length >= DefaultKeywordFetchLength) {
      obj['keyword'] = keyword;
    }

    for (let i=0; i < contentTabs.length; i++) {
      if (contentTabs[i].Key === contentTypes) {
        obj['contentTypes'] = contentTypes;
        break;
      }
    }

    return obj;
  }

  composeQueryString(keyword, contentTypes) {
    let obj = {};

    if (keyword) {
      obj['keyword'] = keyword;
    }

    if (contentTypes) {
      obj['content-type'] = contentTypes;
    }

    return obj;
  }

  getPaginatedList(start, end, contentTypes, keyword, callback) {
    const {dispatch} = this.props;

   dispatch(fetchMainSearch(start, end, contentTypes, keyword, callback));
  }

  keywordChanged(keyword) {
	  const { router, dispatch } = this.props;
	  router.replace(mergeUrlQuery({ keyword, page: 1 }));
    dispatch(keywordChanged(keyword));
  }

  splitContentTypes(pushArray, source) {
    if (source.indexOf('|') !== -1) {
		let threeKeys = source.split('|', 3);
		return pushArray.push(threeKeys[0], threeKeys[1], threeKeys[2]);	
    }

    return pushArray.push(source);
  }

  getFilters(getAll = false) {
    const {mainSearch, contentTabs} = this.props;

    let keyword = mainSearch.filters.keyword,
        contentTypes = mainSearch.filters.contentTypes;

    if (!!keyword) {
      keyword = keyword.length >= DefaultKeywordFetchLength ? keyword : null;
    }

    if (contentTypes && !getAll) {
      let temp = [];
      [contentTypes, temp] = [temp, contentTypes];
      this.splitContentTypes(contentTypes, temp);
    } else {
      contentTypes = [];

      for (let i = 0; i < contentTabs.length; i++) {
        this.splitContentTypes(contentTypes, contentTabs[i].Key);
      }
    }

    return {
      keyword: keyword,
      contentTypes: mainSearch.filters.contentTypes,
      contentTypesAPI: contentTypes
    };
  }

  fetchWithKeyword(value) {
    const {dispatch} = this.props;

    let f = this.getFilters();
    this.getPaginatedList(1, DefaultPagination, f.contentTypesAPI, value,
      () => {
        this.composeQueryString(value, f.contentTypes);
      });
  }

  tabChanged(e, index) {
    // Handled by handleUrlChange as of 8/30/2018
  }

  renderTabs(list, mainSearch, allResultsLabel) {
    const { page, keyword } = getUrlState();

    let tabs = list.map((item, index) => {
      const contentType = item.Key;

      return (
        <li key={index}>
          <Link 
            className={mainSearch.filters.contentTypes === item.Key ? 'active' : ''} 
            to={mergeUrlQuery({keyword, page: 1, "content-type": contentType})}>
              {item.Value}
          </Link>
        </li>
      );
    });

    tabs.unshift(
      <li key='all' onClick={(e) => this.tabChanged(e, null)}>
          <Link 
            className={mainSearch.filters.contentTypes === null ? 'active' : ''} 
            to={mergeUrlQuery({keyword, page: 1, "content-type": ""})}>
              {allResultsLabel}
          </Link>
      </li>
    );
    return tabs;
  }

  render() {
    const {dispatch, mainSearch, contentTabs, filterLabel, allResultsLabel,
		  keywordPlaceholder, ...other } = this.props;
	  const { page, keyword } = getUrlState();

    return(
      <div>
        <div className='l-block l-block--main'>
          <div className='container'>
            <div className='form-center'>
              <div className='form-inline form-inline--no-border'>
                <span className='content-type__menu-toggle'>
                  <i className='fa fa-angle-down'></i> {filterLabel}
                </span>
                <ul className='search-content-type'>
                  {this.renderTabs(contentTabs, mainSearch, allResultsLabel)}
                </ul>
              </div>
              <div className='form-inline form-inline--last'>
                <div className='search-wrap'>
                  <SearchBar placeholderName={keywordPlaceholder}
                             onTextChange={this.keywordChanged}
                             fetchWithText={this.fetchWithKeyword}
									inputValue={keyword} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='l-block l-block--gray l-block--border-top'>
          <div className='container'>
            <div className='search-results'>
              <DynamicListMainSearch
							searchState={mainSearch}
							currentPage={parseInt(page)}
                listClassName='search-results__wrap'
                filters={this.composeQueryString()}
                {...other} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};
