'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class ResultsItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      type: PropTypes.string.isRequired,
      pageTitle: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      readMoreLink: PropTypes.string.isRequired
    }).isRequired,
    readMoreLabel: PropTypes.string.isRequired
  }

  render() {
    const {item, readMoreLabel} = this.props;

    return (
      <div className='list__item--specialty'>
        <div className='list__meta'>
          <span className='list__type'>{item.type}</span>
        </div>
        <div className='list__content'>
          <h4 className='list__title'>
            <a href={item.readMoreLink}>{item.pageTitle}</a>
          </h4>
          <p>{item.summary}</p>
          <a className='link' href={item.readMoreLink}>{readMoreLabel}</a>
        </div>
      </div>
    );
  }
}
