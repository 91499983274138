import Application from "./constants/application.js";

const { DefaultPagination } = Application;

/**
 * constructor to make a URL state Object
 * @return { getUrlState, mergeUrlQuery }
 */
export function createUrlState(defaults) {

    // read the url state from the window.location
    function getUrlState() {
        if (window.location.search === "") {
            return defaults;
        }
        return window.location.search
            .replace(/^\?/, "")
            .split("&")
            .map(pairString => {
                return pairString.split("=")
                .map(decodeURIComponent);
            })
            .map(pair => {
                const [ key, value ] = pair;
                return [ key, numberify(value) ];
            })
            .filter(pair => {
                const [ key, value ] = pair;
                return value !== "" && value !== undefined && value !== null;
            })
            .reduce((obj, pair) => {
                const [ key, value ] = pair;
                return Object.assign({}, obj, {
                    [key]: value
                });
            }, defaults)

    }


    // construct an object that can be passed to react-router's
    // router.push([here]) or <Link to={[here]} />
    function mergeUrlQuery(newParams){
        return {
            pathname: window.location.pathname,
            query: {
                ...getUrlState(),
                ...newParams
            }
        };
    }

    /**
     * creates a listener that can be used with browserHistory.listen()
     * that will execute onChange when the url location changes
     * eg usage:
     *   browserHistory.listen(createBrowserHistoryChangeListener(
     *     () => store.dispatch(fetchList())
     *   ))
     */
    function createBrowserHistoryChangeListener(onChange){

        // keep track of the previous state
        let prevUrlState = getUrlState();

        // this function will execute when the browserHistory changes
        return function(location) {

          const urlState = getUrlState();

          const urlChanged = Object.keys(urlState)
            .some((key) => getUrlState[key] !== urlState[key]);

          if (urlChanged){
            onChange()
          }

          prevUrlState = urlState;
        }
    }

    return { getUrlState, mergeUrlQuery, createBrowserHistoryChangeListener };

}


// convert to a number if it's a number
function numberify(string) {
    return (!isNaN(string) && string !== "") ? Number(string) : string;
}


export function calculatePagination(page){
    return {
        currentPage: page,
        currentStart: page != null ? 1 + ((page-1) * DefaultPagination) : 1,
        currentEnd: page != null ? ((page-1) * DefaultPagination) + DefaultPagination : DefaultPagination
    }
}
