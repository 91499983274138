'use strict';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import PatientSpotlight from './PatientSpotlight';
import store from '../../store';

(() => {
    let patientSpotlightElm =
        document.getElementById('js-patient-spotlight-search');

    if (!patientSpotlightElm) return;

    let props = {};
    props['conditionsLabel'] = patientSpotlightElm.getAttribute('data-conditions-label');
    props['treatmentsLabel'] = patientSpotlightElm.getAttribute('data-treatments-label');
    props['noResults'] = patientSpotlightElm.getAttribute('data-no-results') || "No Results";

    // to prevent infinite scroll from double firing scroll the page to the
    // topPosition
    window.scrollTo(0, 0);

    ReactDOM.render(
        <Provider store={store}>
            <PatientSpotlight {...props} />
        </Provider>,
        patientSpotlightElm
    );
})();
