'use strict';

import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import BlogArticle from './BlogArticle';
import store from '../../store';
import {browserHistory, Router, Route} from '../../../vendor/react-router.js';

(() => {
  let blogSearchElem = document.getElementById('js-blog-search');

  if (!blogSearchElem) return;

  let props = {};
  props['blogContentTypes'] =
    JSON.parse(blogSearchElem.getAttribute('data-blog-content-types'));
  props['keywordLabel'] = blogSearchElem.getAttribute('data-keyword-label');
  props['keywordPlaceholder'] =
    blogSearchElem.getAttribute('data-keyword-placeholder');
  props['searchButton'] = blogSearchElem.getAttribute('data-search-button');
  props['noResults'] = blogSearchElem.getAttribute('data-no-results');
  props['linkName'] = blogSearchElem.getAttribute('data-read-more');
  props['categoryLabel'] = blogSearchElem.getAttribute('data-category-label');
  props['paginationShowing'] = blogSearchElem.getAttribute('data-pagination-showing');
  props['paginationAll'] = blogSearchElem.getAttribute('data-pagination-all');
  props['paginationOf'] = blogSearchElem.getAttribute('data-pagination-of');
  props['paginationResults'] = blogSearchElem.getAttribute('data-pagination-results');

  const App = appProps => <BlogArticle { ... { ...appProps, ...props }} />;

  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="*" component={App} />
      </Router>
    </Provider>,
    blogSearchElem
  );
})();
