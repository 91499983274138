'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import uniqueId from '../../../common/uniqueId';

export default class Select extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]).isRequired
    })).isRequired,
    optionSelected: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired,
    onOptionSelect: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    labelName: PropTypes.string
  }

  constructor() {
    super();
    this.optionPicked = this.optionPicked.bind(this);

    this.forId = uniqueId.generate();
  }

  optionPicked(e) {
    e.preventDefault();
    let option = e.target.value;
    const {onOptionSelect} = this.props;

    onOptionSelect(option);
  }

  renderOptions(options) {
    return options.map((item, index) => {
      return (
        <option value={item.value} key={index}>{item.name}</option>
      );
    });
  }

  render() {
    const {options, optionSelected, isDisabled, labelName} = this.props;

    return (
      <div>
        { labelName &&
          <label htmlFor={`select-element-${this.forId}`}>{labelName}</label>
        }
        <select id={`select-element-${this.forId}`}
                value={optionSelected}
                disabled={isDisabled}
                onChange={this.optionPicked}>
          {this.renderOptions(options)}
        </select>
      </div>
    );
  }
};
