'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import MainSearch from './MainSearch';
import store from '../../store';
import { Router, Route, browserHistory } from '../../../vendor/react-router.js';
import UrlState from './urlState.js';

const { getUrlState, createBrowserHistoryChangeListener } = UrlState;

(() => {
	let mainSearchElem = document.getElementById('js-main-search');

	if (!mainSearchElem) return;

	let props = {};
	props['contentTabs'] =
		JSON.parse(mainSearchElem.getAttribute('data-content-types'));
	props['filterLabel'] = mainSearchElem.getAttribute('data-filter-label');
	props['allResultsLabel'] =
		mainSearchElem.getAttribute('data-all-results-label');
	props['keywordPlaceholder'] =
		mainSearchElem.getAttribute('data-keyword-placeholder');
	props['linkName'] = mainSearchElem.getAttribute('data-link-name');
	props['noResults'] = mainSearchElem.getAttribute('data-no-results');
	props['paginationShowing'] =
		mainSearchElem.getAttribute('data-pagination-showing');
	props['paginationAll'] = mainSearchElem.getAttribute('data-pagination-all');
	props['paginationOf'] = mainSearchElem.getAttribute('data-pagination-of');
	props['paginationResults'] =
		mainSearchElem.getAttribute('data-pagination-results');
	props.labels = {
		mainLineHealthPhysicianPartnerDoctorLabel: mainSearchElem.getAttribute('data-mlhpp-doctor-label'),
		mainLineHealthPhysicianPartnerDoctorInfo: mainSearchElem.getAttribute('data-mlhpp-doctor-info'),
		mainLineHealthPhysicianPartnerReadmoreText: mainSearchElem.getAttribute('data-mlhpp-doctor-readmore')
	}

	const App = appProps => <MainSearch {...{ ...appProps, ...props }} />;

	ReactDOM.render(
		<Provider store={store}>
			<Router history={browserHistory}>
				<Route path="*" component={App} />
			</Router>
		</Provider>,
		mainSearchElem
	);
})();
