'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import $ from "jquery";

import store from '../../store';
import MegaMenu from './MegaMenu';
import { tabChanged } from '../../actions/megaMenu';


(() => {
  let megaMenuElems = document.querySelectorAll('.js-mega-menu');

  if (!megaMenuElems || megaMenuElems.length === 0) return;

  let typesElem = document.getElementById('js-content-types'),
    contentTypes = JSON.parse(typesElem.getAttribute('data-content-types-frontend')),
    contentTypesAPI = JSON.parse(typesElem.getAttribute('data-content-types-backend'));

  for (let i = 0; i < megaMenuElems.length; i++) {
    let props = {
      contentTypes: contentTypes,
      contentTypesAPI: contentTypesAPI,
      searchLink: megaMenuElems[i].getAttribute('data-search-link'),
      searchEndpoint: megaMenuElems[i].getAttribute('data-search-endpoint'),
      searchIsCoveo: megaMenuElems[i].hasAttribute('data-is-coveo-search'),
      keywordLabel: megaMenuElems[i].getAttribute('data-keyword-label'),
      keywordPlaceholder: megaMenuElems[i].getAttribute('data-keyword-placeholder'),
      viewLabel: megaMenuElems[i].getAttribute('data-view-label'),
      tabId: i,
      isActive: false
    };

    ReactDOM.render(
      <Provider store={store}>
        <MegaMenu {...props} key={i} />
      </Provider>,
      megaMenuElems[i]
    );
  }

  let navElems = $('.site-header--nav li a.header-search-app, .button--search--small');

  for (let i = 0; i < navElems.length; i++) {
    if (window.addEventListener) {
      navElems[i].addEventListener('click', e => { e.preventDefault(); e.stopPropagation(); store.dispatch(tabChanged(i)); });
    } else if (window.attachEvent) {
      navElems[i].attachEvent('onclick', e => { e.preventDefault(); e.stopPropagation(); store.dispatch(tabChanged(i)); });
    }
  }
})();
