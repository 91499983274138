'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class EventsItem extends Component {
  static propTypes = {
    event: PropTypes.shape({
      title: PropTypes.string.isRequired,
      summary: PropTypes.string,
      readMoreLink: PropTypes.string.isRequired,
      eventPlace: PropTypes.string,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      cost: PropTypes.string.isRequired,
      offline_Payment: PropTypes.bool.isRequired,
      remainingSeats: PropTypes.string,
      registrationStatus: PropTypes.string,
      registrationStatusLabel: PropTypes.string
    }).isRequired,
    toLabel: PropTypes.string.isRequired,
    fromLabel: PropTypes.string.isRequired,
    costLabel: PropTypes.string.isRequired,
    seatsLabel: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    const { event } = this.props;

    let tempStartDate = new Date(event.startDate + "Z");
    let tempEndDate = new Date(event.endDate + "Z");
    let tempStartDateinMills = tempStartDate.getTime() + (tempStartDate.getTimezoneOffset() * 60000);
    let tempEndDateinMills = tempEndDate.getTime() + (tempEndDate.getTimezoneOffset() * 60000);

    this.startDate = new Date(tempStartDateinMills);
    this.endDate = new Date(tempEndDateinMills);
  }

  checkDatesOnSameDay() {
    return (this.startDate.getDate() === this.endDate.getDate() &&
      this.startDate.getMonth() === this.endDate.getMonth() &&
      this.startDate.getFullYear() === this.endDate.getFullYear());
  }

  getShortTime(date) {
    let hr = date.getHours(),
      mins = date.getMinutes(),
      amPm = hr < 12 ? 'AM' : 'PM';

    hr = hr % 12;
    hr = hr === 0 ? 12 : hr;
    mins = mins < 10 ? ('0' + mins) : mins;

    return hr + ':' + mins + amPm;
  }

  getShortMonth(date) {
    let month = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct',
      'Nov', 'Dec'
    ];

    return month[date.getMonth()];
  }

  render() {
    const { event, toLabel, fromLabel, costLabel, seatsLabel } = this.props;

    return (
      <div>
        <div className='list__meta'>
          {!!event.eventPlace &&
            <span className='list__location'>{event.eventPlace}</span>
          }
          {this.checkDatesOnSameDay() ?
            <span className='list__time'>
              {this.getShortTime(this.startDate)} {toLabel}&nbsp;
              {this.getShortTime(this.endDate)}
            </span> :
            <span className="list__date-time">
              {this.getShortMonth(this.startDate)} {this.startDate.getDate()}
              &nbsp;{fromLabel} {this.getShortTime(this.startDate)} -&nbsp;
              {this.getShortMonth(this.endDate)} {this.endDate.getDate()}
              &nbsp;{toLabel} {this.getShortTime(this.endDate)}
            </span>
          }
          {!!event.remainingSeats &&
            <span className='detail__seats'>
              {seatsLabel}: {event.remainingSeats}
            </span>
          }

          {event.offline_Payment ?
            <span className='detail__cost'>
              SEE DESCRIPTION
			      </span> :
            <span className='detail__cost'>
              {costLabel}: {event.cost}
            </span>
          }


        </div>
        <span className='list__event-date'>
          {this.getShortMonth(this.startDate)}&nbsp;
          <span className='list__event-date__day'>
            {this.startDate.getDate()}
          </span>
          <span className='list__event-date__year'>
            {this.startDate.getFullYear()}
          </span>
        </span>
        <div className='list__content'>
          <h2 className='list__title'>
            <a href={event.readMoreLink}>{event.title}</a>
          </h2>
          <p>{event.summary}</p>
          {event.registrationStatus === 'Register' &&
            <a className='link' href={event.readMoreLink}>
              {event.registrationStatusLabel}
            </a>
          }
          {event.registrationStatus === 'NoRegister' &&
            <span className='link no-reg'>{event.registrationStatusLabel}</span>
          }

          {event.registrationStatus === 'Closed' &&
            <span className='link full'>{event.registrationStatusLabel}</span>
          }
          {event.registrationStatus === 'Canceled' &&
            <span className='link full'>{event.registrationStatusLabel}</span>
          }
        </div>
      </div>
    );
  }
};
