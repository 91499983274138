'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class TherapistListType extends Component {
  static propTypes = {
    doctor: PropTypes.shape({
      title: PropTypes.string.isRequired,
      specialties: PropTypes.shape.isRequired,
      readMoreLink: PropTypes.string.isRequired,
      thumbnailImage: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      phone: PropTypes.string,
      otherLocations: PropTypes.string
    }).isRequired,
    linkName: PropTypes.string.isRequired
  };

  getAltImageValue(title) {
    return `Therapist ${title} Picture`;
  }

  renderSpecialties(item) {
      const specialtiesArr = item.specialties.filter(item => item.searchable);
      const length = specialtiesArr.length;
      return (
          <p className="list__specialties">
              <span>Specialties:&nbsp;</span>
              { // looping through specialties
                  specialtiesArr.reduce((array, item, i) => {
                      const classname = item.accepting ? "is-accepting" : "is-not-accepting";
                      const separator = i < length - 1 ? "," : "";
                      array.push(<span key={i} className="list__specialties-item">{item.label}<span title={item.tooltip} className={classname}>+</span>{separator}&nbsp;</span>);
                      return array;
                  }, [])
                  
              }
              {item.services}
          </p>
      );
  }

  renderPrimaryOfficeDetails(item) {
    return(
      (!!item.address1 || !!item.address2 || !!item.city || !!item.state ||
        !!item.zip) &&
        <div>
          <p className='list__content-list'>
            <span className='list__content-label'>Primary Office:</span>
          </p>
          <p>
            { !!item.address1 && <span>{item.address1}<br/></span> }
            { !!item.address2 &&
              <span>{item.address2}
                { (!!item.city || !!item.state || !!item.zip) &&
                  <span>,<br/></span>
                }
              </span>
            }
            { !!item.city &&
              <span>{item.city}
                { (!!item.state || !!item.zip) &&
                  <span>, </span>
                }
              </span>
            }
            { !!item.state && <span>{item.state} </span> }
            { !!item.zip && <span>{item.zip}</span> }
            { !!item.phone &&
              <span><br/>Phone:&nbsp;
                <a href={'tel:+' + item.phone.replace(/\./g, '')}>
                  {item.phone}
                </a>
              </span>
            }
          </p>
        </div>
    );
  }

  render() {
    const {doctor, linkName} = this.props;

    return (
      <div className='list__item--therapist'>
        <div className='list__meta'>
          <span className='list__type'>Therapist</span>
        </div>
        { doctor.thumbnailImage &&
                <a href={doctor.readMoreLink + '?source=dL-therapists'} className='list__img'>
            <img src={doctor.thumbnailImage}
                 alt={this.getAltImageValue(doctor.title)} />
          </a>
        }
        <div className={'list__content' + (doctor.thumbnailImage
               ? ' has-img' : '')}>
          <h4 className='list__title'>
                    <a href={doctor.readMoreLink + '?source=dL-therapists'}>{doctor.title}</a>
          </h4>
		  {this.renderSpecialties(doctor)}
          {this.renderPrimaryOfficeDetails(doctor)}
          { doctor.otherLocations &&
            <div>
              <p className='list__content-list'>
                <span className='list__content-label'>Other Locations:</span>
              </p>
              <p>{doctor.otherLocations}</p>
            </div>
          }
                <a className='link' href={doctor.readMoreLink + '?source=dL-therapists'}>{linkName}</a>
        </div>
      </div>
    );
  }
};
