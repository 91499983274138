'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';

const {
  REQUEST_MEGA_MENU_SUCCESS, 
  MEGA_MENU_KEYWORD_CHANGED, 
  MEGA_MENU_SPECIALTY_CHANGED,
  MEGA_MENU_RESET_LIST, 
  MEGA_MENU_ACTIVE_TAB_CHANGED
} = Actions;

function megaMenuGetSuccess(data) {
  return {
    type: REQUEST_MEGA_MENU_SUCCESS,
    list: data.results || data.doctors.results,
    listTotal: data.totalResults,
    doctorsBySpecialty: data.doctorsBySpecialty
  };
};

export function keywordChanged(keyword) {
  return {
    type: MEGA_MENU_KEYWORD_CHANGED,
    keyword: keyword
  };
};

export function specialtyChanged(specialty) {
  return {
    type: MEGA_MENU_SPECIALTY_CHANGED,
    specialty: specialty
  };
};

export function resetList() {
  return {
    type: MEGA_MENU_RESET_LIST
  };
};

export function tabChanged(tabId) {
  return {
    type: MEGA_MENU_ACTIVE_TAB_CHANGED,
    tab: tabId
  };
}

// counter to track request responses and dissmiss all responses except the one
// from first request
let counter = 0;
export function fetchMegaMenu(ContentTypes = null, CustomURL = null,
  Keyword = null) {
  return dispatch => {
    let params = {},
        url = '/api/search/generalsearch/searchbar';

    if (ContentTypes) {
        params['ContentTypes'] = ContentTypes;
    }
    if (Keyword) {
        params['Keyword'] = Keyword;
    }
    if (CustomURL) {
        url = `/api/search/quicksearch/${CustomURL}`;
    }

    request(++counter);

    function request(i) {
	    $.ajax({
		    url: url,
		    cache: false,
		    data: params,
		    method: 'POST'
	    }).done((response) => {
		    if (i !== counter) return;
		    if (response.hasOwnProperty('doctors')) {
			    response = {
				    ...response.doctors,
				    doctorsBySpecialty: response.doctorsBySpecialties
			    };
		    }
		    dispatch(megaMenuGetSuccess(response));
	    });
    }
  };
}
