'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import URL from '../../../common/urlManipulator';

export default class LocationsDropdownItem extends Component {
  static propTypes = {
    viewLabel: PropTypes.string.isRequired,
    sideClass: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool
    ]).isRequired,
    searchLink: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
    isMainDropdown: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired
  };

  constructor() {
    super();
    this.linkClicked = this.linkClicked.bind(this);
  }

  linkClicked(e) {
    e.preventDefault();
      const { searchLink, contentType, isMainDropdown, coveocategoryId} = this.props;

      if (isMainDropdown) {
          $(".custom-searchIcon").trigger("click");
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              $(".site-header--buttons-small").find(".fa-search").trigger("click");
          }

          //let qs = URL.composeQueryString({ 't': contentType });
          //let url = URL.composeUrlWithQueryString(searchLink, qs);
          let qs = '&t='+ contentType;
          let url =  searchLink + qs;// URL.composeUrlWithQueryString(searchLink, qs);
      return URL.goToCrossBrowser(url);
    }
      return URL.goToCrossBrowser(searchLink + coveocategoryId);
  }

  renderDetails(item) {
    return (
      <div className='list__address'>
        { (!!item.address1 || !!item.address2 || !!item.city || !!item.state ||
          !!item.zip) &&
          <p>
            { !!item.address1 && <span>{item.address1}<br/></span> }
            { !!item.address2 &&
              <span>{item.address2}
                { (!!item.city || !!item.state || !!item.zip) &&
                  <span>,<br/></span>
                }
              </span>
            }
            { !!item.city &&
              <span>{item.city}
                { (!!item.state || !!item.zip) &&
                  <span>, </span>
                }
              </span>
            }
            { !!item.state && <span>{item.state} </span> }
            { !!item.zip && <span>{item.zip}</span> }
          </p>
        }
      </div>
    );
  }

  renderList(list) {
    return list.map((item, index) => {
      return (
        <li className='list__item list__item--location' key={index}>
          { item.thumbnailImage &&
            <a href={item.readMoreLink} className='list__img'>
              <img src={item.thumbnailImage} alt={item.title + ' Picture'} />
            </a>
          }
          <div className={'list__content' +
            (item.thumbnailImage ? ' has-img' : '')}>
            <h4 className='list__title'>
              <a href={item.readMoreLink}>{item.title}</a>
            </h4>
            {this.renderDetails(item)}
          </div>
        </li>
      );
    });
  }

  render() {
    const {viewLabel, sideClass, searchLink, contentType, isMainDropdown,
        items, coveocategoryId} = this.props;

    return (
      <div className={sideClass ? ` l-2up--${sideClass}` : ''}>
        <div className='list list--sm'>
          <div className='list__header'>
            <h3 className='list__header__title'>Locations</h3>
            <a className='link'
               onClick={this.linkClicked}
               href>
              {viewLabel}
            </a>
          </div>
          <ul className='list__items'>
            {this.renderList(items)}
          </ul>
        </div>
      </div>
    );
  }
};
