'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {fetchNewsArticle, keywordChanged, locationSelected, locationDeselected,
  categoryChanged, categoryDeselected, incrementLoadMoreCounter, loadMoreReset,
  setLoadMoreCounter, paginationPageChanged} from '../../actions/newsArticle';
import NewsBlogEventsSearch from '../shared/NewsBlogEventsSearch';
import NewsBlogArticleSearchItem from '../shared/NewsBlogArticleSearchItem';

@connect(state => ({
  newsArticle: state.newsArticle
}))

export default class NewsArticle extends Component {
  static propTypes = {
    locationsDictionary: PropTypes.array.isRequired,
    newsArticleTypes: PropTypes.array.isRequired,
    keywordLabel: PropTypes.string.isRequired,
    keywordPlaceholder: PropTypes.string.isRequired,
    locationsLabel: PropTypes.string.isRequired,
    locationsDefault: PropTypes.string.isRequired,
    searchButton: PropTypes.string.isRequired,
    noResults: PropTypes.string.isRequired,
    linkName: PropTypes.string.isRequired,
    categoryLabel: PropTypes.string.isRequired,
    paginationShowing: PropTypes.string.isRequired,
    paginationAll: PropTypes.string.isRequired,
    paginationOf: PropTypes.string.isRequired,
    paginationResults: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.fetchPaginatedNewsArticle = this.fetchPaginatedNewsArticle.bind(this);
    this.onKeywordChanged = this.onKeywordChanged.bind(this);
    this.onLocationSelected = this.onLocationSelected.bind(this);
    this.onLocationDeselected = this.onLocationDeselected.bind(this);
    this.onCategoryChanged = this.onCategoryChanged.bind(this);
    this.onCategoryDeselected = this.onCategoryDeselected.bind(this);
    this.onPaginationPageChanged = this.onPaginationPageChanged.bind(this);
  }

  // Our shared NewsBlogArticleSearch component will return startDate and
  // endDate parameters so we have to expect although we're not using them
  fetchPaginatedNewsArticle(start, end, keyword, location, category, startDate, endDate, cb) {
      const {dispatch} = this.props;

      dispatch(fetchNewsArticle(start, end, keyword, location, category, cb));
  }

  onKeywordChanged(keyword) {
    const {dispatch} = this.props;

    dispatch(keywordChanged(keyword));
  }

  onLocationSelected(location) {
    const {dispatch} = this.props;

    dispatch(locationSelected(location));
  }

  onLocationDeselected() {
    const {dispatch} = this.props;

    dispatch(locationDeselected());
  }

  onCategoryChanged(category) {
    const {dispatch} = this.props;

    dispatch(categoryChanged(category));
  }

  onCategoryDeselected() {
    const {dispatch} = this.props;

    dispatch(categoryDeselected());
  }

  onPaginationPageChanged(page, start, end) {
    const {dispatch} = this.props;

    window.scrollTo(0, 200);

    dispatch(paginationPageChanged(page, start, end));
  }

  renderList(list, linkName) {
    return list.map((item, index) => {
      return (
        <li className='list__item list__item--news' key={index}>
          <NewsBlogArticleSearchItem article={item} linkName={linkName} />
        </li>
      );
    });
  }

  render() {
    const {dispatch, newsArticle, locationsDictionary, newsArticleTypes,
      linkName, ...other} = this.props;

    return <NewsBlogEventsSearch
             searchState={newsArticle}
             locationsDictionary={locationsDictionary}
             collectionTypes={newsArticleTypes}
             fetchPaginatedList={this.fetchPaginatedNewsArticle}
             onSetLoadMoreCounter={this.onSetLoadMoreCounter}
             onLoadMoreReset={this.onLoadMoreReset}
             onKeywordChanged={this.onKeywordChanged}
             onLocationSelected={this.onLocationSelected}
             onLocationDeselected={this.onLocationDeselected}
             onCategoryChanged={this.onCategoryChanged}
             onCategoryDeselected={this.onCategoryDeselected}
             onIncrementLoadMoreCounter={this.onIncrementLoadMoreCounter}
             onPaginationPageChanged={this.onPaginationPageChanged}
             {...other}>
             {this.renderList(newsArticle.list, linkName)}
           </NewsBlogEventsSearch>;
  }
};


