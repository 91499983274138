'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';

const {REQUEST_BLOG_ARTICLE_SUCCESS, 
  BLOG_ARTICLE_KEYWORD_CHANGED,
  REQUEST_BLOG_ARTICLE_FETCHING, 
  REQUEST_BLOG_ARTICLE_LOAD_FETCHING,
  BLOG_ARTICLE_CATEGORY_CHANGED, 
  BLOG_ARTICLE_CATEGORY_DESELECTED,
  BLOG_ARTICLE_INCREMENT_LOAD_MORE, 
  REQUEST_BLOG_ARTICLE_LOAD_MORE_SUCCESS,
  BLOG_ARTICLE_LOAD_MORE_RESET, 
  BLOG_ARTICLE_SET_LOAD_MORE, BLOG_PAGINATION_CHANGED 
} = Actions;
  
function blogArticleGetSuccess(data) {
  return {
    type: REQUEST_BLOG_ARTICLE_SUCCESS,
    list: data.articles,
    listTotal: data.totalResults
  };
}

function blogArticleLoadMoreSuccess(data) {
  return {
    type: REQUEST_BLOG_ARTICLE_LOAD_MORE_SUCCESS,
    list: data.articles,
    listTotal: data.totalResults
  };
}

function blogArticleFetching() {
  return {
    type: REQUEST_BLOG_ARTICLE_FETCHING
  };
}

function blogArticleLoadMoreFetching() {
  return {
    type: REQUEST_BLOG_ARTICLE_LOAD_FETCHING
  };
}

export function keywordChanged(keyword) {
  return {
    type: BLOG_ARTICLE_KEYWORD_CHANGED,
    keyword: keyword
  };
};

export function categoryChanged(category) {
  return {
    type: BLOG_ARTICLE_CATEGORY_CHANGED,
    category: category
  };
};

export function categoryDeselected() {
  return {
    type: BLOG_ARTICLE_CATEGORY_DESELECTED
  };
}

export function incrementLoadMoreCounter() {
  return {
    type: BLOG_ARTICLE_INCREMENT_LOAD_MORE
  };
};

export function loadMoreReset() {
  return {
    type: BLOG_ARTICLE_LOAD_MORE_RESET
  };
}

export function setLoadMoreCounter(counter) {
  return {
    type: BLOG_ARTICLE_SET_LOAD_MORE,
    counter: counter
  };
}

export function paginationPageChanged(page, start, end) {
  return {
    type: BLOG_PAGINATION_CHANGED,
    page: page,
    start: start,
    end: end
  };
}

// counter to track request responses and dissmiss all responses except the one
// from first request
export function fetchBlogArticle(Start, End, Keyword = null, Category = null,
  callback = null) {
  return dispatch => {
    let params = {
      Start: Start,
      End: End
    };

    if (Keyword) params['Keyword'] = Keyword;
    if (Category) params['Category'] = Category;

    dispatch(blogArticleFetching());

    request();

    function request() {
      $.ajax({
        url: '/api/search/articles/getblogposts',
        cache: false,
        data: params,
        method: 'POST'
      }).done((response) => {
          dispatch(blogArticleGetSuccess(response));
        if (typeof callback === 'function') callback();
      });
    }
  };
}
