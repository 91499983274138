'use strict';

import $ from 'jquery';

export default function getZipCode(success, error) {
  if (!navigator.geolocation) error('Geolocation API not supported.');

  navigator.geolocation.getCurrentPosition((position) => {
    let params = {
      Latitude: position.coords.latitude,
      Longitude: position.coords.longitude
    };

    $.ajax({
      url: '/api/search/doctors/getzip',
      cache: false,
      data: params,
      method: 'POST'
    }).done((response) => {
      if (typeof success === 'function') success(response);
    }).fail((err) => {
      error(err.responseJSON.exceptionMessage);
    });
  }, (err) => {
    error(err.message);
  });
}
