export default Object.freeze({
    REQUEST_DYNAMIC_LIST_SUCCESS: 'REQUEST_DYNAMIC_LIST_SUCCESS',
    REQUEST_DYNAMIC_LIST_FETCHING: 'REQUEST_DYNAMIC_LIST_FETCHING',
    REQUEST_DYNAMIC_FILTER_SUCCESS: 'REQUEST_DYNAMIC_FILTER_SUCCESS',
    REQUEST_DYNAMIC_FILTER_FETCHING: 'REQUEST_DYNAMIC_FILTER_FETCHING',
    DYNAMIC_LISTING_MERGE_QUERY_PARAMS: 'DYNAMIC_LISTING_MERGE_QUERY_PARAMS',
    DYNAMIC_LIST_SET_TAXONOMY_PARENT: 'DYNAMIC_LIST_SET_TAXONOMY_PARENT',
    DYNAMIC_LIST_SET_INITIAL_FILTER: 'DYNAMIC_LIST_SET_INITIAL_FILTER',
    DYNAMIC_LIST_SET_CONTENT_TYPE: 'DYNAMIC_LIST_SET_CONTENT_TYPE',
    DYNAMIC_LIST_SET_SORT_ORDER: 'DYNAMIC_LIST_SET_SORT_ORDER',
    DYNAMIC_LIST_SET_FILTER_DEFAULT: 'DYNAMIC_LIST_SET_FILTER_DEFAULT',
    DYNAMIC_LIST_SET_FILTER_CONTENT_TYPE: 'DYNAMIC_LIST_SET_FILTER_CONTENT_TYPE',
    DYNAMIC_LIST_SET_SHOWONLYMLHPP: 'DYNAMIC_LIST_SET_SHOWONLYMLHPP',

    REQUEST_SPECIALTIES_SUCCESS: 'REQUEST_SPECIALTIES_SUCCESS',
    REQUEST_SPECIALTIES_FETCHING: 'REQUEST_SPECIALTIES_FETCHING',
    REQUEST_DOCTOR_SPECIALTIES_SUCCESS: 'REQUEST_DOCTOR_SPECIALTIES_SUCCESS',
    REQUEST_DOCTOR_SPECIALTIES_FETCHING: 'REQUEST_DOCTOR_SPECIALTIES_FETCHING',
    SPECIALTIES_LETTER_CHANGED: 'SPECIALTIES_LETTER_CHANGED',
    SPECIALTIES_KEYWORD_CHANGED: 'SPECIALTIES_KEYWORD_CHANGED',
    SPECIALTIES_PAGINATION_CHANGED: 'SPECIALTIES_PAGINATION_CHANGED',
    SPECIALTIES_KEYWORD_INITIATED: 'SPECIALTIES_KEYWORD_INITIATED',
    SPECIALTIES_RESET_SEARCH: 'SPECIALTIES_RESET_SEARCH',

    REQUEST_CONDITIONS_AND_TREATMENTS_SUCCESS:
        'REQUEST_CONDITIONS_AND_TREATMENTS_SUCCESS',
    REQUEST_CONDITIONS_AND_TREATMENTS_FETCHING:
        'REQUEST_CONDITIONS_AND_TREATMENTS_FETCHING',
    CONDITIONS_AND_TREATMENTS_LETTER_CHANGED:
        'CONDITIONS_AND_TREATMENTS_LETTER_CHANGED',
    CONDITIONS_AND_TREATMENTS_KEYWORD_CHANGED:
        'CONDITIONS_AND_TREATMENTS_KEYWORD_CHANGED',
    CONDITIONS_AND_TREATMENTS_PAGINATION_CHANGED:
        'CONDITIONS_AND_TREATMENTS_PAGINATION_CHANGED',
    CONDITIONS_AND_TREATMENTS_KEYWORD_INITIATED:
        'CONDITIONS_AND_TREATMENTS_KEYWORD_INITIATED',
    CONDITIONS_AND_TREATMENTS_RESET_SEARCH:
        'CONDITIONS_AND_TREATMENTS_RESET_SEARCH',

    REQUEST_LOCATIONS_SUCCESS: 'REQUEST_LOCATIONS_SUCCESS',
    REQUEST_LOCATIONS_LOAD_MORE_SUCCESS: 'REQUEST_LOCATIONS_LOAD_MORE_SUCCESS',
    REQUEST_LOCATIONS_FETCHING: 'REQUEST_LOCATIONS_FETCHING',
    REQUEST_LOCATIONS_LOAD_FETCHING: 'REQUEST_LOCATIONS_LOAD_FETCHING',
    LOCATIONS_KEYWORD_CHANGED: 'LOCATIONS_KEYWORD_CHANGED',
    LOCATIONS_ZIP_CODE_CHANGED: 'LOCATIONS_ZIP_CODE_CHANGED',
    LOCATIONS_DISTANCE_CHANGED: 'LOCATIONS_DISTANCE_CHANGED',
    LOCATIONS_LOAD_MORE_RESET: 'LOCATIONS_LOAD_MORE_RESET',
    LOCATIONS_INCREMENT_LOAD_MORE: 'LOCATIONS_INCREMENT_LOAD_MORE',
    LOCATIONS_SELECT_ITEM_CHANGED: 'LOCATIONS_SELECT_ITEM_CHANGED',

    REQUEST_NEWS_ARTICLE_SUCCESS: 'REQUEST_NEWS_ARTICLE_SUCCESS',
    REQUEST_NEWS_ARTICLE_LOAD_MORE_SUCCESS: 'REQUEST_NEWS_ARTICLE_LOAD_MORE_SUCCESS',
    REQUEST_NEWS_ARTICLE_FETCHING: 'REQUEST_NEWS_ARTICLE_FETCHING',
    REQUEST_NEWS_ARTICLE_LOAD_FETCHING: 'REQUEST_NEWS_ARTICLE_LOAD_FETCHING',
    NEWS_ARTICLE_KEYWORD_CHANGED: 'NEWS_ARTICLE_KEYWORD_CHANGED',
    NEWS_ARTICLE_LOCATION_SELECTED: 'NEWS_ARTICLE_LOCATION_SELECTED',
    NEWS_ARTICLE_LOCATION_DESELECTED: 'NEWS_ARTICLE_LOCATION_DESELECTED',
    NEWS_ARTICLE_CATEGORY_CHANGED: 'NEWS_ARTICLE_CATEGORY_CHANGED',
    NEWS_ARTICLE_CATEGORY_DESELECTED: 'NEWS_ARTICLE_CATEGORY_DESELECTED',
    NEWS_ARTICLE_INCREMENT_LOAD_MORE: 'NEWS_ARTICLE_INCREMENT_LOAD_MORE',
    NEWS_ARTICLE_LOAD_MORE_RESET: 'NEWS_ARTICLE_LOAD_MORE_RESET',
    NEWS_ARTICLE_SET_LOAD_MORE: 'NEWS_ARTICLE_SET_LOAD_MORE',
    NEWS_ARTICLE_PAGINATION_CHANGED: 'NEWS_ARTICLE_PAGINATION_CHANGED',

    REQUEST_BLOG_ARTICLE_SUCCESS: 'REQUEST_BLOG_ARTICLE_SUCCESS',
    REQUEST_BLOG_ARTICLE_LOAD_MORE_SUCCESS: 'REQUEST_BLOG_ARTICLE_LOAD_MORE_SUCCESS',
    REQUEST_BLOG_ARTICLE_FETCHING: 'REQUEST_BLOG_ARTICLE_FETCHING',
    REQUEST_BLOG_ARTICLE_LOAD_FETCHING: 'REQUEST_BLOG_ARTICLE_LOAD_FETCHING',
    BLOG_ARTICLE_KEYWORD_CHANGED: 'BLOG_ARTICLE_KEYWORD_CHANGED',
    BLOG_ARTICLE_CATEGORY_CHANGED: 'BLOG_ARTICLE_CATEGORY_CHANGED',
    BLOG_ARTICLE_CATEGORY_DESELECTED: 'BLOG_ARTICLE_CATEGORY_DESELECTED',
    BLOG_ARTICLE_INCREMENT_LOAD_MORE: 'BLOG_ARTICLE_INCREMENT_LOAD_MORE',
    BLOG_ARTICLE_LOAD_MORE_RESET: 'BLOG_ARTICLE_LOAD_MORE_RESET',
    BLOG_ARTICLE_SET_LOAD_MORE: 'BLOG_ARTICLE_SET_LOAD_MORE',
    BLOG_PAGINATION_CHANGED: 'BLOG_PAGINATION_CHANGED',

    REQUEST_EVENTS_SUCCESS: 'REQUEST_EVENTS_SUCCESS',
    REQUEST_EVENTS_LOAD_MORE_SUCCESS: 'REQUEST_EVENTS_LOAD_MORE_SUCCESS',
    REQUEST_EVENTS_FETCHING: 'REQUEST_EVENTS_FETCHING',
    REQUEST_EVENTS_LOAD_FETCHING: 'REQUEST_EVENTS_LOAD_FETCHING',
    EVENTS_KEYWORD_CHANGED: 'EVENTS_KEYWORD_CHANGED',
    EVENTS_LOCATION_SELECTED: 'EVENTS_LOCATION_SELECTED',
    EVENTS_LOCATION_DESELECTED: 'EVENTS_LOCATION_DESELECTED',
    EVENTS_CATEGORY_CHANGED: 'EVENTS_CATEGORY_CHANGED',
    EVENTS_CATEGORY_DESELECTED: 'EVENTS_CATEGORY_DESELECTED',
    EVENTS_INCREMENT_LOAD_MORE: 'EVENTS_INCREMENT_LOAD_MORE',
    EVENTS_LOAD_MORE_RESET: 'EVENTS_LOAD_MORE_RESET',
    EVENTS_SET_LOAD_MORE: 'EVENTS_SET_LOAD_MORE',
    EVENTS_START_DATE_CHANGED: 'EVENTS_START_DATE_CHANGED',
    EVENTS_END_DATE_CHANGED: 'EVENTS_END_DATE_CHANGED',
    EVENTS_PAGINATION_CHANGED: 'EVENTS_PAGINATION_CHANGED',

    REQUEST_MEGA_MENU_SUCCESS: 'REQUEST_MEGA_MENU_SUCCESS',
    MEGA_MENU_KEYWORD_CHANGED: 'MEGA_MENU_KEYWORD_CHANGED',
    MEGA_MENU_RESET_LIST: 'MEGA_MENU_RESET_LIST',
    MEGA_MENU_ACTIVE_TAB_CHANGED: 'MEGA_MENU_ACTIVE_TAB_CHANGED',
    MEGA_MENU_SPECIALTY_CHANGED: 'MEGA_MENU_SPECIALTY_CHANGED',

    REQUEST_MAIN_SEARCH_SUCCESS: 'REQUEST_MAIN_SEARCH_SUCCESS',
    REQUEST_MAIN_SEARCH_FETCHING: 'REQUEST_MAIN_SEARCH_FETCHING',
    MAIN_SEARCH_CONTENT_TYPES_CHANGED: 'MAIN_SEARCH_CONTENT_TYPES_CHANGED',
    MAIN_SEARCH_PAGINATION_CHANGED: 'MAIN_SEARCH_PAGINATION_CHANGED',
    MAIN_SEARCH_KEYWORD_CHANGED: 'MAIN_SEARCH_KEYWORD_CHANGED',

    PATIENT_CONDITION_CHANGED: 'PATIENT_CONDITION_CHANGED',
    PATIENT_TREATMENT_CHANGED: 'PATIENT_TREATMENT_CHANGED',
    PATIENT_FILTERS_CHANGED: 'PATIENT_FILTERS_CHANGED',
    PATIENT_REQUEST_SUCCESS: 'PATIENT_REQUEST_SUCCESS',
    PATIENT_REQUEST_FILTERS: 'PATIENT_REQUEST_FILTERS',
    PATIENT_LOAD_MORE_UPDATE: 'PATIENT_LOAD_MORE_UPDATE',
    PATIENT_REQUEST_SUCCESS_MORE: 'PATIENT_REQUEST_SUCCESS_MORE',

    REQUEST_DOCTORS_SUCCESS: 'REQUEST_DOCTORS_SUCCESS',
    REQUEST_DOCTORS_FILTERS_AND_LABELS_SUCCESS:  'REQUEST_DOCTORS_FILTERS_AND_LABELS_SUCCESS',
    REQUEST_DOCTORS_FETCHING: 'REQUEST_DOCTORS_FETCHING',
    DOCTORS_KEYWORD_CHANGED: 'DOCTORS_KEYWORD_CHANGED',
    DOCTORS_KEYWORD_INITIATED: 'DOCTORS_KEYWORD_INITIATED',
    DOCTORS_LETTER_CHANGED: 'DOCTORS_LETTER_CHANGED',
    DOCTORS_ZIP_CODE_CHANGED: 'DOCTORS_ZIP_CODE_CHANGED',
    DOCTORS_PAGINATION_RESET: 'DOCTORS_PAGINATION_RESET',
    DOCTORS_DISTANCE_CHANGED: 'DOCTORS_DISTANCE_CHANGED',
    DOCTORS_LOCATION_CHANGED: 'DOCTORS_LOCATION_CHANGED',
    DOCTORS_LANGUAGES_CHANGED: 'DOCTORS_LANGUAGES_CHANGED',
    DOCTORS_GENDER_CHANGED: 'DOCTORS_GENDER_CHANGED',
    DOCTORS_SPECIALTY_CHANGED: 'DOCTORS_SPECIALTY_CHANGED',
    DOCTORS_MLH_CHECKBOX_CHANGED: 'DOCTORS_MLH_CHECKBOX_CHANGED',
    DOCTORS_MERGE_QUERY_PARAMS: 'DOCTORS_MERGE_QUERY_PARAMS',
    DOCTORS_MLHPP_CHECKBOX_CHANGED: 'DOCTORS_MLHPP_CHECKBOX_CHANGED',
    DOCTORS_ACCEPTING_CHECKBOX_CHANGED: 'DOCTORS_ACCEPTING_CHECKBOX_CHANGED',
    DOCTORS_PAGINATION_CHANGED: 'DOCTORS_PAGINATION_CHANGED',
    DOCTORS_SCHEDULING_CHECKBOX_CHANGED: 'DOCTORS_SCHEDULING_CHECKBOX_CHANGED',

    REQUEST_RESEARCHER_LIST_SUCCESS: 'REQUEST_RESEARCHER_LIST_SUCCESS',
    REQUEST_RESEARCHER_LIST_FETCHING: 'REQUEST_RESEARCHER_LIST_FETCHING',
    REQUEST_RESEARCHER_FILTER_SUCCESS: 'REQUEST_RESEARCHER_FILTER_SUCCESS',
    REQUEST_RESEARCHER_FILTER_FETCHING: 'REQUEST_RESEARCHER_FILTER_FETCHING',

    REQUEST_CLINICALTRIAL_LIST_SUCCESS: 'REQUEST_CLINICALTRIAL_LIST_SUCCESS',
    REQUEST_CLINICALTRIAL_LIST_FETCHING: 'REQUEST_CLINICALTRIAL_LIST_FETCHING',
    REQUEST_CLINICALTRIAL_FILTER_SUCCESS: 'REQUEST_CLINICALTRIAL_FILTER_SUCCESS',
    REQUEST_CLINICALTRIAL_FILTER_FETCHING: 'REQUEST_CLINICALTRIAL_FILTER_FETCHING',
});
