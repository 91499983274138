'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import $ from 'jquery';
import select2 from '../../../vendor/select2.full.min.js';

import uniqueId from '../../../common/uniqueId';

export default class SelectTwo extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    })).isRequired,
    optionSelected: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.array
    ]),
    onOptionSelect: PropTypes.func.isRequired,
    labelName: PropTypes.string,
    multiSelect: PropTypes.bool,
    multiClass: PropTypes.string,
    busy: PropTypes.bool
  };

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);

    this.reset = false;
    this.forId = uniqueId.generate();
  }

  componentDidMount() {
    const { multiSelect } = this.props;

    if (!this.selectRef){
      return;
    }

    this.$selectElement = $(this.selectRef);
    this.$selectElement.on('change', this.handleChange);
    this.$selectWrapper = this.$selectElement.parents(".select2-wrapper");

    let options = {
      dropdownParent: this.$selectWrapper
    };
    if (!multiSelect) options['minimumResultsForSearch'] = 5;

    this.$selectElement.select2(options);
  }

  componentDidUpdate() {
    const { optionSelected } = this.props;

    if (!this.$selectElement){
      return;
    }

    this.reset = true;
    this.$selectElement.select2('val', optionSelected);
  }

  handleChange() {
    const { options, onOptionSelect, multiSelect } = this.props;

    if (this.reset) return this.reset = false;

    if (multiSelect) {
      let selected = [];

      this.$selectElement.find('option:selected').each((index, elem) => {
        let value = $(elem).val();
        if (!!value) selected.push(value);
      });

      return onOptionSelect(selected);
    }

    let value = this.$selectElement.find('option:selected').val();
    onOptionSelect(value);
  }

  renderOptions(options) {
    return options.map((item, index) => {
      if (item.pageTitle) {
        return <option value={item.pageTitle} key={index}>{item.pageTitle}</option>;
      } else {
        return <option value={item.value} key={index}>{item.name}</option>;
      }
    });
  }

  render() {
    const { options, optionSelected, labelName, multiSelect, multiClass, busy } =
      this.props;

    let additionalProps = {};
    if (multiClass) additionalProps['className'] = multiClass;
    if (multiSelect) additionalProps['multiple'] = multiSelect;

    return (
      <div className="select2-wrapper">
        {labelName &&
          <label className="select2-wrapper__label" htmlFor={`select2-element-${this.forId}`}>{labelName}</label>
        }
        <div className="select2-wrapper__select">
          {busy && <div>
            <div className="spinner-label">{optionSelected}
            </div>
            <img src='/assets/images/gif/squares.gif' className='spinner' />
          </div>}
          <select id={`select2-element-${this.forId}`}
            ref={el => this.selectRef = el}
            value={optionSelected}
            onChange={() => { }}
            style={{width: '100%'}}
            {...additionalProps}>
            {this.renderOptions(options)}
          </select>

        </div>
      </div>
    );
  }
};
