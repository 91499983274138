'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import ConditionsAndTreatments from './ConditionsAndTreatments';
import store from '../../store';
import {browserHistory, Router, Route} from '../../../vendor/react-router.js';

(() => {
  let conditionsAndTreatmentsElem =
    document.getElementById('js-conditions-and-treatments-search');

  if (!conditionsAndTreatmentsElem) return;

  let props = {};
  props['keywordLabel'] =
    conditionsAndTreatmentsElem.getAttribute('data-keyword-label');
  props['keywordPlaceholder'] =
    conditionsAndTreatmentsElem.getAttribute('data-keyword-placeholder');
  props['alphabetLabel'] =
    conditionsAndTreatmentsElem.getAttribute('data-alphabet-label');
  props['searchOr'] =
    conditionsAndTreatmentsElem.getAttribute('data-search-or');
  props['paginationShowing'] =
    conditionsAndTreatmentsElem.getAttribute('data-pagination-showing');
  props['paginationAll'] =
    conditionsAndTreatmentsElem.getAttribute('data-pagination-all');
  props['paginationOf'] =
    conditionsAndTreatmentsElem.getAttribute('data-pagination-of');
  props['paginationResults'] =
    conditionsAndTreatmentsElem.getAttribute('data-pagination-results');
  props['linkName'] = conditionsAndTreatmentsElem.getAttribute('data-link');
  props['noResults'] =
    conditionsAndTreatmentsElem.getAttribute('data-no-results');

  const App = appProps => <ConditionsAndTreatments {...{...appProps, ...props}} />

  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="*" component={App} />
      </Router>
    </Provider>,
    conditionsAndTreatmentsElem
  );
})();
