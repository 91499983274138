'use strict';

import Actions from '../constants/actions';
import URL from '../../common/urlManipulator';

const {
  REQUEST_DYNAMIC_LIST_SUCCESS,
  REQUEST_DYNAMIC_LIST_FETCHING,
  REQUEST_DYNAMIC_FILTER_SUCCESS,
  REQUEST_DYNAMIC_FILTER_FETCHING,
  DYNAMIC_LISTING_MERGE_QUERY_PARAMS,
  DYNAMIC_LIST_SET_TAXONOMY_PARENT,
  DYNAMIC_LIST_SET_INITIAL_FILTER,
  DYNAMIC_LIST_SET_CONTENT_TYPE,
  DYNAMIC_LIST_SET_SORT_ORDER,
  DYNAMIC_LIST_SET_FILTER_DEFAULT,
  DYNAMIC_LIST_SET_FILTER_CONTENT_TYPE,
  DYNAMIC_LIST_SET_SHOWONLYMLHPP
} = Actions;

export const defaultFilter = { label: 'Any', value: '00000000-0000-0000-0000-000000000000' };

const initialState = {
  list: [],
  listTotal: -1, // set to -1 before request to track when it's done
  fetching: false,
  fetchingFilter: false,
  filter: [],
  filterDefault: '',
  filterType: '',
  taxonomyParent: '',
  initialFilter: '',
  contentType: '',
	sortOrder: '',
  showOnlyMLHPP:false,
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_DYNAMIC_LIST_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        listTotal: action.listTotal,
        fetching: false
      });
    case REQUEST_DYNAMIC_LIST_FETCHING:
      return Object.assign({}, state, {
        list: [],
        listTotal: -1,
        fetching: true
      });
    case REQUEST_DYNAMIC_FILTER_FETCHING:
      return Object.assign({}, state, {
        fetchingFilter: true
      });
    case REQUEST_DYNAMIC_FILTER_SUCCESS:
      return Object.assign({}, state, {
        filter: action.filter,
        fetchingFilter: false
      });

    case DYNAMIC_LISTING_MERGE_QUERY_PARAMS:
      return {
        ...state,
        filterValue: action.params.filterValue || defaultFilter.value,
        pagination: {
          ...state.pagination,
          currentPage: Number(action.params.page) || 1
        }
      };
    case DYNAMIC_LIST_SET_INITIAL_FILTER:
      return {
        ...state,
        initialFilter: action.filterValue
      };
    case DYNAMIC_LIST_SET_TAXONOMY_PARENT:
      return {
        ...state,
        taxonomyParent: action.taxonomyParent
      };
    case DYNAMIC_LIST_SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: action.contentType
      };
    case DYNAMIC_LIST_SET_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.sortOrder
      };
    case DYNAMIC_LIST_SET_FILTER_DEFAULT:
      return {
        ...state,
        filterDefault: action.filterDefault
      };
    case DYNAMIC_LIST_SET_FILTER_CONTENT_TYPE:
      return {
        ...state,
        filterType: action.filterContentType
		  };
	  case DYNAMIC_LIST_SET_SHOWONLYMLHPP:
	    return {
		    ...state,
			showOnlyMLHPP: action.showOnlyMLHPP
	    };
    default:
      return state;
  }
}


function filterValueFromUrl() {
  const params = getUrlParams();
  if (params.filterValue && params.filterValue.trim() !== "") {
    return params.filterValue;
  }
}

function getUrlParams() {
  let url = URL.getQueryString(), filterItem;
  if (url === null) return {};

  return {
    filterValue: URL.getParameterByName('filterValue', url)
  };
}
