﻿'use strict';

import $ from 'jquery';

var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var monthLongNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];



/* page navs */

var _debug = true;

var page1 = $('#landingPage');
var page2 = $('#formPage');
var page3 = $('#resultsPage');

var tempResult,
    gStartDate,
    gEndDate;

gStartDate = new Date(2021, 9, 1);

function hideAllPages() {
    page1.removeClass("shown");
    page1.addClass("hidden");
    page2.removeClass("shown");
    page2.addClass("hidden");
    page3.removeClass("shown");
    page3.addClass("hidden");
}

function showPage(pageNumber) {
    hideAllPages();
    switch (pageNumber) {
        case 1:
            page1.removeClass("hidden");
            page1.addClass("shown");
            break;
        case 2:
            page2.removeClass("hidden");
            page2.addClass("shown");
            break;
        case 3:
            page3.removeClass("hidden");
            page3.addClass("shown");
            break;
    }
}

/* Page Nav Event Listeners */

$('#btnStartSchedulingForm').on('click', function (evt) {
    showPage(2);
});

/* form page */

var formPage1 = $('#personal-information');
var formPage2 = $('#scheduling-information');
var formPage3 = $('#confirmation');

function hideAllFormPages() {
    formPage1.removeClass("shown");
    formPage1.addClass("hidden");
    formPage2.removeClass("shown");
    formPage2.addClass("hidden");
    formPage3.removeClass("shown");
    formPage3.addClass("hidden");
}

function showFormPage(pageNumber) {
    hideAllFormPages();
    switch (pageNumber) {
        case 1:
            formPage1.removeClass("hidden");
            formPage1.addClass("shown");
            /* stepper class updates */

            break;
        case 2:
            formPage2.removeClass("hidden");
            formPage2.addClass("shown");
            /* stepper class updates */

            break;
        case 3:
            formPage3.removeClass("hidden");
            formPage3.addClass("shown");
            /* stepper class updates */

            break;
    }
}

/* stepper */

function resetAllSteps() {
    $('#stepper-step1').removeClass('active');
    $('#stepper-step2').removeClass('active');
    $('#stepper-step3').removeClass('active');
    $('#stepper-step1').removeClass('done');
    $('#stepper-step2').removeClass('done');
    $('#stepper-step3').removeClass('done');
}

function displayStep(stepID) {

    resetAllSteps();
    switch (stepID) {
        case 1:
            $('#stepper-step1').addClass('active');
            break;
        case 2:
            $('#stepper-step1').addClass('active');
            $('#stepper-step1').addClass('done');
            $('#stepper-step2').addClass('active');
            break;
        case 3:
            $('#stepper-step1').addClass('active');
            $('#stepper-step1').addClass('done');
            $('#stepper-step2').addClass('active');
            $('#stepper-step2').addClass('done');
            $('#stepper-step3').addClass('active');
            break;
    }
}

/* Form Page Nav Event Listeners */
$('button.js-btn-FormPage1').on('click', function (evt) {
    evt.preventDefault();
    jumpToTop();
    showFormPage(1);
    displayStep(1);
});

$('button.js-btn-FormPage2').on('click', function (evt) {
    evt.preventDefault();
    jumpToTop();
    if (formIsValid(1)) {
        showFormPage(2);
        displayStep(2);
        /* populate list of available dates */
        /* populateAvailableDateList(); */
    }
    
});

$('button.js-btn-FormPage3').on('click', function (evt) {
    evt.preventDefault();
    jumpToTop();
    if (formIsValid(2)) {
        showFormPage(3);
        displayStep(3);
    }
    
});

$('button.js-btn-FormSubmit').on('click', function (evt) {
    evt.preventDefault();

    /* gather data */
    if (formIsValid(3))
    {
        var parameters = {
            FirstName: $('#FirstName').val().trim(),
            LastName: $('#LastName').val().trim(),
            PersonalDOB: $('#PersonalDOB').val().trim(),
            Gender: $('input[name="Gender"]:checked').val(),
            ContactEmail: $('#ContactEmail').val().trim(),
            ContactPhone: $('#ContactPhone').val().trim(),
            Date: $('#Date').val().trim(),
            Time: $('input[name="time"]:checked').val().trim(),
            DepartmentID: $('#DepartmentID').val().split("_")[0].trim(),
            ProviderID: $('#DepartmentID').val().split("_")[1].trim()
        };

        /* submit here */
        
        var url = "/api/forms/SubmitVaccinePatientAppointment"
        $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(parameters),
            dataType: 'json',
            contentType: 'application/json'

        })
            .done(function (data, textStatus, jqXHR) {
                if (!$.trim(data)) {
                    writeInConsole("Unable to process scheduling...");
                } else {

                    /* if success... */
                    if (data != null) {
                        showPage(3);
                        populateResults(data);
                    } else {
                        writeInConsole("error");
                    }
                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                writeInConsole('/****** ERROR *******/');
                writeInConsole(this.data);
                writeInConsole(jqXHR);
                writeInConsole(textStatus);
                writeInConsole(errorThrown);
                writeInConsole('/****** End ERROR *******/');
            })
    }
    
    
});

$('#DepartmentID').on('change', function (evt) {
    evt.preventDefault();
    populateAvailableDateList();
    $('#Date').prop("disabled", false);
});

$('#Date').on("change", function (evt) {
    evt.preventDefault();
    populateAvailableTimeSlot();
});

$('input[name="timeslot"]').on("change", function (evt) {
    evt.preventDefault();
});

$('button.js-btn-Restart').on('click', function (evt) {
    evt.preventDefault();
    showPage(1);
    showFormPage(1);
    displayStep(1);
    $('#vaccinationAppointmentForm')[0].reset();
    $('.timeSlotArea').empty();
    jumpToTop();
    return false;
});

$('.nav-next').on('click', function (evt) {
    console.log("show next two weeks")
    clearDateSelect();
    gStartDate = addDays(gStartDate, 15);
    populateAvailableDateList();
    setTimeout(populateAvailableTimeSlot, 1000);
    
});

$('.nav-prev').on('click', function (evt) {
    console.log("show prev two weeks")
    clearDateSelect();
    gStartDate = addDays(gStartDate, -15);
    if (gStartDate.getDate() < new Date(2021, 9, 1).getDate()) {
        gStartDate = new Date(2021, 9, 1);
    }
    populateAvailableDateList();
    setTimeout(populateAvailableTimeSlot, 1000);
});

function jumpToTop() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
}

function getRadioGroupValue(inputSelector) {
    const rbs = document.querySelectorAll(inputSelector);
    let selectedValue;
    for (const rb of rbs) {
        if (rb.checked) {
            selectedValue = rb.value;
            break;
        }
    }
    return selectedValue;
}

/* calendar functions */
function addDays(startDate, numberOfDays) {
    var startDateFormatted = new Date(startDate);
    var endDate = new Date(startDateFormatted.getTime() + (numberOfDays * 24 * 60 * 60 * 1000));
    return endDate
}

function formatTime(strTime) {
    var _time = strTime.split(":");
    var hrs = parseInt(_time[0]);
    var mins = parseInt(_time[1]);
    // forget about seconds for now

    var ampm = hrs >= 12 ? 'PM' : 'AM';
    hrs = hrs % 12;
    hrs = hrs ? hrs : 12; // the hour '0' should be '12'
    mins = mins < 10 ? '0' + mins : mins;
    return hrs + ':' + mins + ' ' + ampm;
}

function formatDate(inputDate, format) {
    var _yyyy = inputDate.getFullYear();
    var _mm;
    var _dd;

    if ((inputDate.getMonth() + 1).toString().length == 1) {
        _mm = "0" + (inputDate.getMonth() + 1).toString();
    } else {
        _mm = (inputDate.getMonth() + 1).toString();
    }

    if ((inputDate.getDate()).toString().length == 1) {
        _dd = "0" + inputDate.getDate().toString();
    } else {
        _dd = inputDate.getDate().toString();
    }

    if (format == "yyyy-mm-dd") {
        return _yyyy + "-" + _mm + "-" + _dd;
    } else{
        return _mm + "/" + _dd + "/" + _yyyy;
    }
    
}

function differenceInDays(date01, date02) {
    // Copy dates so don't affect originals
    date01 = new Date(+date01);
    date02 = new Date(+date02);

    // Set to noon
    date01.setHours(12, 0, 0, 0);
    date02.setHours(12, 0, 0, 0);

    // Get difference in whole days, divide by milliseconds in one day
    // and round to remove any daylight saving boundary effects
    return Math.round((date02 - date01) / 8.64e7)
}

function getDayOfWeek(date) {
    const dayOfWeek = new Date(date).getDay();
    return isNaN(dayOfWeek) ? null :
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
}

/* data scrubber */
function cleanReturnedData(data) {
    data = data.replace(/\\/g, ''); //remove \ slash
    data = data.replace(/s:/g, '');
    data = data.replace(/o:/g, '');
    data = data.replace(/u:/g, '');
    data = data.replace(/a:/g, '');
    data = data.replace(/b:/g, '');
    data = data.replace(/""/g, '"');

    return data;
}

function getDaysInMonth(month, year) {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    return new Date(year, month, 0).getDate();
    // Here January is 0 based
    // return new Date(year, month+1, 0).getDate();
};

function populateAvailableMonthslist() {
    var thisMonth = new Date().getMonth();
    for (var i = 0; i < monthLongNames.length; i++) {
        var isDisabled = false;
        if (thisMonth > i) {
            $('#months').append('<option value="' + (i + 1) + '" disabled >' + monthLongNames[i] + '</option>')
        } else {
            $('#months').append('<option value="' + (i + 1) + '" >' + monthLongNames[i] + '</option>')
        }
        
    }
}

function populateAvailableDateList() {
    var param = $("#DepartmentID").val();

    var _initParamArrays = param.split("_");
    var startDate = gStartDate;
    var endDate = addDays(startDate, 14);
    gEndDate = new Date(endDate);
    var parameters = { StartDate: formatDate(new Date(startDate), "yyyy-mm-dd"), EndDate: formatDate(new Date(endDate), "yyyy-mm-dd"), ProviderID: _initParamArrays[1], ProviderIDType: "EXTERNAL" };


    if (parameters != undefined) {
        var url = "/api/forms/GetScheduleDaysForProvider/"
        $.ajax({
            type: "POST",
            url: url,
            data: parameters,
            dataType: "text",
            async: true,
            timeout: 105000
        })
        .done(function (data, textStatus, jqXHR) {
            if (!$.trim(data)) {
                console.log("empty data...");
                buildInvalidCalendar(epicIDs);
            }
            else {
                data = $.trim(data);
                data = data.substring(1, data.length - 1); // remove first and last characters (");

                if (data.length != 0) {
                    var cleanedData = cleanReturnedData(data);
                    var xmlDoc = $.parseXML(cleanedData),
                        xml = $(xmlDoc),
                        days = xml.find("ScheduleDay");

                    try {
                        var _daysFiltered = [];
                        $(days).each(function () {
                            if (($(this).find("Department").find("ID").text() == _initParamArrays[0])){
                                var _thisDate = $(this).find("Date").text();
                                /*_daysFiltered.push($(this));*/

                                $('#Date').append('<option value="' + formatDate(addDays(new Date(_thisDate), 1), "default") + '">' + formatDate(addDays(new Date(_thisDate), 1), "default") + '</option>');
                            }
                        });
                        /* update date range info */
                        $('.currentDateRange').html(": Displaying " + formatDate(new Date(gStartDate), "default") + " to " + formatDate(new Date(gEndDate)));

                        /*enable next/prev buttons */
                        if (gStartDate.getDate() === new Date(2021, 10, 1).getDate()) {
                            //Beginning
                            $('.nav-prev').prop("disabled", true);
                            $('.nav-next').prop("disabled", false);

                        } else {
                            $('.nav-prev').prop("disabled", false);
                            $('.nav-next').prop("disabled", false);
                        }
                    }

                    catch (err) {

                        console.log("Error checking for an available slot in current week");
                        alert(err.message);
                    }


                } else {
                    // returned an empty string
                    showSysError(epicIDs);
                }


            }
        })
            .fail(function (jqXHR, textStatus, errorThrown) {
                writeInConsole('/****** ERROR *******/');
                writeInConsole(jqXHR);
                writeInConsole(textStatus);
                writeInConsole(errorThrown);
                writeInConsole('/****** End ERROR *******/');
            })
    }
    
}

function populateAvailableTimeSlot() {
    var param1 = $("#DepartmentID").val()
    var param2 = $("#Date").val();
    if ((param1 != undefined) && (param2 != undefined)) {
        var url = "/api/forms/GetProviderSchedule/?id=" + param1 + "&date=" + param2
            $.ajax({
                type: "GET",
                url: url,
                dataType: "TEXT",
                async: true,
                timeout: 10500
            })
            .done(function (data, textStatus, jqXHR) {
                if (!$.trim(data)) {
                    console.log("empty GetProviderSchedule data...");
                } else {
                    /* clear out the time slot area first */
                    $('.timeSlotArea').html("");
                    data = $.trim(data);

                    if (data.length != 0) {
                        var dataObjJson = JSON.parse(JSON.parse(data).data);
                        var dayScheduleResultJSON = dataObjJson["s:Envelope"]["s:Body"].GetProviderScheduleResponse.GetProviderScheduleResult;

                        /* header */
                        $('.timeSlotArea').append('<div class="scheduleInfo-header"/>');
                        $('.scheduleInfo-header').append('<h3>' + getDayOfWeek($('#Date option:selected').text()) + " - " + $('#Date option:selected').text() + '</h3>');
                        $('.scheduleInfo-header').append('<h5 style="margin: 1em 0em;">' + $('#Location option:selected').text() + '</h5>');
                        $('.scheduleInfo-header').append('<h5 style="margin: 1em 0em; color: #00739d;">' + dayScheduleResultJSON.DepartmentName + '</h5>');
                        
                        $('.timeSlotArea').append('<div class="scheduleInfo-slotArea"/>');

                        
                        if (dayScheduleResultJSON.ScheduleSlots != null) {

                            var daysTimeSlots = dayScheduleResultJSON.ScheduleSlots.ScheduleSlot;
                            var rowNumber = 0;
                            var timeArray = new Array();
                            /* this only works if all times are retrieved, but only available timees are returned */
                            /*
                            daysTimeSlots.forEach(function (item, index) {
                               
                                if ((index % 6) == 0) {
                                    rowNumber++;
                                    $('.scheduleInfo-slotArea').append('<div class="slots-row-header">' + item.StartTime +'</div>');
                                    $('.scheduleInfo-slotArea').append('<div class="slots-row row'+rowNumber+'">');
                                }
                                if (parseInt(item.AvailableOpenings) > 0) {
                                    $('.slots-row.row' + rowNumber).append('<div class="slots-item"><label class="appt-toggleButton"><input type="radio" name="time" value="' + item.StartTime + '"><span>' + item.StartTime + '</span></label></div>');
    
                                } else {
                                    $('.slots-row.row' + rowNumber).append('<div class="slots-item"><label class="appt-toggleButton"><input type="radio" name="time" disabled="disabled" value="' + item.StartTime + '"><span>' + item.StartTime + '</span></label></div>');
    
                                }
                            });
    
                            */
                            var compareHour = "";
                            daysTimeSlots.forEach(function (item, index) {
                                var currTime = item.StartTime.trim();
                                var currTimeArr = currTime.split(":");
                                var currHour = currTimeArr[0];
                                var curAMPM = currTimeArr[1].slice(-2);
                                if (compareHour != currHour) {
                                    rowNumber++;
                                    compareHour = currHour;
                                    $('.scheduleInfo-slotArea').append('<div class="slots-row-header">' + currHour + ':00 ' + curAMPM + '</div>');
                                    $('.scheduleInfo-slotArea').append('<div class="slots-row row' + rowNumber + '" />');

                                }
                                if (parseInt(item.AvailableOpenings) > 0) {
                                    $('.slots-row.row' + rowNumber).append('<div class="slots-item"><label class="appt-toggleButton"><input type="radio" name="time" value="' + item.StartTime + '"><span>' + item.StartTime + '</span></label></div>');

                                } else {
                                    $('.slots-row.row' + rowNumber).append('<div class="slots-item"><label class="appt-toggleButton"><input type="radio" name="time" disabled="disabled" value="' + item.StartTime + '"><span>' + item.StartTime + '</span></label></div>');

                                }
                            });
                        } else {
                            $('.scheduleInfo-slotArea').append('<div><b>There are no available appointment for this selected day.</b></div>')
                        }

                        
                    }

                }
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                writeInConsole('/****** ERROR *******/');
                writeInConsole(jqXHR);
                writeInConsole(textStatus);
                writeInConsole(errorThrown);
                writeInConsole('/****** End ERROR *******/');

                $('.timeSlotArea').html(errorThrown);
            })
    }
}

function clearDateSelect() {
    //<option value="" disabled selected>-Select a Date-</option>
    $('#Date')
        .find('option')
        .remove()
        .end()
        .append('<option disabled selected>-Select a Date-</option>')
        .val('')
        ;
    //$('.timeSlotArea').html("");
    $('#Date').selectedIndex = 0;
}

function getDayOftheWeek(dateInString) {
    var _date = new Date(dateInString);
    return daysOfWeek[_date.getDay()];
}

function geMonthName(dateInString) {
    var _date = new Date(dateInString);

    return monthNames[_date.getMonth()];
}

function getDayandYear(dateInString) {
    var _date = new Date(dateInString);
    return _date.getDate() + ", " + _date.getFullYear()
}

function getFullResultsDate(dateInString) {
    return getDayOftheWeek(dateInString) + " " + geMonthName(dateInString) + " " + getDayandYear(dateInString);
}

function getPatientInstructions(instructionsStringArray) {
    return instructionsStringArray.join("")
}

function populateResults(data) {

    /* populate registrant name */
    var nameTarget = page3.find('span.patName');
    nameTarget.html($('#FirstName').val().trim() + " " + $('#LastName').val().trim());

    /* populate appointment summary */
    var apptTarget = page3.find('div#appointmentDate');
    apptTarget.empty();
    apptTarget.append('<div class="appt-result-date">' + getFullResultsDate(data.date) + '</div>');
    apptTarget.append('<div class="appt-result-arriveTime">' + 'Arrive by ' + data.extraData[0].values[0] + '</div>');
    apptTarget.append('<div class="appt-result-startTime">' + 'Starts at ' + data.providers[0].time + ' (' + data.providers[0].duration + ' Minutes)</div>');

    /* populate location summary */
    var locTarget = page3.find('div#appointmentLocation');
    
    locTarget.empty();
    locTarget.append('<div class="appt-result-location">' + $('#DepartmentID option:selected').text() + '</div>');
    locTarget.append('<div class="appt-result-location-Address">' + getAddress() + '</div>');

/* populate clinic location summary */
    var patInstructionTarget = page3.find('div#patientInstructions');
    patInstructionTarget.empty();
    patInstructionTarget.append('<div class="appt-result-instructions-title"><h4 class="noPadding noMargin">Instructions:</h4></div>');
    patInstructionTarget.append('<div class="appt-result-instructions-title">' + getPatientInstructions(data.patientInstructions) +'</div>');

}

function getAddress() {
    //var selectedDepartment = $('#DepartmentID option:selected').val();

    return $('#DepartmentID option:selected').attr("data-address");
}

function showProps(obj, objName) {
    var result = '';
    for (var i in obj) {
        // obj.hasOwnProperty() is used to filter out properties from the object's prototype chain
        if (obj.hasOwnProperty(i)) {
            result += '${objName}.${i} = ${obj[i]}\n';
        }
    }
    return result;
}


function writeInConsole(message) {
    if (_debug) {
        console.log(message);
    }
}

function formIsValid(formPage) {
    var isValid = true;
    var errorMsg = "No Error.";
    var errorLog = new Array();

    switch (formPage) {
        case 1:
        /* personal information */
            if ($('#LastName').val() == "")
            {
                isValid = false;
                errorLog.push("Last Name");
            }
            if ($('#FirstName').val() == "")
            {
                isValid = false;
                errorLog.push("First Name");
            }
            if ($('#PersonalDOB').val() == "") {
                isValid = false;
                errorLog.push("Date of Birth");
            }

            if (getRadioGroupValue('input[name="Gender"]') == undefined) {
                isValid = false;
                errorLog.push("Gender");
            }

            if ($('#ContactEmail').val() == "") {
                isValid = false;
                errorLog.push("Email");
            }

            if ($('#ContactPhone').val() == "") {
                isValid = false;
                errorLog.push("Phone");
            }
            break;
        case 2:
            /* date and time */
            if ($('#DepartmentID').val() == null) {
                isValid = false;
                errorLog.push("Location");
            }

            if ($('#Date').val() == null) {
                isValid = false;
                errorLog.push("Date");
            }

            if (getRadioGroupValue('input[name="time"]') == undefined) {
                isValid = false;
                errorLog.push("Time");
            }
            break;
        case 3:
            /* reCaptcha */
            if (grecaptcha.getResponse().length == 0)
            {
                isValid = false;
                errorLog.push("Recaptcha");
            }
            break;
    }

    if (!isValid) {
        alert("Error!\nPlease make sure the following fields have a valid entry: \n\n" + errorLog.join("\n"));
        return false;
    }

    return isValid
}