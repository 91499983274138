'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class NewsBlogArticleSearchItem extends Component {
  static propTypes = {
    article: PropTypes.shape({
      authors: PropTypes.string,
      dateOfPublication: PropTypes.string.isRequired,
      locations: PropTypes.string,
      articleType: PropTypes.string.isRequired,
      pageTitle: PropTypes.string.isRequired,
      summary: PropTypes.string,
      readMoreLink: PropTypes.string.isRequired,
      thumbnail: PropTypes.string
    }).isRequired,
    linkName: PropTypes.string.isRequired
  }

  constructor() {
    super();
    this.getDate = this.getDate.bind(this);
  }

  getDate(date) {
    let dateObj = new Date(date),
        fullMonth = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];
    return `${fullMonth[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
  }

  render() {
    const {article, linkName} = this.props;

    return (
      <div>
        <div className='list__meta'>
          { !!article.locations &&
            <span className='list__location'>{article.locations}</span>
          }
          <span className='list__date'>
            {this.getDate(article.dateOfPublication)}
          </span>
          <span className='list__cat'>{article.articleType}</span>
          { !!article.authors &&
            <span className='list__author'>{article.authors}</span>
          }
        </div>
        { article.thumbnail &&
          <a href={article.readMoreLink} className='list__img'>
            <img src={article.thumbnail}
                 alt={article.pageTitle + ' Picture'} />
          </a>
        }
        <div className={'list__content' +
               (article.thumbnail ? ' has-img' : '')}>
          <h2 className='list__title'>
            <a href={article.readMoreLink}>{article.pageTitle}</a>
          </h2>
          <p>{article.summary}</p>
          <a className="link" href={article.readMoreLink}>{linkName}</a>
        </div>
      </div>
    );
  }
};
