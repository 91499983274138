// This is a Pagination component using React Router <Link> to handle page changes.
// Consider using PaginationNoRouter in conjunction with Redux routing middleware
// in order to have the Redux store handle all changes and sync the query string
import React, { Component } from 'react';
import PropTypes from "prop-types";
import queryStringApp from "query-string";

import { Link } from '../../../vendor/react-router.js';
import { calculatePagination } from '../../urlState.js';
import { withRouter } from '../../../vendor/react-router.js';

@withRouter
export default class Pagination extends Component {
  static propTypes = {
    collectionTotal: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    paginationCount: PropTypes.number.isRequired,
    paginationPosition: PropTypes.string.isRequired,
    paginationShowing: PropTypes.string.isRequired,
    paginationAll: PropTypes.string.isRequired,
    paginationOf: PropTypes.string.isRequired,
    paginationResults: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.changePage = this.changePage.bind(this);
    this.getLinkUrl = this.getLinkUrl.bind(this);

    this.calculateNumOfPages();
  }

  componentWillReceiveProps(nextProps) {
    this.calculateNumOfPages(nextProps);
  }

  calculateNumOfPages(nextProps) {
    const {collectionTotal, paginationCount} = nextProps || this.props;

    this.numOfPages = Math.ceil(collectionTotal / paginationCount);
  }

  changePage() {
    // scroll to the top of the page minus the sticky header (100) plus the remaining banner (100)
    window.scrollTo(0, 200);
  }

  getLinkUrl(page, query, pathname) {
    const obj = {
      pathname,
      query: {
        ...query,
        page: page
      }
    };

    if(page === 1) {
      delete obj.query.page;
    }

    return obj;
  }

  getPages() {
    if (this.numOfPages < 2) return;
    const {currentPage} = this.props;
    const query = queryStringApp.parse(window.location.search);
    const pathname = window.location.pathname;

    let elems = [], i, j;

    // calculate start and end of pagination numbers if pagination has over 5
    // pages
    if (currentPage - 2 < 1) {
      i = 1;
      j = 5;
    } else if (currentPage + 2 > this.numOfPages) {
      i = this.numOfPages - 5;
      j = this.numOfPages;
    } else {
      i = currentPage - 2;
      j = currentPage + 2;
    }

    // if pagination has 5 or less pages, reset start and end from previous
    // calculations
    if (this.numOfPages <= 5) {
      i = 1;
      j = this.numOfPages;
    }

    for (i; i <= j; i++) {
      elems.push(
        <li key={i} className={ i === currentPage && 'current' }>
          { i === currentPage ? i :
            <Link 
              onClick= { this.changePage }
              to={ this.getLinkUrl(i, query, pathname) }>{i}</Link> }
        </li>
      );
    }

    return elems;
  }

  getPagesShowing() {
    const {collectionTotal, currentPage, paginationAll, paginationOf} = this.props;
    const { currentStart, currentEnd } = calculatePagination(currentPage);

    if (this.numOfPages <= 1) {
      return paginationAll;
    }

    let end = currentEnd < collectionTotal ? currentEnd : collectionTotal;

    return `${currentStart}-${end} ${paginationOf}`;
  }

  render() {
    const { 
      collectionTotal, 
      paginationPosition, 
      currentPage, 
      paginationShowing, 
      paginationResults 
    } = this.props;

    const query = queryStringApp.parse(window.location.search);
    const pathname = window.location.pathname;

    return (
      collectionTotal > 0 ? (
        <div className={'pager pager--' + paginationPosition}>
          <div>
            <div className='pager__amount'>
              { paginationShowing } { this.getPagesShowing() } { collectionTotal } &nbsp;{ paginationResults }
            </div>
            { this.numOfPages > 1 &&
              <ul className='pager__pagination'>
                { currentPage !== 1 &&
                  <li>
                    <Link to={  this.getLinkUrl(currentPage - 1, query, pathname) }>
                      <i className='fa fa-angle-left'></i>
                    </Link>
                  </li>
                }
                { this.getPages() }
                { currentPage !== this.numOfPages &&
                  <li>
                    <Link to={  this.getLinkUrl(currentPage + 1, query, pathname) }>
                      <i className='fa fa-angle-right'></i>
                    </Link>
                  </li>
                }
              </ul>
            }
          </div>
        </div>
      ) : null
    );
  }
}
