'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';
import { defaultFilter } from '../reducers/dynamicListing';
import UrlState from '../components/dynamic-listing/urlState.js';
import { calculatePagination } from '../urlState.js';

const { getUrlState } = UrlState;

const {
  REQUEST_DYNAMIC_LIST_SUCCESS,
  REQUEST_DYNAMIC_LIST_FETCHING,
  REQUEST_DYNAMIC_FILTER_SUCCESS,
  REQUEST_DYNAMIC_FILTER_FETCHING,
  DYNAMIC_LISTING_MERGE_QUERY_PARAMS,
  DYNAMIC_LIST_SET_TAXONOMY_PARENT,
  DYNAMIC_LIST_SET_INITIAL_FILTER,
  DYNAMIC_LIST_SET_CONTENT_TYPE,
  DYNAMIC_LIST_SET_SORT_ORDER,
  DYNAMIC_LIST_SET_FILTER_DEFAULT,
  DYNAMIC_LIST_SET_FILTER_CONTENT_TYPE,
  DYNAMIC_LIST_SET_SHOWONLYMLHPP
} = Actions;

function stripTimeOffset(data) {
        data.results.forEach(function (result) {
                var startTime = result.startTime;
                var endTime = result.endTime;
                result.startTime = startTime.substring(0, startTime.length - 1);
                result.endTime = endTime.substring(0, endTime.length - 1);
        })
    return data
}


const listGetSuccess = data => ({
    type: REQUEST_DYNAMIC_LIST_SUCCESS,
    data: stripTimeOffset(data),
    list: data.results,
  listTotal: data.totalResults
});


const listFetching = () => ({ type: REQUEST_DYNAMIC_LIST_FETCHING });
const filterGetSuccess =  data => ({ type: REQUEST_DYNAMIC_FILTER_SUCCESS, filter: data });
const filterFetching = () => ({ type: REQUEST_DYNAMIC_FILTER_FETCHING, });

export const mergeQueryParams = params => ({ type: DYNAMIC_LISTING_MERGE_QUERY_PARAMS, params });
export const setTaxonomyParent = taxonomyParent => ({ type: DYNAMIC_LIST_SET_TAXONOMY_PARENT, taxonomyParent });
export const setContentType = contentType => ({ type: DYNAMIC_LIST_SET_CONTENT_TYPE, contentType });
export const setInitialFilter = filterValue => ({ type: DYNAMIC_LIST_SET_INITIAL_FILTER, filterValue });
export const setSortOrder = sortOrder => ({ type: DYNAMIC_LIST_SET_SORT_ORDER, sortOrder });
export const setFilterDefault = filterDefault => ({ type: DYNAMIC_LIST_SET_FILTER_DEFAULT, filterDefault });
export const setFilterType = filterContentType => ({ type: DYNAMIC_LIST_SET_FILTER_CONTENT_TYPE, filterContentType });
export const setShowOnlyMLHPP = showOnlyMLHPP => ({ type: DYNAMIC_LIST_SET_SHOWONLYMLHPP, showOnlyMLHPP });

export const loadQueryParamChange = urlParams =>
  dispatch => {
    dispatch(mergeQueryParams(urlParams));
    dispatch(fetchList());
  };



export const fetchList = () =>
	(dispatch, getState) => {
		const { page, filterValue } = getUrlState();
		const { currentStart, currentEnd } = calculatePagination(page);
    const listing = getState();

    let params = {
		Start: currentStart,
		End: currentEnd,
      TaxonomyParentGuid: listing.taxonomyParent,
      ContentTypeGuid: listing.contentType,
		ResultsSortOrder: listing.sortOrder,
		showOnlyMLHPP: listing.showOnlyMLHPP
    };

    if (filterValue && filterValue !== defaultFilter.value) {
      params.FilterItem = filterValue;
    }

    dispatch(listFetching());

    $.ajax({
      url: '/api/search/dynamiclisting/get',
      cache: false,
      data: params,
      method: 'POST'
    })
        //myFunction(done(response => dispatch(listGetSuccess(response))))
        //success: function(result)
        //   .done((response.contentType == "event")=> dispatch(listGetSuccess1(response)))
     //   .myFunction(response => dispatch(listGetSuccess(response)))
      .done(response => dispatch(listGetSuccess(response)))
      .fail(() => dispatch(listGetSuccess({ "totalResults": 0, "results": [] })));
  };


export const fetchFilter = () =>
  (dispatch, getState) => {
    const listing = getState();

    let params = {
      TaxonomyParentGuid: listing.taxonomyParent,
      ContentTypeGuid: listing.contentType,
      FilterContentType: listing.filterType,
      DefaultValue: listing.filterDefault
    };

	  dispatch(filterFetching());

    $.ajax({
      url: '/api/search/dynamiclisting/getfilters',
      cache: false,
      data: params,
      method: 'POST'
    })
      .done(response => {
        dispatch(filterGetSuccess(response));
        dispatch(fetchList());
      })
      .fail(() => dispatch(filterGetSuccess([])));
  };