'use strict';

import $ from 'jquery';


//helper functions


/* doctor's appointment form */
$('.js-doctor-appointment').each(function () {

    let $el = $(this),
        $submit = $el.find('.js-multipage-form-submit'),
        valid = [],
        url = $el.data('end-point');

    /* load new specialty dropdown value */
    // health - preferred - specialty
    $.getJSON("/assets/csv/specialties.json", function (data) {
        var specialtiesArr = [];
        $.each(data.results, function (key, val) {
            specialtiesArr.push(new Object({ id: val.Name, text: val.Name }));
            //console.log(key, val.Name)
        });

        // build select2
        $('#health-preferred-specialty').select2({
            placeholder: {
                id: '-1', // the value of the option
                text: 'Select an option'
            },
            data: specialtiesArr
        });
    });

    //** VALIDATION **//
    // find all the fields to validate
    $el.find('.appointment-form__concise').each(function(index){
        let page = index;
        valid[page] = [];
        // find all fields marked with the "is-required" class
        $(this).find('.is-required').each(function(index){
            let type = $(this).data('type'),
                field = index;

            valid[page].push(false);
            initValidation($(this),type,field,page);
        });
    });

    function initValidation($el, type, field, page){

        switch(type) {
            case "radio":
                // if a radio button isn't already selected
                if($el.find('[type=radio]:checked').length === 0){
                    $el.find('[type=radio]').on('change.radio',function(){
                        valid[page][field] = true;
                        $el.removeClass('form-item--invalid');
                        $el.find('[type=radio]').unbind('change.radio');
                    });
                } else {
                    valid[page][field] = true;
                }
                $el.on('retest',function(){
                    valid[page][field] = testRadio($(this));
                });
            break;
            case "checkbox":

            break;
            case "date":
                $el.on("change retest", function(e){
                    valid[page][field] = testDate($(this));
                });
                $el.bind
            break;
            case "phone":
                $el.on("keyup retest", function(e){
                    if(testArrow(e)){
                        return;
                    }
                    valid[page][field] = testPhone($(this));
                });
            break;
            case "email":
                $el.on("keyup retest", function(e){
                    if(testArrow(e)){
                        return;
                    }
                    valid[page][field] = testEmail($(this));
                });
            break;
            case "zip":
                $el.on("keyup retest", function(e){
                    if(testArrow(e)){
                        return;
                    }
                    valid[page][field] = testZip($(this));
                });
            break;
            case "select":
                $el.on("change retest", function(e){
                    valid[page][field] = testSelect($(this))
                });
            break;
            default:
                $el.on("keyup retest", function(e){
                    if(testArrow(e)){
                        return;
                    }
                    valid[page][field] = testEmpty($(this));
                });
        }
    }
    function validatePage($page,index){
        // check the fields on the current page to see if they are all true
        $page.find('.is-required').trigger('retest');
        return !(valid[index].some((field) => field === false));
    }
    // ignore left and right arrow keys
    function testArrow(e) {
        if(e.keyCode === 37 || e.keyCode === 39){
            return true;
        }
        return false;
    }
    function testDate($el) {
            let value = $el.val(),
                date = new Date(value),
                valid = true;

            if($el.attr('type') !== 'date') {
                valid = value.match(/[0-9]{2}\/[0-9]{2}\/[[0-9]{4}/);
            }

        if(valid){
            valid = !!(value) && !(isNaN(date.getTime()));
        }
        toggleInvalid($el, valid)
        return valid;
    }
    function testEmail($el) {
        let valid = !!($el.val().match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+/i));
        toggleInvalid($el, valid);
        return valid;
    }
    function testEmpty($el) {
        let valid = !!($el.val());
        toggleInvalid($el, valid);
        return valid;
    }
    function testPhone($el){
        // convert to just numbers
        let value = $el.val().replace(/\D/g,''),
            phone,
            valid = false;

        // is there a value
        if(value.length !== 0) {
            phone = value = $el.val().replace(/\D/g,'');
            // enough numbers entered
            if(value.length >= 10){
                phone = value.slice(0,3) +
                    "." + value.slice(3,6) +
                    "." + value.slice(6,10);
                valid = true;
            }
        }
        $el.val(phone);
        toggleInvalid($el, valid);
        return valid;
    }
    function testRadio($el) {
        let valid = !!$el.find('[type=radio]:checked').length;
        toggleInvalid($el, valid);
    }
    function testSelect($el) {
        // check if the first item is not selected
        let valid = !!($el.val());
        toggleInvalid($el, valid)
        return valid;
    }
    function testZip($el) {
        // convert to just numbers
        let value = $el.val().replace(/\D/g,''),
            zip,
            valid = false;

        // is there a value
        if(value.length !== 0) {
            zip = value = $el.val().replace(/\D/g,'');
            // enough numbers entered
            if(value.length === 5 || value.length >= 9){
                valid = true;
            }
            if(value.length > 5){
                zip = value.slice(0,5) +
                    "-" + value.slice(5,9);
            }
        }
        $el.val(zip);
        toggleInvalid($el, valid)
        return valid;
    }
    function toggleInvalid($el, boolean){
        if(boolean){
            $el.removeClass('form-item--invalid');
        } else {
            $el.addClass('form-item--invalid');
        }
    }

    //** Form Submit without paypal **//
    $el.submit(function(e) {
        e.preventDefault(); // avoid to execute the actual submit of the form.
        let dataArray,
            thouShallNotPass = false,
            data,
            paypalField,
            recaptchaField,
            isPaypal,
            paypalEndpoint;

        // check if all fields on this page are filled out
        $el.find('.appointment-form__concise').each(function(i){
            if( !validatePage($(this),i) ){
                thouShallNotPass = true;
            }
        });

        if (thouShallNotPass) {
            $el.find('.js-multipage-form-js-fail-msg').show();
            return false;
        } else {
            $el.find('.js-multipage-form-js-fail-msg').hide();
        }

        //check for paypal field
        paypalField = $('.js-paypal-field');
        if (paypalField.length !== 0 && paypalField.val() === "") {
            isPaypal = true;
            paypalEndpoint = paypalField.data("endpoint");
        }

        // check if recaptcha is valid
        recaptchaField = $('.js-recaptcha-field');
        if(recaptchaField.length !== 0) {
            if(grecaptcha.getResponse() === ""){
                $('.g-recaptcha iframe').css({ "border": "1px solid #ee5f5b" });
                $el.find('.js-multipage-form-js-fail-msg').show();
                return false;
            } else {
                $('.g-recaptcha iframe').css({ "border-color": "transparent" });
                $el.find('.js-multipage-form-js-fail-msg').hide();
            }
        }

        // disable the submit button--large
        $submit.prop("disabled",true);
        // hide existing messages
        $el.find('.js-multipage-form-fail-msg').stop(true,true).fadeOut();
        $('.js-multipage-form-success-msg').stop(true,true).fadeOut();
        // show loading gif
        $submit.find('.spinner').show();
        $submit.find('button').hide();

        // gather data
        dataArray = $(this).serializeArray();
        data = dataArray.reduce( (obj, current) => {
            obj[current.name] = current.value;
            return obj;
        },{});

        // submit data to server
        $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(data),
            dataType: "json",
            contentType : "application/json",
            success: function(data) {
                if (isPaypal) {
                    window.location.href = paypalEndpoint + "?SECURETOKENID=" +
                        data.token.secureTokenId +
                        "&SECURETOKEN=" +
                        data.token.secureToken +
                        "&USER1=" +
                        data.transactionId;
                    return;
                }

                /* ga */
                var eventName = $('h1.eventName').text();
                var eventDate = $('.detail__event-date').clone().children().remove().end().text().trim() + " " + $('.detail__event-date__day').text() + ", " + $('.detail__event-date__year').text();
                var passVal = eventName + "|" + eventDate;
                window.dataLayer.push({ 'event':'trackEvent', 'eventCategory':'eventForm', 'eventAction':'registration', 'eventLabel': passVal});
                window.dataLayer.push({'u1':eventName, 'u2':eventDate});

                var redirect = $('.js-multipage-form-success-msg').data("url");
                $('.js-multipage-form-success-msg').stop(true,true).fadeIn();
                window.location.href = redirect + '?u1=' + eventName + '&u2=' + eventDate;
                //console.log('redirecting...' + redirect + '?u1=' + eventName + '&u2=' + eventDate);
            },
            error: function(error) {
                //re-enable button
                $submit.prop("disabled",false);
                // hide loading gif
                $submit.find('.spinner').hide();
                $submit.find('button').show();
                // if it's a recaptcha error
                if(error.status==401) {
                    $('.g-recaptcha iframe').css({"border":"1px solid #ee5f5b"});
                    return;
                }
                $el.find('.js-multipage-form-fail-msg').stop(true,true).fadeIn();
            }
        });
    });
});


/* to be deleted - no longer in use */
/* event registration form */
$('.js-events-registration').each(function () {
    let $el = $(this),
        numSlides,
        bxSlider,
        $next = $el.find('.js-multipage-form-next'),
        $prev = $el.find('.js-multipage-form-prev'),
        $submit = $el.find('.js-multipage-form-submit'),
        valid = [],
        url = $el.data('end-point');

    //** CONTROLS **//
    $el.find('.js-multipage-form-page .container').hide();
    $el.find('.js-multipage-form-page').first().find('.container').show();

    bxSlider = $el.find('.js-multipage-form-slides').bxSlider({
        adaptiveHeight: true,
        controls: false,
        infiniteLoop: false,
        pager: false,
        touchEnabled: false, // blocks the form entry in IE11 on touch device
        onSlideBefore: function ($slideElement, oldIndex, newIndex) {
            let thouShallNotPass = false;
            // if moving forward
            if (oldIndex < newIndex) {
                thouShallNotPass = !(validatePage($el.find('.js-multipage-form-page').eq(oldIndex), oldIndex));
            }
            // check the fields on the current page to see if they are all true
            if (thouShallNotPass) {
                return false;
            }
            $('.js-multipage-form-page .container').hide();
            $slideElement.find('.container').show();

            // if we're now at the beginning
            if (newIndex === 0) {
                $prev.hide();
            } else {
                $prev.show();
            }
            // if we're now at the end
            if (newIndex === numSlides - 1) {
                $next.hide();
                $submit.show();
            } else {
                $next.show();
                $submit.hide();
            }
            // update the step links
            $el
                .find('.js-multipage-form-step')
                .removeClass('is-active')
                .eq(newIndex)
                .addClass('is-active');
        },
        onSlideAfter: function ($slideElement, oldIndex, newIndex) {
            ga('gtm1.set', 'page', '/appointments/request-physician-appointment?#page' + (oldIndex + 1));
            ga('gtm1.send', 'pageview');
            //console.log('/appointments/request-physician-appointment?#page' + (oldIndex+1) + ' completed.');
        }
    });
    // used to show the submit button
    numSlides = bxSlider.getSlideCount();

    $prev.hide();

    if (numSlides < 2) {
        $next.hide();
        $submit.show();
    }

    // when the user clicks a step link
    $el.find('.js-multipage-form-step').each(function (index) {
        $(this).on('click', function () {
            bxSlider.goToSlide(index);
        });
    });
    // when the user clicks the previous button
    $prev.on('click', function (e) {
        e.preventDefault();
        bxSlider.goToPrevSlide();
        $('html, body').animate({
            scrollTop: $(".js-multipage-form").offset().top - 100
        }, 1000);
    });
    // when the user clicks the next button
    $next.on('click', function (e) {
        e.preventDefault();
        bxSlider.goToNextSlide();
        $('html, body').animate({
            scrollTop: $(".js-multipage-form").offset().top - 130
        }, 1000);
    });

    //** VALIDATION **//
    // find all the fields to validate
    $el.find('.js-multipage-form-page').each(function (index) {
        let page = index;
        valid[page] = [];
        // find all fields marked with the "is-required" class
        $(this).find('.is-required').each(function (index) {
            let type = $(this).data('type'),
                field = index;

            valid[page].push(false);
            initValidation($(this), type, field, page);
        });
    });

    function initValidation($el, type, field, page) {

        switch (type) {
            case "radio":
                // if a radio button isn't already selected
                if ($el.find('[type=radio]:checked').length === 0) {
                    $el.find('[type=radio]').on('change.radio', function () {
                        valid[page][field] = true;
                        $el.removeClass('form-item--invalid');
                        $el.find('[type=radio]').unbind('change.radio');
                    });
                } else {
                    valid[page][field] = true;
                }
                $el.on('retest', function () {
                    valid[page][field] = testRadio($(this));
                });
                break;
            case "checkbox":

                break;
            case "date":
                $el.on("change retest", function (e) {
                    valid[page][field] = testDate($(this));
                });
                $el.bind
                break;
            case "phone":
                $el.on("keyup retest", function (e) {
                    if (testArrow(e)) {
                        return;
                    }
                    valid[page][field] = testPhone($(this));
                });
                break;
            case "email":
                $el.on("keyup retest", function (e) {
                    if (testArrow(e)) {
                        return;
                    }
                    valid[page][field] = testEmail($(this));
                });
                break;
            case "zip":
                $el.on("keyup retest", function (e) {
                    if (testArrow(e)) {
                        return;
                    }
                    valid[page][field] = testZip($(this));
                });
                break;
            case "select":
                $el.on("change retest", function (e) {
                    valid[page][field] = testSelect($(this))
                });
                break;
            default:
                $el.on("keyup retest", function (e) {
                    if (testArrow(e)) {
                        return;
                    }
                    valid[page][field] = testEmpty($(this));
                });
        }
    }
    function validatePage($page, index) {
        // check the fields on the current page to see if they are all true
        $page.find('.is-required').trigger('retest');
        return !(valid[index].some((field) => field === false));
    }
    // ignore left and right arrow keys
    function testArrow(e) {
        if (e.keyCode === 37 || e.keyCode === 39) {
            return true;
        }
        return false;
    }
    function testDate($el) {
        let value = $el.val(),
            date = new Date(value),
            valid = true;

        if ($el.attr('type') !== 'date') {
            valid = value.match(/[0-9]{2}\/[0-9]{2}\/[[0-9]{4}/);
        }

        if (valid) {
            valid = !!(value) && !(isNaN(date.getTime()));
        }
        toggleInvalid($el, valid)
        return valid;
    }
    function testEmail($el) {
        let valid = !!($el.val().match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+/i));
        toggleInvalid($el, valid);
        return valid;
    }
    function testEmpty($el) {
        let valid = !!($el.val());
        toggleInvalid($el, valid);
        return valid;
    }
    function testPhone($el) {
        // convert to just numbers
        let value = $el.val().replace(/\D/g, ''),
            phone,
            valid = false;

        // is there a value
        if (value.length !== 0) {
            phone = value = $el.val().replace(/\D/g, '');
            // enough numbers entered
            if (value.length >= 10) {
                phone = value.slice(0, 3) +
                    "." + value.slice(3, 6) +
                    "." + value.slice(6, 10);
                valid = true;
            }
        }
        $el.val(phone);
        toggleInvalid($el, valid);
        return valid;
    }
    function testRadio($el) {
        let valid = !!$el.find('[type=radio]:checked').length;
        toggleInvalid($el, valid);
    }
    function testSelect($el) {
        // check if the first item is not selected
        let valid = !!($el.val());
        toggleInvalid($el, valid)
        return valid;
    }
    function testZip($el) {
        // convert to just numbers
        let value = $el.val().replace(/\D/g, ''),
            zip,
            valid = false;

        // is there a value
        if (value.length !== 0) {
            zip = value = $el.val().replace(/\D/g, '');
            // enough numbers entered
            if (value.length === 5 || value.length >= 9) {
                valid = true;
            }
            if (value.length > 5) {
                zip = value.slice(0, 5) +
                    "-" + value.slice(5, 9);
            }
        }
        $el.val(zip);
        toggleInvalid($el, valid)
        return valid;
    }
    function toggleInvalid($el, boolean) {
        if (boolean) {
            $el.removeClass('form-item--invalid');
        } else {
            $el.addClass('form-item--invalid');
        }
    }

    //** Form Submit without paypal **//
    $el.submit(function (e) {
        e.preventDefault(); // avoid to execute the actual submit of the form.
        let dataArray,
            thouShallNotPass = false,
            data,
            paypalField,
            recaptchaField,
            isPaypal,
            paypalEndpoint;

        // check if all fields on this page are filled out
        $el.find('.js-multipage-form-page').each(function (i) {
            if (!validatePage($(this), i)) {
                thouShallNotPass = true;
            }
        });

        if (thouShallNotPass) {
            return false;
        }

        //check for paypal field
        paypalField = $('.js-paypal-field');
        if (paypalField.length !== 0 && paypalField.val() === "") {
            isPaypal = true;
            paypalEndpoint = paypalField.data("endpoint");
        }

        // check if recaptcha is valid
        recaptchaField = $('.js-recaptcha-field');
        if (recaptchaField.length !== 0) {
            if (grecaptcha.getResponse() === "") {
                $('.g-recaptcha iframe').css({ "border": "1px solid #ee5f5b" });
                return false;
            } else {
                $('.g-recaptcha iframe').css({ "border-color": "transparent" });
            }
        }

        // disable the submit button--large
        $submit.prop("disabled", true);
        // hide existing messages
        $el.find('.js-multipage-form-fail-msg').stop(true, true).fadeOut();
        $('.js-multipage-form-success-msg').stop(true, true).fadeOut();
        // show loading gif
        $submit.find('.spinner').show();
        $submit.find('button').hide();

        // gather data
        dataArray = $(this).serializeArray();
        data = dataArray.reduce((obj, current) => {
            obj[current.name] = current.value;
            return obj;
        }, {});

        // submit data to server
        $.ajax({
            type: "POST",
            url: url,
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            success: function (data) {
                if (isPaypal) {
                    window.location.href = paypalEndpoint + "?SECURETOKENID=" +
                        data.token.secureTokenId +
                        "&SECURETOKEN=" +
                        data.token.secureToken +
                        "&USER1=" +
                        data.transactionId;
                    return;
                }

                /* ga */
                var eventName = $('h1.eventName').text();
                var eventDate = $('.detail__event-date').clone().children().remove().end().text().trim() + " " + $('.detail__event-date__day').text() + ", " + $('.detail__event-date__year').text();
                var passVal = eventName + "|" + eventDate;
                window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'eventForm', 'eventAction': 'registration', 'eventLabel': passVal });
                window.dataLayer.push({ 'u1': eventName, 'u2': eventDate });
                //console.log('track ga : ' +  'event:trackEvent', 'eventCategory:eventForm', 'eventAction:registration', 'eventLabel:'+ passVal);

                //ga('send', 'event', 'eventForm', 'registration', eventName + '|' + eventDate );

                /* end ga */
                var redirect = $('.js-multipage-form-success-msg').data("url");
                $('.js-multipage-form-success-msg').stop(true, true).fadeIn();
                window.location.href = redirect + '?u1=' + eventName + '&u2=' + eventDate;
                //console.log('redirecting...' + redirect + '?u1=' + eventName + '&u2=' + eventDate);
            },
            error: function (error) {
                //re-enable button
                $submit.prop("disabled", false);
                // hide loading gif
                $submit.find('.spinner').hide();
                $submit.find('button').show();
                // if it's a recaptcha error
                if (error.status == 401) {
                    $('.g-recaptcha iframe').css({ "border": "1px solid #ee5f5b" });
                    return;
                }
                $el.find('.js-multipage-form-fail-msg').stop(true, true).fadeIn();
            }
        });
    });
});

