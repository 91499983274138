'use strict';

import $ from 'jquery';
import {SearchComponentClass}
  from '../redux/constants/application';

// for each related specialties component
$('.js-related-most-viewed').each(function(){
	let $el = $(this);

  if(!!window.location.search.replace("?","").length) {
    $el.addClass("is-hidden").slideUp('slow');
  }

	$(SearchComponentClass).on('reset', function(e) {
		$el.slideDown('slow').removeClass("is-hidden");
	});
	$(SearchComponentClass).on('fetching', function(e) {
		$el.addClass("is-hidden").slideUp('slow');
	});
})
