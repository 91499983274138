'use strict';

import {combineReducers} from 'redux';
import dynamicListing from './dynamicListing';
import specialties from './specialties';
import conditionsAndTreatments from './conditionsAndTreatments';
import locations from './locations';
import newsArticle from './newsArticle';
import blogArticle from './blogArticle';
import events from './events';
import megaMenu from './megaMenu';
import mainSearch from './mainSearch';
import patientSpotlight from './patientSpotlight';
import doctors from './doctors';
import researcherListing from './researcherListing';
import clinicalTrialListing from './clinicalTrialListing';

const reducers = {
    dynamicListing,
    specialties,
    conditionsAndTreatments,
    locations,
    newsArticle,
    blogArticle,
    events,
    megaMenu,
    mainSearch,
    patientSpotlight,
    doctors,
    researcherListing,
    clinicalTrialListing
};

export default combineReducers(reducers);
