'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import URL from '../../../common/urlManipulator';

export default class NewsEventsDropdownItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    viewLabel: PropTypes.string.isRequired,
    sideClass: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool
    ]).isRequired,
    searchLink: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
    isMainDropdown: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired
  };

  constructor() {
    super();
    this.linkClicked = this.linkClicked.bind(this);
  }

  getFullMonth(date) {
    let fullMonth = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];

    return fullMonth[date.getMonth()];
  }

  getDate(eventDate, blogDate) {
    const {title} = this.props;

    let date = title === 'Events' ? eventDate : blogDate, month;
    date = new Date(date);
    month = this.getFullMonth(date);

    return `${month} ${date.getDate()}, ${date.getFullYear()}`;
  }

  linkClicked(e) {
    e.preventDefault();
    const {searchLink, contentType, isMainDropdown} = this.props;

      if (isMainDropdown) {
          $(".custom-searchIcon").trigger("click");
          if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              $(".site-header--buttons-small").find(".fa-search").trigger("click");
          }

      //let qs = URL.composeQueryString({'content-type': contentType});
      //  let url = URL.composeUrlWithQueryString(searchLink, qs);
        let qs = '&t=' + contentType;
        let url = searchLink + qs;
      return URL.goToCrossBrowser(url);
    }
    return URL.goToCrossBrowser(searchLink);
  }

  renderList(list) {
    return list.map((item, index) => {
      return (
        <li className='list__item list__item--simple' key={index}>
          <span>
            {this.getDate(item.startTime, item.newsOrBlogPublishedDate)}
          </span>
          <a className='link--news' href={item.readMoreLink}>{item.title}</a>
        </li>
      );
    });
  }

  render() {
    const {title, viewLabel, sideClass, searchLink, contentType, isMainDropdown,
      items} = this.props;

    return (
      <div className={sideClass ? ` l-2up--${sideClass}` : ''}>
        <div className='list list--sm'>
          <div className='list__header'>
            <h3 className='list__header__title'>{title}</h3>
            <a className='link'
               onClick={this.linkClicked}
               href>
              {viewLabel}
            </a>
          </div>
          <ul className='list__items'>
            {this.renderList(items)}
          </ul>
        </div>
      </div>
    );
  }
};
