'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { withRouter } from '../../../vendor/react-router.js';
import {
  fetchFilter,
  fetchList,
  setInitialFilter,
  setTaxonomyParent,
  setContentType,
  setSortOrder,
  setFilterDefault,
  setFilterType,
  loadFilterChange,
  loadPageChange,
	setShowOnlyMLHPP
} from '../../actions/dynamicListing';
import DynamicListMainSearch from '../shared/DynamicListMainSearch';
import SelectTwo from '../shared/SelectTwo';
import UrlState from "./urlState.js";

const { getUrlState, mergeUrlQuery } = UrlState;

@connect(state => ({ dynamicListing: state }))

@withRouter
export default class DynamicListing extends Component {
  static propTypes = {
    taxonomyParent: PropTypes.string.isRequired,
    contentType: PropTypes.array.isRequired,
    filterContentType: PropTypes.array.isRequired,
    sortOrder: PropTypes.string.isRequired,
    noResults: PropTypes.string.isRequired,
    labels: PropTypes.object,
    linkName: PropTypes.string.isRequired,
    paginationShowing: PropTypes.string.isRequired,
    paginationAll: PropTypes.string.isRequired,
    paginationOf: PropTypes.string.isRequired,
    paginationResults: PropTypes.string.isRequired,
    filterLabel: PropTypes.string.isRequired,
    filterDefaultValue: PropTypes.string.isRequired,
    showOnlyMLHPP: PropTypes.bool.isRequired,


  }

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const { dispatch } = this.props;

    dispatch(setContentType(this.props.contentType));
    dispatch(setTaxonomyParent(this.props.taxonomyParent));
    dispatch(setSortOrder(this.props.sortOrder));
    dispatch(setContentType(this.props.contentType));
    dispatch(setFilterDefault(this.props.filterDefaultValue));
	dispatch(setFilterType(this.props.filterContentType));
	dispatch(setShowOnlyMLHPP(this.props.showOnlyMLHPP));

    if (this.showFilter()) {
        this.getFilter();
        let { page, filterValue } = getUrlState();
        if (filterValue == "00000000-0000-0000-0000-000000000000" && this.props.initialFilterItem) {
            filterValue = this.props.initialFilterItem;
            this.props.router.push(mergeUrlQuery({
                filterValue, page
            }));
        }
    } else {
      dispatch(fetchList());
    }
  }

  showFilter = () => {
    return this.props.filterContentType && this.props.filterContentType.length > 0;
  }

  getFilter = () => {
    const { dispatch } = this.props;

    dispatch(fetchFilter());
  }

  normalizeFilterKeys = filter =>
    filter.map(item => ({
      name: item.label,
      value: item.value
    }));

    onFilterChange = filterValue => {
        const { dispatch } = this.props;
        const page = 1;
      this.props.router.push(mergeUrlQuery({
          filterValue, page
      }));
  }

  render() {
    const { dispatch, dynamicListing, taxonomyParent, contentType, sortOrder, filterContentType, ...other } = this.props;
	  const { page, filterValue } = getUrlState();
    const selected = dynamicListing.fetchingFilter ? dynamicListing.filterDefault : filterValue;

    return (
      <div>
        {this.showFilter() && // TODO
          <div className="dynamic-listing__filter">
            <SelectTwo options={this.normalizeFilterKeys(dynamicListing.filter)}
              optionSelected={selected}
              onOptionSelect={this.onFilterChange}
              labelName={this.props.filterLabel}
              busy={dynamicListing.fetchingFilter} />
          </div>}
        <DynamicListMainSearch
		  searchState={dynamicListing}
				currentPage={page}
          {...other} />
      </div>
    );
  }
};
