﻿'use strict';

import Actions from '../constants/actions';

const {
    REQUEST_RESEARCHER_LIST_SUCCESS,
    REQUEST_RESEARCHER_LIST_FETCHING,
    REQUEST_RESEARCHER_FILTER_SUCCESS,
    REQUEST_RESEARCHER_FILTER_FETCHING
} = Actions;

const initialState = {
    list: [],
    listTotal: -1, // set to -1 before request to track when it's done
    fetching: false,
    fetchingFilter: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case REQUEST_RESEARCHER_LIST_SUCCESS:
            return Object.assign({}, state, {
                list: action.list,
                listTotal: action.listTotal,
                fetching: false
            });
        case REQUEST_RESEARCHER_LIST_FETCHING:
            return Object.assign({}, state, {
                list: [],
                listTotal: -1,
                fetching: true
            });
        case REQUEST_RESEARCHER_FILTER_FETCHING:
            return Object.assign({}, state, {
                fetchingFilter: true
            });
        case REQUEST_RESEARCHER_FILTER_SUCCESS:
            return Object.assign({}, state, {
                filter: action.filter.filters.researcherTypes,
                fetchingFilter: false
            });
        default:
            return state;
    }
}
