import React, { Component } from 'react';
import PropTypes from "prop-types";

const { shape, string } = PropTypes;

const propTypes = {
    labels: shape({
        mainLineHealthPhysicianPartnerDoctorLabel: string.isRequired,
        mainLineHealthPhysicianPartnerDoctorInfo: string.isRequired,
        mainLineHealthPhysicianPartnerReadmoreText: string.isRequired
    }).isRequired
}


const Mlhpp = (props) => {
    const { labels = {} } = props;
    return (
        <figure className="mlhpp">
          {labels.mainLineHealthPhysicianPartnerDoctorLabel}
			<img src="/assets/images/png/tooltip.png" alt="Learn more about MLHPP"/>
          <figcaption>
				{labels.mainLineHealthPhysicianPartnerDoctorInfo} <a href="https://www.mainlinehealth.org/physicians/physician-partners">{labels.mainLineHealthPhysicianPartnerReadmoreText}</a>
          </figcaption>
        </figure>
    );
};


Mlhpp.propTypes = propTypes;

export default Mlhpp;
