'use strict';

import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Events from './Events';
import store from '../../store';
import {browserHistory, Router, Route} from '../../../vendor/react-router.js';

(() => {
  let eventSearchElem = document.getElementById('js-event-search');

  if (!eventSearchElem) return;

  let props = {};
  props['locationsDictionary'] =
    JSON.parse(eventSearchElem.getAttribute('data-locations-dictionary'));
  props['eventSearchTypes'] =
    JSON.parse(eventSearchElem.getAttribute('data-event-search-types'));
  props['keywordLabel'] = eventSearchElem.getAttribute('data-keyword-label');
  props['keywordPlaceholder'] =
    eventSearchElem.getAttribute('data-keyword-placeholder');
  props['fromDateLabel'] = eventSearchElem.getAttribute('data-from-date-label');
  props['toDateLabel'] = eventSearchElem.getAttribute('data-to-date-label');
  props['locationsLabel'] =
    eventSearchElem.getAttribute('data-locations-label');
  props['locationsDefault'] =
    eventSearchElem.getAttribute('data-locations-default');
  props['searchButton'] = eventSearchElem.getAttribute('data-search-button');
  props['noResults'] = eventSearchElem.getAttribute('data-no-results');
  props['toLabel'] = eventSearchElem.getAttribute('data-to-label');
  props['fromLabel'] = eventSearchElem.getAttribute('data-from-label');
  props['costLabel'] = eventSearchElem.getAttribute('data-cost-label');
  props['seatsLabel'] = eventSearchElem.getAttribute('data-seats-label');
  props['categoryLabel'] = eventSearchElem.getAttribute('data-category-label');
  props['paginationShowing'] = eventSearchElem.getAttribute('data-pagination-showing');
  props['paginationAll'] = eventSearchElem.getAttribute('data-pagination-all');
  props['paginationOf'] = eventSearchElem.getAttribute('data-pagination-of');
  props['paginationResults'] = eventSearchElem.getAttribute('data-pagination-results');

  const App = appProps => <Events { ... { ...appProps, ...props}} />;

  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path ="*" component={App}/>
      </Router>
    </Provider>,
    eventSearchElem
  );
})();
