﻿import $ 				from "jquery";

$(".js-add-to-google-cal").click(function() {
    var eventId = $(this).data("event-id");

    $.ajax({
            url: "/api/addToCalendar/addtogooglecalendar/" + eventId,
            method: "GET"
        })
        .done((redirectUrl) => {
            window.open(redirectUrl,"AddToGoogleCalendar","menubar=1,resizable=1,width=400,height=500");
        });
});
