﻿'use strict';

import $ from 'jquery';

/** vars **/

var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
var daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

var _displayDate = new Date();
var _displayIDs;
var _appointFormSteps = 5;
var _stepsArr = new Array(_appointFormSteps);

/* functions */

/* calendar functions */
function addDays(startDate, numberOfDays) {
    var startDateFormatted = new Date(startDate);
    var endDate = new Date(startDateFormatted.getTime() + (numberOfDays * 24 * 60 * 60 * 1000));
    return endDate
}

function formatTime(strTime) {
    var _time = strTime.split(":");
    var hrs = parseInt(_time[0]);
    var mins = parseInt(_time[1]);
    // forget about seconds for now

    var ampm = hrs >= 12 ? 'PM' : 'AM';
    hrs = hrs % 12;
    hrs = hrs ? hrs : 12; // the hour '0' should be '12'
    mins = mins < 10 ? '0' + mins : mins;

    return hrs + ':' + mins + ' ' + ampm;

}

function formatDate(inputDate) {
    var _yyyy = inputDate.getFullYear();
    var _mm;
    var _dd;

    if ((inputDate.getMonth() + 1).toString().length == 1) {
        _mm = "0" + (inputDate.getMonth() + 1).toString();
    } else {
        _mm = (inputDate.getMonth() + 1).toString();
    }

    if ((inputDate.getDate()).toString().length == 1) {
        _dd = "0" + inputDate.getDate().toString();
    } else {
        _dd = inputDate.getDate().toString();
    }

    return _yyyy + "-" + _mm + "-" + _dd;
}

function differenceInDays(date01, date02) {
    // Copy dates so don't affect originals
    date01 = new Date(+date01);
    date02 = new Date(+date02);

    // Set to noon
    date01.setHours(12, 0, 0, 0);
    date02.setHours(12, 0, 0, 0);

    // Get difference in whole days, divide by milliseconds in one day
    // and round to remove any daylight saving boundary effects
    return Math.round((date02 - date01) / 8.64e7)
}

/* data scrubber */
function cleanReturnedData(data) {
    data = data.replace(/\\/g, ''); //remove \ slash
    data = data.replace(/s:/g, '');
    data = data.replace(/o:/g, '');
    data = data.replace(/u:/g, '');
    data = data.replace(/a:/g, '');
    data = data.replace(/b:/g, '');

    return data;
}

/* **************************************************************************************************************** */

if ($('.js-openScheduleFormBuilder').length) {
    //run only if openScheduleForm is used
    // 1. Check if there is a slot available within next 7 days ( a week )
    // if available, show full form builder
    // else sho next available slot
    
    $('.js-openScheduleFormBuilder').each(function () {
        var _initParams = $(this).attr("data-id");
        $(this).attr("data-displayDate", new Date());
        checkIfAvailableInNext30Days(_initParams);
    });
   
}

function checkIfAvailableInNext7Days(params) {
    var _initParamArrays = params.split("-");
    var startDate = new Date();
    var endDate = addDays(startDate, 6);
    var parameters = { StartDate: formatDate(startDate), EndDate: formatDate(endDate), ProviderID: _initParamArrays[1], ProviderIDType: "EXTERNAL" };
   
    var result = getSchedule(parameters, params);

}

function checkIfAvailableInNext30Days(params) {
    var _initParamArrays = params.split("-");
    var startDate = new Date();
    var endDate = addDays(startDate, 29);
    var parameters = { StartDate: formatDate(startDate), EndDate: formatDate(endDate), ProviderID: _initParamArrays[1], ProviderIDType: "EXTERNAL" };

    var result = getSchedule(parameters, params);

}

function getSchedule(params, initIDs) {

    var initIDsArr = initIDs.split("-");
    $.ajax({
        type: "POST",
        url: "/api/forms/GetScheduleDaysForProvider",
        data: params,
        dataType: "text",
        async: true,
        timeout: 105000
    })
    .done(function (data) {
        if (!$.trim(data)) {
            console.log("empty data...");
        }
        else {
            data = $.trim(data);
            data = data.substring(1, data.length - 1); // remove first and last characters (");

            if (data.length != 0) {
                var cleanedData = cleanReturnedData(data);
                var xmlDoc = $.parseXML(cleanedData),
                    xml = $(xmlDoc),
                    days = xml.find("ScheduleDay");

                try {
                    var _timeSlots = 0;
                    var _daysFiltered = [];
                    $(days).each(function () {
                        if (($(this).find("Department").find("ID").text() == initIDsArr[0]) && ($(this).find("VisitType").find("ID").text() == initIDsArr[2])) {
                            //var _thisDate = $(this).find("Date").text();
                            _timeSlots = _timeSlots + $(this).find("Slot").length;
                            _daysFiltered.push($(this));
                        }
                    });

                    if (_timeSlots > 0) {
                        // there is at least one, so build calendar
                        console.log("build the calendar");
                        buildCalendar(initIDsArr, _daysFiltered);

                    } else {
                        // there is no appointment available, show next available time slot
                        console.log("show next available slot");

                        // hide the form for now.
                        $('.epic--openscheduler_newpatient_section[data-id="' + initIDs + '"]').hide();
                        $('.js-openScheduleNextAvailableForm[data-location-id="' + initIDsArr[0] + '"]').hide();
                        //buildCalendar(initIDsArr, days);
                        $('.xhrStatusWindow[data-id="' + initIDs + '"]').show();
                        $('.xhrStatusWindow[data-id="' + initIDs + '"]>.preloader').hide();
                        $('.noImmediateAvailable[data-id="' + initIDs + '"]').show();

                    }
                }
                catch (err) {

                    console.log("Error checking for an available slot in current week");
                    alert(err.message);
                }

            } else {
                // returned an empty string
                console.log("Error checking api result -- is empty");
                showSysError(initIDs);
            }
        }

        return true;

    })
    .fail(function (jqXHR, textStatus, errorThrown) {

        console.log('/****** start status *******/');
        console.log('/****** ERROR *******/');
        console.log(jqXHR);
        console.log(textStatus);
        console.log(errorThrown);
        console.log('/****** end status *******/');

        return false;

    });
}

function buildCalendar(_IDs, days) {

    var returnHTML = $("<div class='appointmentCalendar' />");
    var epicIDs = _IDs.join("-");
    _displayIDs = epicIDs;

    console.log(days);

    if ($(days).length > 0) {
        var counter = 0;
        $(days).each(function () {
            if (counter == 7) {
                return false;
            }
            if ($(this).find("Department").find("ID").text() == _IDs[0] && $(this).find("VisitType").find("ID").text() == _IDs[2]) {
                var _thisDate = $(this).find("Date").text();
                var _today = $(this).find("Date").text().split("-");
                var thisDay = new Date(_today[1] + "/" + _today[2] + "/" + _today[0]);
                var dayOfWeek = thisDay.getDay();
                var newRow = $("<div class='row'/>");
                newRow.append("<div class='dateColumn'><div class='dayOfWeek'>" + daysOfWeek[dayOfWeek] + "</div><div class='date'>" + monthNames[thisDay.getMonth()] + " " + (thisDay.getDate()) + "</div></div>");

                var newTimeColumn = $("<div class='timeColumn' />");

                var $timeSlots = $(this).find("Slot");

                if ($timeSlots.length > 0) {
                    var prevNav = $("<div class='prevSliderBtnArea'><button id='prevSliderBtn_" + epicIDs + "' type='button' class='btn button--white button-slim'><span class='fa fa-angle-left'></span></button></div>");
                    var newULList = $("<ul class='bxslider slots'>")
                    var nextNav = $("<div class='nextSliderBtnArea'><button id='nextSliderBtn_" + epicIDs + "' type='button' class='btn button--white button-slim'><span class='fa fa-angle-right'></span></button></div>");

                    $timeSlots.each(function (i) {
                        newULList.append("<li class='slot'><div class='switch'><input type='radio' id='" + epicIDs + _thisDate + "-" + $(this).find("Time").text() + "' name='row_" + epicIDs + "' class='cmn-toggle cmn-toggle-yes-no' data-id='" + epicIDs + "' value='" + _thisDate + "-" + $(this).find("Time").text() + "'></input><label for='" + epicIDs + _thisDate + "-" + $(this).find("Time").text() + "' data-on='" + formatTime($(this).find("Time").text()) + "' data-off='" + formatTime($(this).find("Time").text()) + "'></label></div></li>");
                    });
                    newTimeColumn.append(prevNav);
                    newTimeColumn.append(newULList);
                    newTimeColumn.append(nextNav);

                    newRow.append(newTimeColumn);

                } else {
                    newRow.append("<div class='timeColumn'><div class='prevSliderBtnArea'>&nbsp;</div><div class='noneAvailable'>No appointments available</div></div>");
                }

                returnHTML = returnHTML.append(newRow);
                }
            counter++;
        });

        $('.epic--openscheduler_newpatient_wrapper[data-id="' + _IDs[0] + "-" + _IDs[1] + "-1003"+ '"] div.preloader').hide();
        $('.epic--openscheduler_newpatient_wrapper[data-id="' + _IDs[0] + "-" + _IDs[1] + "-1003" + '"] div.calendarContent').html(returnHTML);
        $('.epic--openscheduler_newpatient_wrapper[data-id="' + _IDs[0] + "-" + _IDs[1] + "-1003" + '"] div.calendarContent').on('click', '.switch', function (evt) {
            var selectedValue = $(this).find('input[type=radio]').val();
            $('form[data-id="' + _IDs[0] + "-" + _IDs[1] + "-1003" + '"]').find('.appointmentDateAndTime').val(selectedValue);
            updateDateTimeConfirmation(_IDs);
        });

        var _thisSliderArr = new Array();

        $('.bxslider').each(function (i) {
            _thisSliderArr[i] = $(this).bxSlider({
                minSlides: 2,
                maxSlides: 6,
                slideWidth: 80,
                slideMargin: 10,
                hideControlOnEnd: true,
                nextSelector: '#prevSliderBtn_' + epicIDs,
                prevSelector: '#nextSliderBtn_' + epicIDs,
                adaptiveHeight: false,
                controls: false,
                infiniteLoop: false,
                pager: false,
                touchEnabled: false, // blocks the form entry in IE11 on touch device
                onSlideBefore: function ($slideElement, oldIndex, newIndex) {

                },
                onSlideAfter: function ($slideElement, oldIndex, newIndex) {
                    //ga('gtm1.set', 'page', '/appointments/request-physician-appointment?#page' + (oldIndex+1));
                    //ga('gtm1.send', 'pageview');

                }
            });
        });

        /* time slot navs */

        $('.prevSliderBtnArea > button').on('click', function (e) {
            var obj = $(this).parent().parent().find('.bxslider');
            var index = $('.bxslider').index(obj);
            _thisSliderArr[index].goToPrevSlide();
        });

        $('.nextSliderBtnArea > button').on('click', function (e) {
            var obj = $(this).parent().parent().find('.bxslider');
            var index = $('.bxslider').index(obj);
            _thisSliderArr[index].goToNextSlide();
        });

    } else {
        showSysError(epicIDs);
    }

    
}

function addSchedulingFormSectionCompletion(sectionID) {
    _stepsArr[sectionID] = true;
    var returnIndex = -1;

    for (var i = 0; i < _appointFormSteps; i++) {
        if (_stepsArr[i] != true) {
            returnIndex = i;
            break;
        }
    }

    return returnIndex
}

function checkIfFormComplete() {
    var returnIndex = -1;

    for (var i = 0; i < _appointFormSteps; i++) {
        if (_stepsArr[i] != true) {
            returnIndex = i;
            break;
        }
    }

    return returnIndex
}

function validateFormSection(sectionID) {
    var returnVal = true
    /* validate each section */

    return returnVal
}

function displaySection(sectionID, idBlock) {
    if (sectionID != "endForm") {
        closeAllSections(idBlock);
        var targetDiv = ".step-item-data.step-" + sectionID + ".panel";
        var thisDivHeight;

        $('.epic--openScheduler_form[data-id="' + idBlock + '"]').find(targetDiv).slideDown(1000, function () {
            $('.epic--openScheduler_form[data-id="' + idBlock + '"]').find(targetDiv).addClass('isActive');
            $('.epic--openScheduler_form[data-id="' + idBlock + '"]').find(targetDiv).attr("style", "display:block;");
            //thisDivHeight = $('.epic--openScheduler_form[data-id="' + idBlock + '"]').find(targetDiv).show().height();
            // $('html').scrollTop($('.epic--openScheduler_form[data-id="' + idBlock + '"]').find(targetDiv).offset().top - 150);
            $('html').animate({
                scrollTop: $('.epic--openScheduler_form[data-id="' + idBlock + '"]').find(targetDiv).offset().top - 175
            });
        });

        switch (sectionID - 1) {
            case 1:
                window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'OpenSchedule', 'eventAction': 'AppointmentForm', 'eventLabel': 'Complete timedate' });
                break;
            case 2:
                window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'OpenSchedule', 'eventAction': 'AppointmentForm', 'eventLabel': 'Complete insurance' });
                break;
            case 3:
                window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'OpenSchedule', 'eventAction': 'AppointmentForm', 'eventLabel': 'Complete patientinfo' });
                break;
            case 4:
                window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'OpenSchedule', 'eventAction': 'AppointmentForm', 'eventLabel': 'Complete contactinfo' });
                break;
        }
    }

}

function closeAllSections(idBlock) {
    $('.epic--openScheduler_form[data-id="' + idBlock + '"]').find('.step-item-data.panel.isActive').each(function () {
        $(this).slideUp(500, function () {
            $(this).removeClass('isActive');
            $(this).attr("style", "display:none;");
        });
    })
}

function updateConfirmationSection(blockID) {
    /* Insurance Information */
    if ($("#insuranceProvider_" + blockID).val() !== "") {
        $('form[data-id="' + blockID + '"').find('td[data-field="insurance-name"]').html($("#insuranceProvider_" + blockID).val());
    }

    if ($("#insuranceMemberID_" + blockID).val() !== "") {
        $('form[data-id="' + blockID + '"').find('td[data-field="insurance-member-id"]').html($("#insuranceMemberID_" + blockID).val());
    }

    if ($("#insuranceGroupID_" + blockID).val() !== "") {
        $('form[data-id="' + blockID + '"').find('td[data-field="insurance-group-number"]').html($("#insuranceGroupID_" + blockID).val());
    }

    /* Patient Information */
    if (($("#fName_" + blockID).val() !== "") && ($("#lName_" + blockID).val() !== "")) {
        $('form[data-id="' + blockID + '"').find('td[data-field="patient-name"]').html($("#lName_" + blockID).val() + ", " + $("#fName_" + blockID).val());
    }
    if ($("#DOB_" + blockID).val() !== "") {
        $('form[data-id="' + blockID + '"').find('td[data-field="patient-dob"]').html($("#DOB_" + blockID).val());
    }
    if ($("input[name=gender_" + blockID + "]:checked").val() !== "") {
        $('form[data-id="' + blockID + '"').find('td[data-field="patient-gender"]').html($("input[name=gender_" + blockID + "]:checked").val());
    }
    if ($("#reasonforVisit_" + blockID).val() !== "") {
        $('form[data-id="' + blockID + '"').find('td[data-field="patient-reasonForVisit"]').html($("#reasonforVisit_" + blockID).val());
    }

    /* Contact Information */
    if ($("#email_" + blockID).val() !== "") {
        $('form[data-id="' + blockID + '"').find('td[data-field="contact-email"]').html($("#email_" + blockID).val());
    }
    if ($("#phone_" + blockID).val() !== "") {
        $('form[data-id="' + blockID + '"').find('td[data-field="contact-phoneNumber"]').html($("#phone_" + blockID).val());
    }

    if ($("#phoneType_" + blockID).val() !== "") {
        $('form[data-id="' + blockID + '"').find('td[data-field="contact-phoneType"]').html($("#phoneType_" + blockID).val());
    }

    var tmpBlock = "";
    if ($("#address2_" + blockID).val() === "") {
        tmpBlock = $("#address1_" + blockID).val() + " <br/>" + $("#city_" + blockID).val() + ", " + $("#state_" + blockID).val() + "&nbsp;" + $("#zip_" + blockID).val();

    } else {
        tmpBlock = $("#address1_" + blockID).val() + " <br/>" + $("#address2_" + blockID).val() + "<br/>" + $("#city_" + blockID).val() + ", " + $("#state_" + blockID).val() + "&nbsp;" + $("#zip_" + blockID).val();

    }
    //console.log(tmpBlock);

    $('form[data-id="' + blockID + '"').find('td[data-field="contact-address"]').html(tmpBlock);

}

function resetForm($form) {
    $form.find('input:text, input:password, input:file, select, textarea').val('');
    $form.find('input:radio, input:checkbox')
        .removeAttr('checked').removeAttr('selected');
}

$('.js-cancelform').on('click', function (evt) {
    var _thisID = $(this).attr('data-id');
    resetForm($('form[data-id=' + _thisID + ']'));

    try {
        $('html').scrollTop(parseInt($('.epic--openscheduler_newpatient_wrapper[data-id="' + _thisID + '"]').offset().top) - 290);
    }
    catch (err) {
        console.log(err);
    }
    setTimeout(function () {
        console.log('WIAT 1/2 Sec.');
    }, 500);

    console.log(parseInt($('.epic--openscheduler_newpatient_wrapper[data-id="' + _thisID + '"]').offset().top));
    displaySection("1", _thisID);
});

/* js buttons for next and prev form steps */
$('.js-nextStepEpicForm').not('.isSubmitButton').on('click', function (e) {

    var thisStep = $(this).parent().parent().attr('class');
    var nextStep = $('div[class^="step-item-data"], div[class~="' + $(this).attr('data-target') + '"]').attr('class');
    var nextStepID = $(this).attr('data-target');
    var nextStepNumber = nextStepID[nextStepID.length - 1].toString();
    var nowStepNumber = parseInt(nextStepNumber) - 1;

    if (validateFormSection(nowStepNumber)) {
        if (nextStepNumber == 5) {
            updateConfirmationSection($(this).parent().parent().parent().attr('data-id'));
        }
        displaySection(nextStepNumber, $(this).parent().parent().parent().attr('data-id'));
    }

    switch (nowStepNumber) {
        case "1":
            window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'OpenSchedule', 'eventAction': 'AppointmentForm', 'eventLabel': 'Complete timedate' });
            break;
        case "2":
            window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'OpenSchedule', 'eventAction': 'AppointmentForm', 'eventLabel': 'Complete insurance' });
            break;
        case "3":
            window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'OpenSchedule', 'eventAction': 'AppointmentForm', 'eventLabel': 'Complete patientinfo' });
            break;
        case "4":
            window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'OpenSchedule', 'eventAction': 'AppointmentForm', 'eventLabel': 'Complete contactinfo' });
            break;
    }
});

/* prev and next date range button */
$('.js-prevDateRange').on('click', function (e) {
    //clicked on previous date range
    var _IDs = $(this).attr("data-id");
    var currentDisplayDate = $('.js-openScheduleFormBuilder[data-ID="' + _IDs +'"]').attr("data-displayDate");
    var startDate = addDays(currentDisplayDate, -6);

    if ($(this).attr("disabled") == "disabled") {
        //if disabled
        // do 
        //console.log("button is disabled");


    } else {
        // not disabled
        // console.log("button is enabled");

        updateAppointmentCalendar(_IDs[2], _IDs, startDate)
    }

});

$('.js-nextDateRange').on('click', function (e) {
    //clicked on next date range
    var _IDs = $(this).attr("data-id");
    var currentDisplayDate = $('.js-openScheduleFormBuilder[data-ID="' + _IDs + '"]').attr("data-displayDate");
    var startDate = addDays(currentDisplayDate, 6);

    if ($(this).attr("disabled") == "disabled") {
        //if disabled
        // do nothing
        //console.log("button is disabled");

    } else {
        // not disabled
        //console.log("button is enabled");
        updateAppointmentCalendar(_IDs[2], _IDs, startDate)
    }

});

/* jump steps */
$('.step-item.step-1 .step-text').on("click", function () {

    // if jump is alllowed

    displaySection("1", $(this).parent().parent().attr('data-id'));
});

$('.step-item.step-2 .step-text').on("click", function () {

    // if jump is alllowed
    //console.log($(this).parent().parent().attr('data-id'));
    displaySection("2", $(this).parent().parent().attr('data-id'));
});

$('.step-item.step-3 .step-text').on("click", function () {

    // if jump is alllowed

    displaySection("3", $(this).parent().parent().attr('data-id'));
});

$('.step-item.step-4 .step-text').on("click", function () {

    // if jump is alllowed

    displaySection("4", $(this).parent().parent().attr('data-id'));
});

$('.step-item.step-5 .step-text').on("click", function () {

    // if jump is alllowed
    updateConfirmationSection($(this).parent().parent().attr('data-id'));

    displaySection("5", $(this).parent().parent().attr('data-id'));
});

$('.isSubmitButton').on("click", function (evt) {
    console.log("/** Submit the form... **/");

    var blockID = $(this).attr("data-id");
    var IDsArr = blockID.split("-");

        var tempGender = $("input[name='gender_" + blockID + "']:checked").val().toString();
        if (tempGender.toUpperCase() == "UNSPECIFIED")
        {
            tempGender = "Unknown";
        }
        
    var parameters = {
        DepartmentID: IDsArr[0],
        DepartmentIDType: "EXTERNAL",
        NationalIdentifier: "",
        DateOfBirth: $("#DOB_" + blockID).val().toString().replace(/(..).(..).(....)/, "$3-$1-$2"),
            Gender: tempGender,
        MaritalStatus: "",
        Race: "",
        Religion: "",

        Academic: "",
        First: $("#fName_" + blockID).val().toString(),
        GivenNameInitials: "",
        LastName: $("#lName_" + blockID).val().toString(),
        LastNameFromSpouse: "",
        LastNamePrefix: "",
        Middle: "",
        PreferredName: "",
        PreferredNameType: "EXTERNAL",
        SpouseLastNameFirst: "",
        SpouseLastNamePrefix: "",
        Suffix: "",
        Title: "",

        City: $("#city_" + blockID).val().toString(),
        Country: "USA",
        County: "",
        Email: $("#email_" + blockID).val().toString(),
        HouseNumber: "",
        PhoneNumber: $("#phone_" + blockID).val().toString(),
        PhoneType: $("#phoneType_" + blockID).val().toString(),
        State: $("#state_" + blockID).val().toString(),
        Street: $("#address1_" + blockID).val().toString(),
        StreetLine2: $("#address2_" + blockID).val().toString(),
        ZipCode: $("#zip_" + blockID).val().toString(),

        VisitTypeID: "1003",
        VisitTypeIDType: "EXTERNAL",
        Date: $('form[data-id="' + blockID + '"]').find('.appointmentDate').val(),
        Comments: $("#reasonforVisit_" + blockID).val().toString(),
        IsReviewOnly: "0",
        ProviderID: IDsArr[1],
        ProviderIDType: "EXTERNAL",
        Time: $('form[data-id="' + blockID + '"]').find('.appointmentTime').val()

    }

    //console.log(parameters);

    /* prep the ui */
    var $targetPanel = $('.epic--openscheduler_newpatient_section[data-id="' + blockID+ '"]');
    $targetPanel.hide();
    $targetPanel.next().show();

    //var el = $('form[data-id="' + IDsArr[0] +  "-" + IDsArr[1] + '"').find('.epic--openScheduler_form').offset();
    //$('html, body').animate({
    //    scrollTop: el.top
    //});

    $('html').animate({
        scrollTop: $('.js-openScheduleFormBuilder[data-id="' + blockID + '"]').offset().top - 175
    });


    /*xhr action */

    var appointmentRequest = $.ajax({
        type: "Post",
        url: "/api/forms/SubmitPatientAppointment",
        data: parameters,
        dataType: "text"

    });

    appointmentRequest.success(function (data) {
        console.log('successful but may not be error free: check');
        data = data.replace(/\\/g, ''); //remove \ slash
        data = data.replace(/s:/g, '');
        data = data.replace(/o:/g, '');
        data = data.replace(/u:/g, '');
        data = data.replace(/a:/g, '');
        data = data.replace(/b:/g, '');
        data = data.replace(/c:/g, '');
        data = data.replace(/i:/g, '');
        data = data.substring(1, data.length - 1); // remove first and last characters (");
        console.log(data);

        var resultXML = $.parseXML(data);

        console.log(resultXML);

        var errors = new Array();
        $(resultXML).find("faultstring").each(function () {
            // Still errors
            console.log("/****************************** Still Errors *********************************/");
            console.log("Error Count: " + $(resultXML).find("faultstring").length);
            console.log($(this).text());
            errors.push($(this).text());
        });

        if (errors.length > 0) {

            var $resultPanel = $('.xhrStatusWindow[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003" + '"]');

            $resultPanel.find('.preloader').hide();
            if (errors.length == 1) {
                $resultPanel.find('.xhrStatus--errorMsg').html(errors[0]);
            } else {
                $resultPanel.find('.xhrStatus--errorMsg').html(errors.join(', '));
            }

            $resultPanel.find('.xhrStatus--failed').show();

            $resultPanel.find('.return2RequestForm').on('click', function (evt) {
                $resultPanel.find('.xhrStatus--failed').hide();
                $resultPanel.find('.xhrStatusWindow[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003" + '"]').hide();
                $resultPanel.find('.xhrStatusWindow[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003" + '"] > .preloader').show();
                $('.epic--openscheduler_newpatient_section[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003"+ '"]').show();
            });

            console.log('** parameters **');
            console.log(parameters);



        } else {
            // No error - Successful

            window.dataLayer.push({ 'event': 'trackEvent', 'eventCategory': 'OpenSchedule', 'eventAction': 'AppointmentForm', 'eventLabel': 'Complete appointment' });

            var $resultPanel = $('.xhrStatusWindow.panel[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003" + '"]');

            // populate confirmation page
            var newDateAndTime = $('form[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003" + '"]').find('.appointmentDateAndTime').val();
            //result: 2017-07-17-15:00:00
            var dateTimeArr = newDateAndTime.split("-");

            $resultPanel.find('.xhrStatus--passed .verify-appointment-date').html(monthNames[parseInt(dateTimeArr[1]) - 1] + " " + dateTimeArr[2] + ", " + dateTimeArr[0]);
            $resultPanel.find('.xhrStatus--passed .verify-appointment-time').html(formatTime(dateTimeArr[3]));

            $resultPanel.find('.preloader').hide();
            $('.xhrStatus--passed').show();

            $('.xhrStatus--passed .closeRequestForm').on('click', function (evt) {

                //$('.xhrStatus--passed').hide();
                //$('.xhrStatusWindow[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003" + '"]').hide();
                //$('.xhrStatusWindow[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003" + '"] > .preloader').show();

                //resetForm($('form[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003" + '"]'));
                //displaySection("1", IDsArr[0] + "-" + IDsArr[1]);
                //$resultPanel.hide();
                //$('.epic--openscheduler_newpatient_section[data-id="' + IDsArr[0] + "-" + IDsArr[1] + "-1003"+ '"]').show();

                location.reload();

            });
        }

    });

    appointmentRequest.fail(function (xhr, status, errorMSg) {
        console.log('failed');
        console.log(errorMSg);
        console.log(status);
        console.log(xhr);
        console.log('** parameters **');
        console.log(parameters);
        var $resultPanel = $('.epic--openscheduler_newpatient_wrapper[data-id="' + blockID + '"');

        $resultPanel.find('.xhrStatusWindow[data-id="' + blockID + '"] > .preloader').hide();
        $resultPanel.find('.xhrStatus--failed[data-id="' + blockID + '"]').show();

        $resultPanel.find('.return2RequestForm').on('click', function (evt) {
            $resultPanel.find('.xhrStatus--failed[data-id="' + blockID + '"]').hide();
            $resultPanel.find('.xhrStatusWindow[data-id="' + blockID + '"]').hide();
            $resultPanel.find('.xhrStatusWindow[data-id="' + blockID + '"] > .preloader').show();
            $resultPanel.find('form[data-id="' + blockID + '"]').show();
        });
    });
});

$('.xhrStatusWindow .api--failed input.closeMessageBtn').on("click", function (evt) {
    // close
    var thisIDs = $(this).parent().parent().attr('data-id');

    $(this).parent().hide();
    $(this).parent().parent().hide();
});

/* this function updates the Confirmation section */
function updateDateTimeConfirmation(_IDs) {
    var newDateAndTime = $('form[data-id="' + _IDs[0] + "-" + _IDs[1] + "-1003" + '"]').find('.appointmentDateAndTime').val();

    //result: 2017-07-17-15:00:00
    var dateTimeArr = newDateAndTime.split("-");

    $('form[data-id="' + _IDs[0] + "-" + _IDs[1] + "-1003" + '"]').find('td[data-field="appointment-date"]').html(monthNames[parseInt(dateTimeArr[1]) - 1] + " " + dateTimeArr[2] + ", " + dateTimeArr[0]);
    $('form[data-id="' + _IDs[0] + "-" + _IDs[1] + "-1003" + '"]').find('td[data-field="appointment-time"]').html(formatTime(dateTimeArr[3]));

    $('form[data-id="' + _IDs[0] + "-" + _IDs[1] + "-1003" + '"]').find('.appointmentDate').val(dateTimeArr[0] + '-' + dateTimeArr[1] + '-' + dateTimeArr[2]);
    $('form[data-id="' + _IDs[0] + "-" + _IDs[1] + "-1003" + '"]').find('.appointmentTime').val(dateTimeArr[3]);
}

function updateAppointmentCalendar(visitType, epicIDs, startDate) {

    /* save these to use later */
    var startDateFormatted = new Date(startDate);
    _displayIDs = epicIDs;

    $('.js-openScheduleFormBuilder[data-ID="' + epicIDs + '"]').attr("data-displayDate", startDateFormatted);
    
    var _today = new Date();

    if (differenceInDays(startDateFormatted, _today) < 0) {
        $('.js-prevDateRange[data-ID="' + epicIDs + '"]').removeAttr("disabled");
    } else {
        $('.js-prevDateRange[data-ID="' + epicIDs + '"]').attr("disabled", "disabled");
    }

    var endDate = addDays(startDateFormatted, 6);
    var displayDateRange = monthNames[startDateFormatted.getMonth()] + "&nbsp;" + startDateFormatted.getDate() + " - " + monthNames[endDate.getMonth()] + "&nbsp;" + endDate.getDate();
    var _IDs = epicIDs.split("-");

    $('.epic--openscheduler_newpatient_wrapper[data-id="' + epicIDs + '"] div.dateRange').html(displayDateRange);

    var parameters = { StartDate: formatDate(startDate), EndDate: formatDate(endDate), ProviderID: _IDs[1], ProviderIDType: "EXTERNAL" };

    $.ajax({
        type: "POST",
        url: "/api/forms/GetScheduleDaysForProvider",
        data: parameters,
        dataType: "text",
        async: true,
        timeout: 105000
    })
        .done(function (data, textStatus, jqXHR) {
            if (!$.trim(data)) {
                console.log("empty data...");
                buildInvalidCalendar(epicIDs);
            }
            else {
                data = $.trim(data);
                data = data.substring(1, data.length - 1); // remove first and last characters (");
                
                if (data.length != 0) {
                    var cleanedData = cleanReturnedData(data);
                    var xmlDoc = $.parseXML(cleanedData),
                        xml = $(xmlDoc),
                        days = xml.find("ScheduleDay");

                    try {
                        var _daysFiltered = [];
                        $(days).each(function () {
                            if (($(this).find("Department").find("ID").text() == _IDs[0]) && ($(this).find("VisitType").find("ID").text() == _IDs[2])) {
                                //var _thisDate = $(this).find("Date").text();
                               _daysFiltered.push($(this));
                            }
                        });

                        buildCalendar(_IDs, _daysFiltered);

                    }
                    catch (err) {

                        console.log("Error checking for an available slot in current week");
                        alert(err.message);
                    }


                } else {
                    // returned an empty string
                    showSysError(epicIDs);
                }


            }
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
            console.log('/****** start status *******/');
            console.log('/****** ERROR *******/');
            console.log(jqXHR);
            console.log(textStatus);
            console.log(errorThrown);
            console.log('/****** end status *******/');

            showSysError(epicIDs);
        })
        .always(function (jqXHR, textStatus, errorThrown) {
            //console.log('/****** start status *******/');
            //console.log(jqXHR);
            //console.log(textStatus);
            //console.log(errorThrown);
            //console.log('/****** end status *******/');
        });

}

/* errors */
function showSysError(epicIDs) {
    var _IDs = epicIDs.split("-"); //EpicDepartmentID-GetProviderID-VisitTypeID;
    $('form[data-id="' + epicIDs + '"]').hide();
    $('.xhrStatusWindow[data-id="' + epicIDs + '"]').show();
    $('.xhrStatusWindow[data-id="' + epicIDs + '"] .preloader').hide();
    $('.xhrStatusWindow[data-id="' + epicIDs + '"] .api--failed').show();
}

function buildInvalidCalendar(epicIDs) {
    showSysError(epicIDs);
}