'use strict';

import Actions from '../constants/actions';

import Application from '../constants/application';

const {
  REQUEST_NEWS_ARTICLE_SUCCESS, 
  NEWS_ARTICLE_KEYWORD_CHANGED,
  REQUEST_NEWS_ARTICLE_FETCHING, 
  REQUEST_NEWS_ARTICLE_LOAD_FETCHING,
  NEWS_ARTICLE_LOCATION_SELECTED, 
  NEWS_ARTICLE_LOCATION_DESELECTED,
  NEWS_ARTICLE_CATEGORY_CHANGED, 
  NEWS_ARTICLE_CATEGORY_DESELECTED,
  NEWS_ARTICLE_INCREMENT_LOAD_MORE, 
  REQUEST_NEWS_ARTICLE_LOAD_MORE_SUCCESS,
  NEWS_ARTICLE_LOAD_MORE_RESET, 
  NEWS_ARTICLE_SET_LOAD_MORE,
  NEWS_ARTICLE_PAGINATION_CHANGED 
} = Actions;

const { DefaultPagination } = Application;

const initialState = {
  list: [],
  listTotal: -1, // set to -1 before request to track when it's done
  fetching: false,
  loadMoreFetching: false,
  loadMoreCounter: 1, // starts with 1 similar to first page of pagination
  pagination: {
    currentEnd: DefaultPagination,
    currentPage: 1,
    currentStart: 1,
  },
  filters: {
    keyword: null,
    location: null,
    category: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_NEWS_ARTICLE_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        listTotal: action.listTotal,
        fetching: false
      });
    case REQUEST_NEWS_ARTICLE_LOAD_MORE_SUCCESS:
      return Object.assign({}, state, {
        list: state.list.concat(action.list),
        listTotal: action.listTotal,
        loadMoreFetching: false
      });
    case REQUEST_NEWS_ARTICLE_FETCHING:
      return Object.assign({}, state, {
        list: [],
        listTotal: -1,
        fetching: true
      });
    case REQUEST_NEWS_ARTICLE_LOAD_FETCHING:
      return Object.assign({}, state, {
        loadMoreFetching: true
      });
    case NEWS_ARTICLE_KEYWORD_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          keyword: action.keyword
        })
      });
    case NEWS_ARTICLE_LOCATION_SELECTED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          location: action.locationKey
        })
      });
    case NEWS_ARTICLE_LOCATION_DESELECTED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          location: null
        })
      });
    case NEWS_ARTICLE_CATEGORY_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          category: action.category,
        })
      });
    case NEWS_ARTICLE_CATEGORY_DESELECTED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          category: null,
        })
      });
    case NEWS_ARTICLE_INCREMENT_LOAD_MORE:
      return Object.assign({}, state, {
        loadMoreCounter: state.loadMoreCounter + 1
      });
    case NEWS_ARTICLE_LOAD_MORE_RESET:
      return Object.assign({}, state, {
        loadMoreCounter: 1
      });
    case NEWS_ARTICLE_SET_LOAD_MORE:
      return Object.assign({}, state, {
        loadMoreCounter: action.counter
      });
    case NEWS_ARTICLE_PAGINATION_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: parseInt(action.page, 10),
          currentStart: parseInt(action.start, 10),
          currentEnd: parseInt(action.end, 10)
        }
      });
    default:
      return state;
  }
};
