if (document.getElementsByClassName("list__item--event") != null) {
	if (document.getElementById("coveod42951cc") != null) {
		Coveo.$$(document.getElementById("coveod42951cc")).on('preprocessResults', function (e, args) {
			args.results.results.forEach(function (result) {
				var startTime = result.raw.starttime;
				var endTime = result.raw.endtime;
				result.raw.starttime = startTime.substring(0, startTime.length - 1);
				result.raw.endtime = endTime.substring(0, endTime.length - 1);
			})
		});

	}
}

document.addEventListener("DOMContentLoaded", function () {
	var n = Coveo.$$(document).find(".CoveoSearchInterface");
	if (n) {
		setTimeout(function () {
		Coveo.init(n);
		}, 10);
	}
});
