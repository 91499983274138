'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import MasonryComponent from '../shared/React-Masonry';
import InfiniteScroll from '../shared/ReactInfiniteScroll';
import { connect } from 'react-redux';
import {
    conditionChanged, treatmentChanged, updateLoadMore, fetchPatientSpotlightList,
    fetchPatientSpotlightFilter
} from '../../actions/patientSpotlight';
import Application from '../../constants/application';
import URL from '../../../common/urlManipulator';
import SelectTwo from '../shared/SelectTwo';
import PatientSpotlightItem from './PatientSpotlightItem';

const { DefaultPagination } = Application;

// var Masonry = require('react-masonry-component')(React);

@connect(state => ({
    patientSpotlight: state.patientSpotlight
}))

export default class PatientSpotlight extends Component {
    static propTypes = {
        conditionsLabel: PropTypes.string.isRequired,
        treatmentsLabel: PropTypes.string.isRequired,
        noResults: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);
        this.onConditionChanged = this.onConditionChanged.bind(this);
        this.onTreatmentChanged = this.onTreatmentChanged.bind(this);
        /*this.onFiltersChanged = this.onFiltersChanged.bind(this);*/
        this.renderResults = this.renderResults.bind(this);
        this.onLoadMore = this.onLoadMore.bind(this);
        this.onMasonryInit = this.onMasonryInit.bind(this);

        this.scrollListener = false;
    }

    componentWillMount() {
        const { dispatch } = this.props;
        let params = this.getUrlParams(),
            patientSpotlight = this.props.patientSpotlight;

        // fetch filter values
        dispatch(fetchPatientSpotlightFilter(() => {
            // if the condition in the URL isn't in the list of filters
            let conditions = this.props.patientSpotlight.conditions,
                paramVal1 = params.condition,
                treatments = this.props.patientSpotlight.treatments,
                paramVal2 = params.treatment;

            if (!paramVal1 || !paramVal2) {
                return;
            }


            if (!conditions.some((condition) => condition.value === paramVal1)) {
                dispatch(filtersChanged(this.props.patientSpotlight.defaultCondition, this.props.patientSpotlight.filters.treatment));
            }
            if (!treatments.some((treatment) => treatment.value === paramVal2)) {
                dispatch(filtersChanged(this.props.patientSpotlight.filters.condition, this.props.patientSpotlight.defaultTreatment));
            }


        }));

        // if a condition is passed
        if (params.condition) {
            // update current condtion and fetch results
            this.onConditionChanged(params.condition);
        } else if (params.treatment) {
            // update current condtion and fetch results
            this.onTreatmentChanged(params.treatment);
        }else {
            // fetch results
            dispatch(fetchPatientSpotlightList(1, DefaultPagination));
        }
        //this.onFiltersChanged(params.condition, params.treatment);
        dispatch(fetchPatientSpotlightList(1, DefaultPagination));
    }

    getUrlParams() {
        let url = URL.getQueryString(), condition, treatment;
        if (url === null) return {};

        return {
            condition: URL.getParameterByName('condition', url),
            treatment: URL.getParameterByName('treatment', url)
        };
    }

    /*
    onFiltersChanged(condition, treatment) {
        const { patientSpotlight, dispatch } = this.props;

        if ((condition === patientSpotlight.filters.condition && treatment === patientSpotlight.filters.treatment) || (condition === undefined && treatment === undefined)){
            return;
        }

        if (condition === undefined) { condition = patientSpotlight.defaultCondition }
        if (treatment === undefined) { treatment = patientSpotlight.defaultTreatment }

        let qs, params = {};
        params = { condition: condition, treatment: treatment };

        qs = URL.composeQueryString(params);
        URL.addNewQueryString(qs);

        this.scrollListener = false;

        dispatch(filtersChanged(condition, treatment));
        dispatch(fetchPatientSpotlightList(1, DefaultPagination, condition, treatment));
    }
    */

    onConditionChanged(condition) {
        const { patientSpotlight, dispatch } = this.props;

        if (condition === patientSpotlight.filters.condition || condition === undefined) {
            return;
        }

        patientSpotlight.filters.condition = condition;

        let qs,
            params = {};
        /*
        if (patientSpotlight.filters.treatment === undefined || patientSpotlight.filters.treatment === null) {
            patientSpotlight.filters.treatment = patientSpotlight.defaultTreatment;
        }
        */
        params = { condition: condition, treatment: patientSpotlight.filters.treatment };

        qs = URL.composeQueryString(params);
        URL.addNewQueryString(qs);

        this.scrollListener = false;

        dispatch(conditionChanged(condition, patientSpotlight.filters.treatment));
        dispatch(fetchPatientSpotlightList(1, DefaultPagination, condition, patientSpotlight.filters.treatment));
    }

    onTreatmentChanged(treatment) {
        const { patientSpotlight, dispatch } = this.props;

        if (treatment === patientSpotlight.filters.treatment || treatment === undefined) {
            return;
        }

        patientSpotlight.filters.treatment = treatment;

        let qs,
            params = {};
        /*
        if (patientSpotlight.filters.condition === undefined || patientSpotlight.filters.condition === null) {
            patientSpotlight.filters.condition = patientSpotlight.defaultCondition;
        }
        */
        params = { condition: patientSpotlight.filters.condition, treatment: treatment };

        qs = URL.composeQueryString(params);
        URL.addNewQueryString(qs);

        this.scrollListener = false;

        dispatch(treatmentChanged(patientSpotlight.filters.condition, treatment));
        dispatch(fetchPatientSpotlightList(1, DefaultPagination, patientSpotlight.filters.condition, treatment));
    }

    onLoadMore() {
        const { patientSpotlight, dispatch } = this.props;
        if (!patientSpotlight.loadMore || !this.scrollListener) {
            return false;
        }

        let start = patientSpotlight.pagination.currentEnd + 1,
            end = patientSpotlight.pagination.currentEnd + DefaultPagination;

        if (end > patientSpotlight.listTotal) {
            dispatch(updateLoadMore(false));
            end = patientSpotlight.listTotal;
            this.scrollListener = false;
        }
        dispatch(fetchPatientSpotlightList(start, end, patientSpotlight.filters.condition, patientSpotlight.filters.treatment));

        return true;
    }

    onMasonryInit() {
        // allow infinite scroll to call more results after masonry is complete
        this.scrollListener = true;
    }

    renderResults() {
        const { patientSpotlight } = this.props;

        if (patientSpotlight.list.length > 0) {
            return (
                <InfiniteScroll
                    className="container"
                    pageStart={patientSpotlight.pagination.currentStart}
                    loadMore={this.onLoadMore}
                    hasMore={patientSpotlight.loadMore}
                    threshold={150}
                    loader={<div className="loader">Loading ...</div>}>
                    <MasonryComponent
                        className="spotlight-results"
                        onInit={this.onMasonryInit}>
                        {patientSpotlight.list.map((item, index) =>
                            <PatientSpotlightItem
                                item={item}
                                key={index} />
                        )}
                    </MasonryComponent>
                </InfiniteScroll>
            );
        } else {
            return (<div className="container"><div className="spotlight-container--empty">{this.props.noResults}</div></div>);
        }
    }

    render() {
        const { patientSpotlight, conditionsLabel, treatmentsLabel } = this.props;

        // console.log('render',patientSpotlight);

        return <div>
            <div className="l-block patient-spotlight__controls">
                <div className="container">
                    <div className="form-center">
                        <div className="form-inline--last">
                            <div className="form-left spotlight-conditions-filter">
                                <div className="form-item patient-spotlight__select-block">
                                    <SelectTwo options={patientSpotlight.conditions || []}
                                        optionSelected={patientSpotlight.filters.condition || patientSpotlight.defaultCondition}
                                        onOptionSelect={this.onConditionChanged}
                                        labelName={conditionsLabel} />
                                </div>
                            </div>
                            <div className="form-right spotlight-treatments-filter">
                                <div className="form-item patient-spotlight__select-block">
                                    <SelectTwo options={patientSpotlight.treatments || []}
                                        optionSelected={patientSpotlight.filters.treatment || patientSpotlight.defaultTreatment}
                                        onOptionSelect={this.onTreatmentChanged}
                                        labelName={treatmentsLabel} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="l-block l-block--gray l-block--border-top">
                {this.renderResults()}
            </div>
        </div>;
    }
};
