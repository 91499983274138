﻿'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { compose, applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import ClinicalTrialListing from './ClinicalTrialListing';
import reducer from '../../reducers/index';
import store from '../../store';
import { browserHistory, Route, Router, IndexRoute } from '../../../vendor/react-router.js';
import { fetchList } from '../../actions/clinicalTrialListing.js'
import UrlState from './urlState.js';

const { getUrlState, createBrowserHistoryChangeListener } = UrlState;

(() => {
  let dynamicElem = document.getElementById('js-clinicaltrial-listing');

  if (!dynamicElem) {
    return;
  }

  let props = {} ;
  props['taxonomyParent'] = dynamicElem.getAttribute('data-taxonomy-parent');
  props['contentType'] = JSON.parse(dynamicElem.getAttribute('data-content-types'));
  props['sortOrder'] = dynamicElem.getAttribute('data-sort-order');
  props['noResults'] = dynamicElem.getAttribute('data-no-results');
  props['linkName'] = dynamicElem.getAttribute('data-link-name');
  props['paginationShowing'] = dynamicElem.getAttribute('data-pagination-showing');
  props['paginationAll'] = dynamicElem.getAttribute('data-pagination-all');
  props['paginationOf'] = dynamicElem.getAttribute('data-pagination-of');
  props['paginationResults'] = dynamicElem.getAttribute('data-pagination-results');


  const App = routerProps => {
    return <ClinicalTrialListing {...props} location={routerProps.location} />
  }

  browserHistory.listen(createBrowserHistoryChangeListener(
    () => store.dispatch(fetchList())
  ));

  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="*" component={App} />
      </Router>
    </Provider>,
    dynamicElem
  );
})();
