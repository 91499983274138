'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';
import UrlState from '../components/doctors/urlState.js';
import { calculatePagination } from '../createUrlState.js';

const { 
  getUrlState
} = UrlState;

const {
  REQUEST_DOCTORS_SUCCESS, 
  REQUEST_DOCTORS_FILTERS_AND_LABELS_SUCCESS,
  REQUEST_DOCTORS_FETCHING, 
  DOCTORS_KEYWORD_CHANGED, 
  DOCTORS_KEYWORD_INITIATED,
  DOCTORS_LETTER_CHANGED, 
  DOCTORS_ZIP_CODE_CHANGED, 
  DOCTORS_PAGINATION_RESET, 
  DOCTORS_SPECIALTY_CHANGED,
  DOCTORS_DISTANCE_CHANGED, 
  DOCTORS_LOCATION_CHANGED, 
  DOCTORS_LANGUAGES_CHANGED, 
  DOCTORS_MERGE_QUERY_PARAMS,
  DOCTORS_GENDER_CHANGED, 
  DOCTORS_MLH_CHECKBOX_CHANGED, 
  DOCTORS_MLHPP_CHECKBOX_CHANGED,
  DOCTORS_ACCEPTING_CHECKBOX_CHANGED, 
  DOCTORS_SCHEDULING_CHECKBOX_CHANGED,
  DOCTORS_PAGINATION_CHANGED 
} = Actions;
  
export function mergeQueryParams(params) {
  return {
    type: DOCTORS_MERGE_QUERY_PARAMS,
    params: params
  };
}

function filtersAndLabelsGetSuccess(data) {
  return {
    type: REQUEST_DOCTORS_FILTERS_AND_LABELS_SUCCESS,
    filterValues: data.filters,
    labels: data.labels,
    specialties: data.filters.specialties
  };
}

function doctorsGetSuccess(data) {
  return {
    type: REQUEST_DOCTORS_SUCCESS,
    list: data.results,
    listTotal: data.totalResults
  };
}

function doctorsFetching() {
  return {
    type: REQUEST_DOCTORS_FETCHING
  };
}

export function keywordChanged(keyword) {
  return {
    type: DOCTORS_KEYWORD_CHANGED,
    keyword: keyword
  };
};

export function keywordSearchInitiated() {
  return {
    type: DOCTORS_KEYWORD_INITIATED
  };
};

export function letterChanged(letter) {
  return {
    type: DOCTORS_LETTER_CHANGED,
    letter: letter
  };
};

export function zipCodeChanged(zipCode, zipValidity) {
  return {
    type: DOCTORS_ZIP_CODE_CHANGED,
    zipCode: zipCode,
    zipValidity: zipValidity
  };
};

export function paginationReset() {
  return {
    type: DOCTORS_PAGINATION_RESET
  };
};

export function distanceChanged(distance) {
  return {
    type: DOCTORS_DISTANCE_CHANGED,
    distance: distance
  };
};

export function locationChanged(location) {
  return {
    type: DOCTORS_LOCATION_CHANGED,
    location: location
  };
};

export function specialtyChanged(specialty) {
  return {
    type: DOCTORS_SPECIALTY_CHANGED,
    specialty: specialty
  }
}

export function languagesChanged(languages) {
  return {
    type: DOCTORS_LANGUAGES_CHANGED,
    languages: languages
  };
};

export function genderChanged(gender) {
  return {
    type: DOCTORS_GENDER_CHANGED,
    gender: gender
  };
};

export function mlhCheckboxChanged(checked) {
  return {
    type: DOCTORS_MLH_CHECKBOX_CHANGED,
    mlhCheckbox: checked
  };
};

export function mlhppCheckboxChanged(checked) {
  return {
    type: DOCTORS_MLHPP_CHECKBOX_CHANGED,
    mlhppCheckbox: checked
  };
};

export function acceptingCheckboxChanged(checked) {
  return {
    type: DOCTORS_ACCEPTING_CHECKBOX_CHANGED,
    acceptingCheckbox: checked
  };
};

export function schedulingCheckboxChanged(checked) {
    return {
        type: DOCTORS_SCHEDULING_CHECKBOX_CHANGED,
        schedulingCheckbox: checked
    };
};

export function paginationChanged(page, start, end) {
  return {
    type: DOCTORS_PAGINATION_CHANGED,
    page: page,
    start: start,
    end: end
  };
};

export function fetchFiltersAndLabels(Callback = null) {
  return dispatch => {
    $.ajax({
      url: '/api/search/doctors/getfilters',
      cache: false
    }).done((response) => {
      if (response.filters.locations) {
        response.filters.locations = response.filters.locations.map((item) => {
          item['name'] = item.label;
          delete item.label;
          if (item.value === '00000000-0000-0000-0000-000000000000') {
            item.value = '';
          }
          return item;
        });
      }

      if (response.filters.languages) {
        response.filters.languages = response.filters.languages.map((item) => {
          item['name'] = item.label;
          delete item.label;
          if (item.value === '00000000-0000-0000-0000-000000000000') {
            item.value = '';
          }
          return item;
        });
      }

      if (response.filters.genders) {
        response.filters.genders = response.filters.genders.map((item) => {
          item['name'] = item.label;
          delete item.label;
          if (item.value === '00000000-0000-0000-0000-000000000000') {
            item.value = '';
          }
          return item;
        });
      }

      if (response.filters.specialties) {
        response.filters.specialties = response.filters.specialties.map((item) => {
          item['name'] = item.label;
          delete item.label;
          if (item.value === '00000000-0000-0000-0000-000000000000') {
            item.value = '';
          }
          return item;
        });
      }

      dispatch(filtersAndLabelsGetSuccess(response));
      if (typeof Callback === 'function') Callback();
    });
  };
};

let counter = 0;

export function fetchList() {
  return dispatch => {

    const { 
      page, 
      specialty,
      letter,
      gender,
      distance,
      zip,
      languages,
      location,
      scheduling,
      accepting,
      mlhc,
      mlhpp
    } = getUrlState();

      let { keyword, wildcard } = getUrlState();

    if(specialty !== "") {
        keyword = specialty;
        wildcard = false;
    }

    const { 
      currentStart, 
      currentEnd 
    } = calculatePagination(page);

    const params = {
      Start: currentStart,
      End: currentEnd
    };

    if (keyword && !letter) params['Keyword'] = keyword;
    if (letter && !keyword) params['StartsWith'] = letter;

    if (zip && distance) {
      params['Zip'] = zip;
      params['Radius'] = distance;
    }

    if (location) params['Location'] = location;
    if (languages) params['Languages'] = languages;
    if (gender) params['Gender'] = gender;
    if (mlhc) params['MLHCare'] = mlhc;
    if (mlhpp) params['MLHPP'] = mlhpp;
    if (wildcard) params['WildCard'] = wildcard;
    if (accepting) {
      params['Accepting'] = accepting;
    }
    if (scheduling) {
      params['Scheduling'] = scheduling;
    }

    dispatch(doctorsFetching());

    request(++counter);

    function request(i) {
      $.ajax({
        url: '/api/search/doctors/get',
        cache: false,
        data: params,
        method: 'POST'
      }).done((response) => {
        if (i !== counter) return;
        dispatch(doctorsGetSuccess(response));
      });
    }
  };
}
