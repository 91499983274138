import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from '../../../vendor/react-router.js';
import classnames from "classnames";
import AnimateHeight from "./AnimateHeight";

const basicTabProps = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const basicTabShape = PropTypes.shape(basicTabProps);

const tabComponentProps = {
  isActive: PropTypes.bool.isRequired,
  ...basicTabProps
};

export default class TabFilters extends Component {
  static propTypes = {
    tabs: PropTypes.arrayOf(basicTabShape),
    activeTabValue: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.tabIsActive = this.tabIsActive.bind(this);
    this.renderTab = this.renderTab.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.state = {
      isOpen: false,
      sliderHeight: 0
    }
  }

  componentWillMount() {
    if (window.matchMedia("(min-width: 900px)")) {
      this.setState(() => {
        return { sliderHeight: 'auto' }
      });
    }
  }

  tabIsActive(tab) {
    return this.props.activeTabValue && tab.value === this.props.activeTabValue;
  }

  toggleOpen() {
    this.setState((prevState) => {
      const newHeight = prevState.sliderHeight === 0 ? 'auto' : 0;
      return { isOpen: !prevState.isOpen, sliderHeight: newHeight }
    });
  }

  renderTab(tab) {
    const isActive = this.tabIsActive(tab);

    return (
      <li key={tab.value}><Link to={tab.to} className={isActive ? "active" : ""}>{tab.label}</Link>
    </li>
    );
  }

  render() {
    return (
      <div className="form-inline form-inline--no-border">
        <span className={classnames("content-type__menu-toggle", { "open": this.state.isOpen })} onClick={this.toggleOpen}><i className="fa fa-angle-down"></i> Filter results</span>
        <AnimateHeight duration={300} height={this.state.sliderHeight}>
          <ul className="search-content-type" style={{ display: "block" }}>
            {this.props.tabs.map(this.renderTab)}
          </ul>
        </AnimateHeight>
      </div>
    );
  }
}
