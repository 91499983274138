/** 
 * New version of urlState.js using queryString
 */

import queryStringApp from "query-string";

import Application from "./constants/application.js";

const { DefaultPagination } = Application;

/**
 * constructor to make a URL state Object
 * @return { getUrlState, mergeUrlQuery }
 */
export function createUrlState(defaults) {

  function getDefaults() {
    return defaults;
  }

  // read the url state from the window.location
  function getUrlState() {
    const queryString = queryStringApp.parse(location.search);

    return Object.assign({}, defaults, queryString);
  }


  // construct an object that can be passed to react-router's
  // router.push([here]) or <Link to={[here]} />
  function mergeUrlQuery(newParams){

    // merge the new params in with the url state
    const allParams = {
      ...getUrlState(),
      ...newParams
    };
    const defaults = getDefaults();

    // and remove any that are defaults
    const query = Object.keys(allParams)
      .filter(key => allParams[key] !== defaults[key] )
      .reduce((obj, key) => {
        obj[key] = allParams[key];
        return obj;
      }, {});
    
    return {
      pathname: window.location.pathname,
      query
    };
  }
    /**
     * creates a listener that can be used with browserHistory.listen()
     * that will execute onChange when the url location changes
     * eg usage:
     *   browserHistory.listen(createBrowserHistoryChangeListener(
     *     () => store.dispatch(fetchList())
     *   ))
     */
  function createBrowserHistoryChangeListener(onChange){

    // keep track of the previous state
    let prevUrlState = getUrlState();

    // this function will execute when the browserHistory changes
    return function(location) {

      const urlState = getUrlState();

      const urlChanged = Object.keys(urlState)
        .some((key) => getUrlState[key] !== urlState[key]);

      if (urlChanged){
        onChange();
      }

      prevUrlState = urlState;
    };
  }

  return { getUrlState, getDefaults, mergeUrlQuery, createBrowserHistoryChangeListener };

}


// convert to a number if it's a number
function numberify(string) {
    return (!isNaN(string) && string !== "") ? Number(string) : string;
}


export function calculatePagination(page){
    return {
        currentPage: page,
        currentStart: page != null ? 1 + ((page-1) * DefaultPagination) : 1,
        currentEnd: page != null ? ((page-1) * DefaultPagination) + DefaultPagination : DefaultPagination
    }
}
