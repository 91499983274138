import { createUrlState } from "../../urlState.js"

const defaults = {
    page: 1,
    researcherType: getDefaultResearcherType(),
    keyword: null
};


function getDefaultResearcherType(){
    var dynamicElem = document.getElementById('js-researcher-listing');
    if (dynamicElem){
        return dynamicElem.getAttribute('data-filter-default-value');
    }
    else{
        return '00000000-0000-0000-0000-000000000000';
    }
}

export default createUrlState(defaults);
