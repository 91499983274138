'use strict';

// It is important to note window.orientation command throughout this component.
// Custom date picker library called Pikaday is used in case of desktop browsers
// and in case of mobile browsers that don't support input of type 'date'. For
// mobile browsers that support input of type 'date' we are leaving devices to
// incorporate their own native behavior. Because of this I use
// window.orientation to detect if it's desktop or mobile browser.

import React, { Component } from 'react';
import PropTypes from "prop-types";
import uniqueId from '../../../common/uniqueId';
import Pikaday from 'pikaday';

export default class DatePicker extends Component {
  static propTypes = {
    labelName: PropTypes.string.isRequired,
    dateValue: PropTypes.string.isRequired,
    onDateChange: PropTypes.func.isRequired,
    minDate: PropTypes.string,
    maxDate: PropTypes.string
  };

  constructor() {
    super();
    this.dateChanged = this.dateChanged.bind(this);

    this.forId = uniqueId.generate();
  }

  componentDidMount() {
    const {minDate, maxDate} = this.props;

    if(!this.checkBrowserDateSupport() || !("orientation" in window)) {
      let options = {
        field: this.datePicker,
        position: 'bottom right',
        onSelect: (date) => this.dateChanged(null, date)
      };
      if (!!minDate) {
        options['minDate'] = minDate === 'now' ? new Date() : new Date(minDate);
      }
      if (!!maxDate) options['maxDate'] = maxDate;

      this.picker = new Pikaday(options);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {minDate, maxDate} = nextProps || this.props;
    if (typeof(this.picker) !== "undefined") {
      if (!!minDate) {
        let date = minDate === 'now' ? new Date() : new Date(minDate);
        this.picker.setMinDate(date);
      }
      if (!!maxDate) this.picker.setMaxDate(new Date(maxDate));
    }
  }

  checkBrowserDateSupport() {
    let input = document.createElement('input');
    input.setAttribute('type','date');

    let notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);

    return (input.value !== notADateValue);
  }

  getFormatDate(date) {
    let month, day;

    month = date.getMonth() + 1;
    month = month > 9 ? month : `0${month}`;

    day = date.getDate();
    day = day > 9 ? day : `0${day}`;

    return `${date.getFullYear()}-${month}-${day}`;
  }

  dateChanged(e, date) {
    if (e) e.preventDefault();
    //if ((e && !e.target.value) || !date) return;
    let value = !!e ? e.target.value : this.getFormatDate(date);
    const {onDateChange} = this.props;

    onDateChange(value);
  }

  render() {
    const {idName, labelName, dateValue, onDateChange, minDate, maxDate} =
      this.props;
    let conditional = {};
    if (!!minDate) {
      conditional['min'] = minDate === 'now' ? this.getFormatDate(new Date())
        : minDate;
    }
    if (!!maxDate) conditional['max'] = maxDate;

    return (
      <div>
        <label htmlFor={`date-picker-input-${this.forId}`}>{labelName}</label>
        <input id={`date-picker-input-${this.forId}`}
               type={"orientation" in window ? 'date' : 'text'}
               ref={el => this.datePicker = el}
               placeholder='yyyy-mm-dd'
               value={dateValue}
               onChange={this.dateChanged}
               {...conditional} />
      </div>
    );
  }
};
