'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from '../../../vendor/react-router.js';

export default class AlphabetPicker extends Component {
  static propTypes = {
    labelName: PropTypes.string.isRequired,
    onLetterSelect: PropTypes.func.isRequired,
    selectedLetter: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]).isRequired,
    path: PropTypes.string
  };

  onLetterPick(letter, e) {
    e.preventDefault();
    const {onLetterSelect} = this.props;

    onLetterSelect(letter);
  }

  renderLetters() {
    const {selectedLetter} = this.props;

    let engAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        letterItems = [];

    for (let i=0; i < engAlphabet.length; i++) {
      letterItems.push(
        <li key={i}>
              <Link to={{ pathname: this.props.path, query: { ...this.props.filters, letter: (selectedLetter === engAlphabet.charAt(i)) ? '' : engAlphabet.charAt(i) } }} className={(engAlphabet.charAt(i) === selectedLetter) ?
                'is-active' : ''}>
            {engAlphabet.charAt(i)}
          </Link>
        </li>
      );
    }

    return letterItems;
  }

  render() {
    const {labelName} = this.props;

    return (
      <div>
        <span className='label'>{labelName}</span>
        <div className='form-item'>
          <ul className='form-inline__browse filter__browse'>
            {this.renderLetters()}
          </ul>
        </div>
      </div>
    );
  }
}
