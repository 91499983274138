'use strict';

import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { keywordChanged, fetchMegaMenu, resetList, tabChanged } from '../../actions/megaMenu';
import Application from '../../constants/application';
import { fetchSpecialtiesList, fetchDoctorSpecialtiesList } from '../../actions/specialties';
import URL from '../../../common/urlManipulator';
import SearchBar from '../shared/SearchBar';
import MegaMenuDropdown from './MegaMenuDropdown';
import SelectTwo from '../shared/SelectTwo';

const { DefaultKeywordFetchLength } = Application;

@connect((state, ownProps) => {
    return {
        ...ownProps,
        megaMenu: state.megaMenu,
        isActive: ownProps.tabId === state.megaMenu.activeTab,
        specialties: state.specialties
    };
})

export default class MegaMenu extends Component {
    static propTypes = {
        contentTypes: PropTypes.array.isRequired,
        contentTypesAPI: PropTypes.array.isRequired,
        searchIsCoveo: PropTypes.bool,
        searchLink: PropTypes.string.isRequired,
        searchEndpoint: PropTypes.string,
        keywordLabel: PropTypes.string,
        keywordPlaceholder: PropTypes.string.isRequired,
        viewLabel: PropTypes.string.isRequired,
        megaMenu: PropTypes.object.isRequired,
        isActive: PropTypes.bool.isRequired,
        tabId: PropTypes.number.isRequired,
        ViewAllLink: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
        };
        this.fetchCategories = this.fetchCategories.bind(this);
        this.keywordChanged = this.keywordChanged.bind(this);
        this.clearList = this.clearList.bind(this);

        this.restructureContentTypes(props.contentTypes, props.contentTypesAPI);
    }

    componentDidMount() {
        const { specialties, searchEndpoint } = this.props;
        specialties && specialties.doctorList.length && (searchEndpoint === "GetDoctors") ?
            this.setState({ specialties: specialties.doctorList }, () => { })
            : this.fetchPaginatedSpecialties();
    }

    componentWillReceiveProps(newProps) {
        if (!this.state.specialties && newProps.specialties.doctorList.length) {
            const any = {
                name: "Any Specialty",
                value: "00000000-0000-0000-0000-000000000000"
            }
            if (!newProps.specialties.doctorList.find((obj) => obj.name === "Any Specialty")) {
                newProps.specialties.doctorList.unshift(any)
            }
            this.setState({ specialties: newProps.specialties.doctorList })
        }
    }

    componentWillUnmount() {
        const { addListener } = this.props;

        if (!addListener) {
            return;
        }
    }

    restructureContentTypes(contentTypes, contentTypesAPI) {
        this.contentTypesRestructure = {};

        contentTypes.forEach((type) => {
            this.contentTypesRestructure[type.Value] = type.Key;
        });

        this.contentTypesRestructureAPI = contentTypesAPI.map((type) => {
            return type.Key;
        });
    }

    getLink(searchLink, megaMenu) {
        let keyword = megaMenu.keyword;
        const { searchIsCoveo } = this.props;

        if (keyword && keyword.length >= DefaultKeywordFetchLength) {

            // coveo uses #q=
            if (searchIsCoveo) {
                const qs = URL.composeQueryString({ q: keyword }).replace("?", "#");
                return URL.composeUrlWithQueryString(searchLink, qs);
            }
            // regular search usese ?keyword=
            else {
                let qs = URL.composeQueryString({ keyword });
                return URL.composeUrlWithQueryString(searchLink, qs);
            }
        }
        return searchLink;
    }

    fetchCategories(keyword) {
        const { dispatch, megaMenu, contentTypesAPI, searchEndpoint } =
            this.props;

        let currKeyword = megaMenu.keyword,
            typesAPI = null,
            customUrl = null;

        if (searchEndpoint) {
            customUrl = searchEndpoint;
        } else {
            typesAPI = this.contentTypesRestructureAPI;
        }

        if (keyword.length > 0 && currKeyword.length >= DefaultKeywordFetchLength) {
            dispatch(fetchMegaMenu(typesAPI, customUrl, keyword));
        }
    }

    keywordChanged(keyword) {
        const { dispatch } = this.props;

        dispatch(keywordChanged(keyword));
    }

    onOptionChanged(option) {
        const { megaMenu, dispatch, searchLink, searchEndpoint } = this.props;

        if (searchEndpoint === "GetDoctors") {

            let qs,
                params = {};

            if (option !== megaMenu.specialty) {
                params = { specialty: option };
            }

            // Check if user selected default condition
            if (option === "00000000-0000-0000-0000-000000000000") {
                params = { specialty: '' };
                option = '';
            }

            qs = URL.composeQueryString(params);
            const url = URL.composeUrlWithQueryString(searchLink, qs);
            return URL.goToCrossBrowser(url);
        }
    }

    fetchPaginatedSpecialties() {
        const { dispatch, megamenu, searchEndpoint } = this.props;

        if (searchEndpoint === "GetDoctors") {
            dispatch(fetchDoctorSpecialtiesList(1, 999, null, null,
                // dispatch(fetchSpecialtiesList(1, 999, null, null,
                () => {
                    const any = {
                        name: "Any Specialty",
                        value: "00000000-0000-0000-0000-000000000000"
                    }
                    if (!this.props.specialties.doctorList.find((obj) => obj.name === "Any Specialty")) {
                        this.props.specialties.doctorList.unshift(any)
                    }
                    this.setState({
                        specialties: this.props.specialties.doctorList
                    });
                }
            ));
        }
    }

    clearList() {
        const { dispatch } = this.props;

        dispatch(resetList());
    }

    render() {
        const { megaMenu, specialties, searchLink, searchEndpoint, keywordLabel, keywordPlaceholder, viewLabel, coveocategoryId } = this.props;

        return (
            <div className={(searchEndpoint ? 'dropdown__wrap--search' : 'container')}>
                <div className={(keywordLabel && !searchEndpoint) ? 'dropdown__global-search' : 'dropdown__global-search--small'}>
                    {(keywordLabel && !searchEndpoint) &&
                        <h3 className='dropdown__title__center'>{keywordLabel}</h3>
                    }
                    <div className={'search-wrap' + ((keywordLabel && !searchEndpoint) ? ' dropdown__global-search__form' : '')}>

                        {searchEndpoint === "GetDoctors" ?
                            specialties && specialties.fetchingDoctors ?
                                <div className='l-block'>
                                    <img className='spinner' src='/assets/images/gif/squares.gif' />
                                </div>
                                : <SelectTwo options={this.state.specialties || []}
                                    optionSelected={this.state.specialty || '00000000-0000-0000-0000-000000000000'}
                                    onOptionSelect={(option) => this.onOptionChanged(option)}
                                    labelName={keywordLabel} />
                            : <div>
                                <SearchBar labelName={searchEndpoint ? keywordLabel : null}
                                    placeholderName={keywordPlaceholder}
                                    onTextChange={this.keywordChanged}
                                    fetchWithText={this.fetchCategories}
                                    magnifyingGlassLink={this.getLink(searchLink, megaMenu)}
                                    inputValue={megaMenu.keyword || ''}
                                    tabId={this.props.tabId}
                                    isActive={this.props.isActive} />
                                <MegaMenuDropdown searchState={megaMenu}
                                    contentTypes={this.contentTypesRestructure}
                                    onSlideClosed={this.clearList}
                                    searchLink={this.getLink(searchLink, megaMenu)}
                                    cleanLink={searchLink}
                                    isMainDropdown={this.props.isActive}
                                    viewLabel={viewLabel}
                                  
                                />
                            </div>
                        }
                        {searchEndpoint === 'GetDoctors' &&
                            <span className='dropdown__or'><span>Or</span></span>
                        }
                    </div>
                </div>
            </div>
        );
    }
};
