'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { compose, applyMiddleware, createStore } from 'redux';
import reducer from '../../reducers/index';
import Doctors from './Doctors.js';
import store from '../../store';
import { Router, Route, browserHistory } from '../../../vendor/react-router.js';
import { fetchList } from '../../actions/doctors.js';
import UrlState from './urlState.js';

const { createBrowserHistoryChangeListener } = UrlState;

(() => {
  let doctorSearchElem = document.getElementById('js-doctor-search');
  let typesElem = document.getElementById('js-content-types'),
    contentTypes = JSON.parse(typesElem.getAttribute('data-content-types-frontend')),
    contentTypesAPI = JSON.parse(typesElem.getAttribute('data-content-types-backend'));

  if (!doctorSearchElem) return;

  let props = {
    contentTypes: contentTypes,
    contentTypesAPI: contentTypesAPI,
    compareLink: doctorSearchElem.getAttribute('data-compare-link'),
  };

  browserHistory.listen(createBrowserHistoryChangeListener(
    () => store.dispatch(fetchList())
  ));

  const App = appProps => <Doctors {...{ ...appProps, ...props }} />;

  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="*" component={App} />
      </Router>
    </Provider>,
    doctorSearchElem
  );
})();
