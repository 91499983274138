'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import Application from '../../constants/application';
import getZipCode from '../../../common/getZipCode';

const { 
  DefaultTimeout, 
  DefaultZipCodeLength 
} = Application;

export default class ZipCodeBar extends Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    zipValue: PropTypes.string.isRequired,
    onZipChange: PropTypes.func.isRequired,
    fetchWithZip: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.zipChanged = this.zipChanged.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.fetchCurrentLocation = this.fetchCurrentLocation.bind(this);
  }

  crossBrowserValidity(target) {
    const { onZipChange } = this.props;

    if (target.checkValidity) {
      onZipChange(target.value, target.checkValidity());

      return target.checkValidity();
    }

    let value = target.value || target;
    let valid = value.match(`[0-9]{${DefaultZipCodeLength}}`);

    onZipChange(value, valid);

    return valid;
  }

  zipChanged(e, locationZip) {
    if (e) e.preventDefault();
    let value = locationZip || e.target.value;
    const { fetchWithZip } = this.props;

    let validity = this.crossBrowserValidity(locationZip || e.target);

    if (value.length < DefaultZipCodeLength && value.length !== 0 || !validity) {
      return;
    }

    if (this.timeoutFunc) {
      clearTimeout(this.timeoutFunc);
    }

    this.props.onZipChange(value, validity);

    this.timeoutFunc = setTimeout(() => {
      fetchWithZip();
    }, DefaultTimeout);
  }

  enterPressed(e) {
    const { zipValue } = this.props;

    if (e.charCode === 13 && zipValue.length > DefaultZipCodeLength &&
      zipValue.length !== 0) {
      fetchWithZip();
    }
  }

  fetchCurrentLocation(e) {
    e.preventDefault();

    getZipCode((response) => {
      this.zipChanged(null, response);
    }, (err) => {
      console.log(err);
    });
  }

  isGeolocationSupported() {
    return !!navigator.geolocation;
  }

  render() {
    const { placeholder, location, zipValue } = this.props;

    return (
      <div>
        <input type='text'
          placeholder={placeholder}
          pattern={`[0-9]{${DefaultZipCodeLength}}`}
          maxLength={DefaultZipCodeLength}
          onChange={this.zipChanged}
          value={zipValue}
          onKeyPress={this.enterPressed} />
        {this.isGeolocationSupported() &&
          <p className='help'>
            <a href onClick={this.fetchCurrentLocation}>
              <i className='fa fa-location-arrow'></i>
              {location}
            </a>
          </p>
        }
      </div>
    );
  }
};
