import React, { Component } from 'react';
import PropTypes from "prop-types";
import classnames  from 'classnames';
import { connect } from 'react-redux';
import Application from '../../constants/application';
import SelectTwo from '../shared/SelectTwo';
import AnimateHeight from '../shared/AnimateHeight';

const { DefaultPagination } = Application;

export default class ClinicalTrialFilter extends Component {

  static propTypes = {
    conditions: PropTypes.array.isRequired,
    selectedCondition: PropTypes.string,
    specialties: PropTypes.array.isRequired,
    selectedSpecialty: PropTypes.string,
    onFilterSubmit: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.handleConditionChange = this.handleConditionChange.bind(this);
    this.handleSpecialtyChange = this.handleSpecialtyChange.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);

    this.state = {
      selectedCondition: props.selectedCondition,
      selectedSpecialty: props.selectedSpecialty,
      isOpen: false,
      sliderHeight: 0
    }
  }

  componentWillMount() {
    if (window.matchMedia("(min-width: 900px)").matches) {
      this.setState( (prevState) => {
        return { ...prevState, sliderHeight: 'auto' }
      });
    }
  }

  toggleOpen() {
    this.setState( (prevState) => {
      const newHeight  = prevState.sliderHeight === 0 ? 'auto' : 0;
      return {isOpen: !prevState.isOpen, sliderHeight: newHeight }
    });
  }

  handleConditionChange(selected) {
    this.setState( (prevState) => ({ ...prevState, selectedCondition: selected }) );
  }

  handleSpecialtyChange(selected) {
    this.setState( (prevState) => ({ ...prevState, selectedSpecialty: selected }) );
  }

  filterSubmit(e) {
    e.preventDefault();
    this.props.onFilterSubmit(this.state.selectedSpecialty, this.state.selectedCondition);
  }



  render() {
    const { conditions, specialties } = this.props;
    const { selectedSpecialty, selectedCondition } = this.state;
    return (
      <div className="form-center">
        <div className="form-inline">
          <span className={classnames("content-type__menu-toggle", {"open": this.state.isOpen})} onClick={this.toggleOpen}><i className="fa fa-angle-down"></i> Filter results</span>
          <AnimateHeight duration={300} height={this.state.sliderHeight}>
            <div className="search-content-type--no-border" style={ {display: "block"} }>
              <div className="form-inline__label form-inline__label-toggle">
                <span className="label">Filter by</span>
              </div>
              <div className="form-inline__group form-inline--no-border">
                <div className="form-item field-med">
                  <label className="element-invisible" htmlFor="trials-condition">Filter by condition</label>
                  <SelectTwo options={conditions} optionSelected={selectedCondition} onOptionSelect={this.handleConditionChange} />
                </div>
                <div className="form-item field-med">
                  <label className="element-invisible" htmlFor="trials-condition">Filter by Specialty</label>
                  <SelectTwo options={specialties} optionSelected={selectedSpecialty} onOptionSelect={this.handleSpecialtyChange} />
                </div>
                <input className="button--input" type="submit" name="submit" value="Filter" onClick={this.filterSubmit}/>
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
    );
  }
}
