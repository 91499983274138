'use strict';

import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import NewsArticle from './NewsArticle';
import store from '../../store';
import {browserHistory, Router, Route} from '../../../vendor/react-router.js';

(() => {
  let newsArticleElem = document.getElementById('js-news-article');

  if (!newsArticleElem) return;

  let props = {};
  props['locationsDictionary'] =
    JSON.parse(newsArticleElem.getAttribute('data-locations-dictionary'));
  props['newsArticleTypes'] =
    JSON.parse(newsArticleElem.getAttribute('data-news-article-types'));
  props['keywordLabel'] = newsArticleElem.getAttribute('data-keyword-label');
  props['keywordPlaceholder'] =
    newsArticleElem.getAttribute('data-keyword-placeholder');
  props['locationsLabel'] =
    newsArticleElem.getAttribute('data-locations-label');
  props['locationsDefault'] =
    newsArticleElem.getAttribute('data-locations-default');
  props['searchButton'] = newsArticleElem.getAttribute('data-search-button');
  props['noResults'] = newsArticleElem.getAttribute('data-no-results');
  props['linkName'] = newsArticleElem.getAttribute('data-read-more');
  props['categoryLabel'] = newsArticleElem.getAttribute('data-category-label');
  props['paginationShowing'] = newsArticleElem.getAttribute('data-pagination-showing');
  props['paginationAll'] = newsArticleElem.getAttribute('data-pagination-all');
  props['paginationOf'] = newsArticleElem.getAttribute('data-pagination-of');
  props['paginationResults'] = newsArticleElem.getAttribute('data-pagination-results');

  const App = appProps => <NewsArticle { ... { ...appProps, ...props }} />;

  ReactDOM.render(
    <Provider store={store}>
      <Router history={browserHistory}>
        <Route path="*" component={App} />
      </Router>
    </Provider>,
    newsArticleElem
  );
})();
