'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';

const {
    PATIENT_CONDITION_CHANGED,
    PATIENT_TREATMENT_CHANGED,
    PATIENT_REQUEST_SUCCESS,
    PATIENT_REQUEST_FILTERS,
    PATIENT_REQUEST_SUCCESS_MORE,
    PATIENT_LOAD_MORE_UPDATE
} = Actions;

/*
export function filtersChanged(condition, treatment) {
    return {
        type: PATIENT_FILTERS_CHANGED,
        condition: condition,
        treatment: treatment
    }
}
*/

export function conditionChanged(condition, treatment) {
    return {
        type: PATIENT_CONDITION_CHANGED,
        condition: condition,
        treatment: treatment
    };
};

export function treatmentChanged(condition, treatment) {
    return {
        type: PATIENT_TREATMENT_CHANGED,
        condition: condition,
        treatment: treatment
    };
};

export function updateLoadMore(value = true) {
    return {
        type: PATIENT_LOAD_MORE_UPDATE,
        LoadMore: value
    };
}

export function patientSpotlightGetSuccess(data, loadMore) {
    return {
        type: PATIENT_REQUEST_SUCCESS,
        list: data.results,
        listTotal: data.totalResults,
        loadMore: loadMore
    };
}

export function patientSpotlightGetSuccessMore(start, end, data, loadMore) {
    return {
        type: PATIENT_REQUEST_SUCCESS_MORE,
        list: data.results,
        listTotal: data.totalResults,
        start: start,
        end: end,
        loadMore: loadMore
    };
}

export function patientSpotlightGetFilters(conditions, treatments) {
    return {
        type: PATIENT_REQUEST_FILTERS,
        conditions: conditions,
        defaultCondition: conditions[0].value,
        treatments: treatments,
        defaultTreatment: treatments[0].value
    };
}

export function fetchPatientSpotlightList(start, end, condition = null, treatment = null, callback = null) {
    return dispatch => {
        let params = {
            start: start,
            end: end
        };
        if (condition) params['condition'] = condition;
        if (treatment) params['treatment'] = treatment;

        $.ajax({
            url: '/api/search/patientspotlights/get',
            cache: false,
            data: params,
            method: 'POST'
        }).done((response) => {
            // console.log('fetched', response);
            let loadMore = !(end >= response.totalResults);

            if (start === 1) {
                dispatch(patientSpotlightGetSuccess(response, loadMore));
            } else {
                dispatch(patientSpotlightGetSuccessMore(start, end, response, loadMore));
            }

            if (typeof callback === 'function') {
                callback();
            }
        }).error((error) => {
            try {
                console.log('errorResults', error);
            } catch (e) { };
        });
    };
};

export function fetchPatientSpotlightFilter(callback = null) {
    return dispatch => {

        $.ajax({
            url: '/api/search/patientspotlights/getfilters',
            cache: false,
            method: 'GET'
        }).done((response) => {
            let conditions = response[0].map((condition) => {
                return {
                    name: condition.name,
                    value: condition.guid
                };
            });
            let treatments = response[1].map((treatment) => {
                return {
                    name: treatment.name,
                    value: treatment.guid
                };
            });
            dispatch(patientSpotlightGetFilters(conditions, treatments));

            if (typeof callback === 'function') {
                callback();
            }
        }).error((error) => {
            try {
                console.log('filterError', error);
            } catch (e) { };
        });
    };
};

