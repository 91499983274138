'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import DynamicListing from './DynamicListing';
import { finalCreateStore }  from '../../store';
import { createStore } from 'redux';
import { browserHistory, Route, Router, IndexRoute } from '../../../vendor/react-router.js';
import reducer from '../../reducers/dynamicListing';
import { fetchList } from '../../actions/dynamicListing.js'
import UrlState from './urlState.js';

const { getUrlState, createBrowserHistoryChangeListener } = UrlState;

(() => {
  let dynamicElem = document.getElementById('js-dynamic-listing');

  if (!dynamicElem) {
    return;
  }

  let props = {};
  props['taxonomyParent'] = dynamicElem.getAttribute('data-taxonomy-parent');
  props['contentType'] = JSON.parse(dynamicElem.getAttribute('data-content-types'));
  props['sortOrder'] = dynamicElem.getAttribute('data-sort-order');
  props['noResults'] = dynamicElem.getAttribute('data-no-results');
  props['linkName'] = dynamicElem.getAttribute('data-link-name');
  props['paginationShowing'] = dynamicElem.getAttribute('data-pagination-showing');
  props['paginationAll'] = dynamicElem.getAttribute('data-pagination-all');
  props['paginationOf'] = dynamicElem.getAttribute('data-pagination-of');
  props['filterLabel'] = dynamicElem.getAttribute('data-filter-label');
  props['filterDefaultValue'] = dynamicElem.getAttribute('data-filter-default-value');
  props['paginationResults'] = dynamicElem.getAttribute('data-pagination-results');
  props['initialFilterItem'] = dynamicElem.getAttribute('data-filter-initial-item');
  props['showOnlyMLHPP'] = dynamicElem.getAttribute('data-mlhpp-showOnlyMLHPP').toLowerCase()==='true';

  props.labels = {
    mainLineHealthPhysicianPartnerDoctorLabel: dynamicElem.getAttribute('data-mlhpp-doctor-label'),
    mainLineHealthPhysicianPartnerDoctorInfo: dynamicElem.getAttribute('data-mlhpp-doctor-info'),
    mainLineHealthPhysicianPartnerReadmoreText: dynamicElem.getAttribute('data-mlhpp-doctor-readmore')
  }


  // filter type is options, so we want to ensure a falsy value
  // if not provided
  let filterContentType = dynamicElem.getAttribute('data-filter-content-type');

  if (!filterContentType || filterContentType.trim() === "") {
    props['filterContentType'] = null;
  } else {
    props['filterContentType'] = JSON.parse(filterContentType);
  }

		const store = finalCreateStore(reducer);
	const App = appProps => <DynamicListing  {...appProps} {...props} />;

	browserHistory.listen(createBrowserHistoryChangeListener(
		() => store.dispatch(fetchList())
	));

  ReactDOM.render(
	  <Provider store={store}>
		<Router history={browserHistory}>
		<Route path="*" component={App} />
		</Router>
		</Provider>,
    dynamicElem
  );
})();
