import Application from "../constants/application.js";
import Actions from "../constants/actions.js";

const {
  REQUEST_DOCTORS_SUCCESS,
  REQUEST_DOCTORS_FILTERS_AND_LABELS_SUCCESS,
  REQUEST_DOCTORS_FETCHING,
  DOCTORS_KEYWORD_CHANGED,
  DOCTORS_KEYWORD_INITIATED,
  DOCTORS_LETTER_CHANGED,
  DOCTORS_ZIP_CODE_CHANGED,
  DOCTORS_PAGINATION_RESET,
  DOCTORS_MERGE_QUERY_PARAMS,
  DOCTORS_DISTANCE_CHANGED,
  DOCTORS_LOCATION_CHANGED,
  DOCTORS_LANGUAGES_CHANGED,
  DOCTORS_SPECIALTY_CHANGED,
  DOCTORS_GENDER_CHANGED,
  DOCTORS_MLH_CHECKBOX_CHANGED,
  DOCTORS_MLHPP_CHECKBOX_CHANGED,
  DOCTORS_ACCEPTING_CHECKBOX_CHANGED,
  DOCTORS_PAGINATION_CHANGED,
  DOCTORS_SCHEDULING_CHECKBOX_CHANGED
} = Actions;

const { DefaultPagination, DefaultDistanceDropdown } = Application;

const initialState = {
  list: [],
  listTotal: -1,
  fetching: false,
  pagination: {
    currentPage: 1,
    currentStart: 1,
    currentEnd: DefaultPagination
  },
  filters: {
    keyword: null,
    selectedLetter: null,
    radius: {
      zip: null,
      zipValidity: false,
      distance: DefaultDistanceDropdown[0].value
    },
    location: null,
    languages: null,
    gender: null,
    mlhCheckbox: false,
    mlhppCheckbox: false,
    acceptingCheckbox: false,
    schedulingCheckbox: false,
    specialty: null
  },
  filterValues: {
    locations: null,
    genders: null,
    languages: null
  },
  labels: null,
  specialties: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOCTORS_MERGE_QUERY_PARAMS:
      const params = action.params;
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          specialty: params.specialty,
          keyword: params.keyword
        }),
        pagination: { currentPage: params.page, ...state.pagination }
      });
    case REQUEST_DOCTORS_FILTERS_AND_LABELS_SUCCESS:
      return Object.assign({}, state, {
        filterValues: action.filterValues,
        labels: action.labels,
        specialties: action.specialties
      });
    case REQUEST_DOCTORS_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        listTotal: action.listTotal,
        fetching: false
      });
    case REQUEST_DOCTORS_FETCHING:
      return Object.assign({}, state, {
        list: [],
        listTotal: -1,
        fetching: true
      });
    case DOCTORS_KEYWORD_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          keyword: action.keyword
        })
      });
    case DOCTORS_KEYWORD_INITIATED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: Object.assign({}, state.filters, {
          selectedLetter: null
        })
      });
    case DOCTORS_LETTER_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: Object.assign({}, state.filters, {
          selectedLetter: action.letter,
          keyword: null
        })
      });
    case DOCTORS_ZIP_CODE_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          radius: Object.assign({}, state.filters.radius, {
            zip: action.zipCode,
            zipValidity: action.zipValidity
          })
        })
      });
    case DOCTORS_PAGINATION_RESET:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        }
      });
    case DOCTORS_DISTANCE_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          radius: Object.assign({}, state.filters.radius, {
            distance: action.distance
          })
        })
      });
    case DOCTORS_LOCATION_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          location: action.location
        })
      });
    case DOCTORS_LANGUAGES_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          languages: action.languages
        })
      });
    case DOCTORS_GENDER_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          gender: action.gender
        })
      });
    case DOCTORS_SPECIALTY_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: Object.assign({}, state.filters, {
          specialty: action.specialty,
          keyword: action.specialty
        })
      });
    case DOCTORS_MLH_CHECKBOX_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: Object.assign({}, state.filters, {
          mlhCheckbox: action.mlhCheckbox
        })
      });
    case DOCTORS_MLHPP_CHECKBOX_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: Object.assign({}, state.filters, {
          mlhppCheckbox: action.mlhppCheckbox
        })
      });
    case DOCTORS_ACCEPTING_CHECKBOX_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: Object.assign({}, state.filters, {
          acceptingCheckbox: action.acceptingCheckbox
        })
      });
    case DOCTORS_SCHEDULING_CHECKBOX_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: Object.assign({}, state.filters, {
          schedulingCheckbox: action.schedulingCheckbox
        })
      });
    case DOCTORS_PAGINATION_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: action.page,
          currentStart: action.start,
          currentEnd: action.end
        }
      });
    default:
      return state;
  }
};
