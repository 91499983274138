'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class LocationListType extends Component {
  static propTypes = {
    item: PropTypes.shape({
      title: PropTypes.string.isRequired,
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      phone: PropTypes.string,
      thumbnailImage: PropTypes.string,
      readMoreLink: PropTypes.string.isRequired
    }).isRequired,
    linkName: PropTypes.string.isRequired
  };

  getAltImageValue(title) {
    return `${title} Picture`
  }

  renderDetails() {
    const {item} = this.props;

    return(
      <div>
        { (!!item.address1 || !!item.address2 || !!item.city || !!item.state ||
          !!item.zip) &&
          <p>
            { !!item.address1 && <span>{item.address1}<br/></span> }
            { !!item.address2 &&
              <span>{item.address2}
                { (!!item.city || !!item.state || !!item.zip) &&
                  <span>,<br/></span>
                }
              </span>
            }
            { !!item.city &&
              <span>{item.city}
                { (!!item.state || !!item.zip) &&
                  <span>, </span>
                }
              </span>
            }
            { !!item.state && <span>{item.state} </span> }
            { !!item.zip && <span>{item.zip}</span> }
          </p>
        }
        { !!item.phone && <p>Phone: {item.phone}</p> }
      </div>
    );
  }

  render() {
    const {item, linkName} = this.props;

    return (
      <div className='list__item--location'>
        <div className='list__meta'>
          <span className='list__type'>Locations</span>
        </div>
        { item.thumbnailImage &&
          <a href={item.readMoreLink} className='list__img'>
            <img src={item.thumbnailImage}
                 alt={this.getAltImageValue(item.title)} />
          </a>
        }
        <div className={'list__content' + (item.thumbnailImage ?
               ' has-img' :'')}>
          <h4 className='list__title'>
            <a href={item.readMoreLink}>{item.title}</a>
          </h4>
          {this.renderDetails()}
          <a className='link' href={item.readMoreLink}>{linkName}</a>
        </div>
      </div>
    );
  }
};
