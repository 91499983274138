'use strict';

import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from "prop-types";
import Application from '../../constants/application';
import DoctorsDropdownItem from './DoctorsDropdownItem';
import DoctorsSpecialtiesDropdownItem from './DoctorsSpecialtiesDropdownItem';
import LocationsDropdownItem from './LocationsDropdownItem';
import GeneralDropdownItem from './GeneralDropdownItem';
import NewsEventsDropdownItem from './NewsEventsDropdownItem';

const { DefaultKeywordFetchLength } = Application;

export default class MegaMenuDropdown extends Component {
  static propTypes = {
    searchState: PropTypes.shape({
      list: PropTypes.array.isRequired,
      keyword: PropTypes.string
    }).isRequired,
    contentTypes: PropTypes.object.isRequired,
    onSlideClosed: PropTypes.func.isRequired,
    searchLink: PropTypes.string.isRequired,
    cleanLink: PropTypes.string.isRequired,
    isMainDropdown: PropTypes.bool.isRequired,
    viewLabel: PropTypes.string.isRequired
  };

  componentDidMount() {
    this.dropdownSlide();
    this.onUpdateCheck();
  }

  componentWillReceiveProps(nextProps) {
    this.onUpdateCheck(nextProps);
  }

  componentDidUpdate() {
    this.dropdownSlide();
  }

  onUpdateCheck(nextProps) {
    const { searchState, isMainDropdown } = nextProps || this.props;

    this.hideDoctorsBySpecialty = isMainDropdown && searchState.doctorsBySpecialty.length === 0;
  }

  dropdownSlide() {
    const { searchState, onSlideClosed, isMainDropdown } = this.props;
    let dropdown = $(this.dropdownRef);

    if (isMainDropdown && searchState.list.length > 0 && searchState.keyword && searchState.keyword.length >= DefaultKeywordFetchLength) {
      if (dropdown.is(':hidden')) {
        dropdown.slideDown();
      }
    } else {
      if (dropdown.is(':visible')) {
        dropdown.slideUp(400, onSlideClosed);
      }
    }
  }

    renderList(searchState, viewLabel, searchLink, contentTypes, isMainDropdown, cleanLink, coveocategoryId) {
    let renderObj = {},
      renderArr = [],
      i = 0;

    searchState.list.forEach((item) => {
      if (renderObj.hasOwnProperty(item.contentType)) {
        return renderObj[item.contentType].push(item);
      }
      renderObj[item.contentType] = [item];
    });

    if (renderObj.hasOwnProperty('doctor') || renderObj.hasOwnProperty('therapist')) {
      /* Note nested ternary below to allow for doctors and therapists to be combined into one "Providers" */
       //renderArr.push(
       //  <DoctorsDropdownItem key={i}
       //    viewLabel={viewLabel}
       //    sideClass={this.hideDoctorsBySpecialty && !isMainDropdown ? false : (i % 2) + 1}
       //    searchLink={searchLink}
       //    contentType={contentTypes.Providers}
       //    isMainDropdown={isMainDropdown}
       //    items={
       //      renderObj.hasOwnProperty('doctor') && renderObj.hasOwnProperty('therapist')
       //        ? renderObj.doctor.concat(renderObj.therapist)
       //        : renderObj.hasOwnProperty('doctor')
       //          ? renderObj.doctor
       //          : renderObj.therapist
       //    } />);

       //i++;

      if (!this.hideDoctorsBySpecialty) {
        renderArr.push(
          <DoctorsSpecialtiesDropdownItem items={searchState.doctorsBySpecialty}
            searchLink={cleanLink}
            key={i} />);

        i++;
      }
    }

    if (renderObj.hasOwnProperty('location')) {
      renderArr.push(
          <LocationsDropdownItem key={i}
              viewLabel={viewLabel}
              sideClass={isMainDropdown ? (i % 2) + 1 : false}
              searchLink={searchLink}
              contentType={contentTypes.Locations}
              isMainDropdown={isMainDropdown}
              items={renderObj.location}
              coveocategoryId={coveocategoryId}/>);

      i++;
    }

    if (renderObj.hasOwnProperty('specialty') ||
      renderObj.hasOwnProperty('service')) {

      let arr = [];

      if (!!renderObj.specialty) {
        arr = arr.concat(renderObj.specialty);
      }

      if (!!renderObj.service) {
        arr = arr.concat(renderObj.service);
      }

      renderArr.push(
        <GeneralDropdownItem key={i}
          title="Specialties"
          viewLabel={viewLabel}
          sideClass={isMainDropdown ? (i % 2) + 1 : false}
          searchLink={searchLink}
          contentType={contentTypes.Specialties}
          isMainDropdown={isMainDropdown}
          items={arr}
          coveocategoryId={coveocategoryId}/>);

      i++;
    }

    if (renderObj.hasOwnProperty('condition') || renderObj.hasOwnProperty('treatment')) {
      let arr = [];

      if (!!renderObj.condition) {
        arr = arr.concat(renderObj.condition);
      }

      if (!!renderObj.treatment) {
        arr = arr.concat(renderObj.treatment);
      }

      renderArr.push(<GeneralDropdownItem
        key={i}
        title="Conditions & Treatments"
        viewLabel={viewLabel}
        sideClass={isMainDropdown ? (i % 2) + 1 : false}
        searchLink={searchLink}
        contentType={contentTypes['Conditions']}
        isMainDropdown={isMainDropdown}
        items={arr}
        coveocategoryId={coveocategoryId}/>);

      i++;
    }

    if (renderObj.hasOwnProperty('event')) {
      renderArr.push(
        <NewsEventsDropdownItem key={i}
          title="Events"
          viewLabel={viewLabel}
          sideClass={isMainDropdown ? (i % 2) + 1 : false}
          searchLink={searchLink}
          contentType={contentTypes.Events}
          isMainDropdown={isMainDropdown}
          items={renderObj.event}
          coveocategoryId={coveocategoryId}/>);

      i++;
    }

    if (renderObj.hasOwnProperty('news') || renderObj.hasOwnProperty('blog')) {
      let arr = [];

      if (!!renderObj.news) {
        arr = arr.concat(renderObj.news);
      }

      if (!!renderObj.blog) {
        arr = arr.concat(renderObj.blog);
      }

      renderArr.push(
        <NewsEventsDropdownItem key={i}
          title="News & Blog"
          viewLabel={viewLabel}
          sideClass={isMainDropdown ? (i % 2) + 1 : false}
          searchLink={searchLink}
          contentType={contentTypes["News & Blog"]}
          isMainDropdown={isMainDropdown}
          items={arr}
          coveocategoryId={coveocategoryId}/>);

      i++;
    }

    if (renderObj.hasOwnProperty('Research') || renderObj.hasOwnProperty('clinical trial')) {
        let arr = [];

        if (!!renderObj.Research) {
            arr = arr.concat(renderObj.Research);
        }
        if (!!renderObj["clinical trial"]) {
            arr = arr.concat(renderObj["clinical trial"]);
        }
        renderArr.push(
            <GeneralDropdownItem key={i}
                title="Research"
                viewLabel={viewLabel}
                sideClass={isMainDropdown ? (i % 2) + 1 : false}
                searchLink={searchLink}
                contentType={contentTypes['Research']}
                //contentType={contentTypes.Research}
                isMainDropdown={isMainDropdown}
                items={arr}
                coveocategoryId={coveocategoryId}/>);

        i++;

    }

    if (renderObj.hasOwnProperty('general') ||
        renderObj.hasOwnProperty('practice')) {

          let arr = [];

          if (!!renderObj.general) {
              arr = arr.concat(renderObj.general);
          }

          if (!!renderObj.practice) {
              arr = arr.concat(renderObj.practice);
          }


      renderArr.push(
        <GeneralDropdownItem key={i}
          title="General Content"
          viewLabel={viewLabel}
          sideClass={isMainDropdown ? (i % 2) + 1 : false}
          searchLink={searchLink}
          contentType={contentTypes['General content']}
          isMainDropdown={isMainDropdown}
          items={arr}
          coveocategoryId={coveocategoryId}/>);

      i++;
    }

    return renderArr;
  }

  render() {
      const { searchState, viewLabel, searchLink, contentTypes, isMainDropdown, cleanLink, coveocategoryId} =
      this.props;

    return (
      <div className={'dropdown--container' + (isMainDropdown ? ' dropdown--carousel' : '')}
        ref={el => this.dropdownRef = el}
        style={{ display: 'none' }}>
        {this.renderList(searchState, viewLabel, searchLink, contentTypes,
            isMainDropdown, cleanLink, coveocategoryId)}
      </div>
    );
  }
};
