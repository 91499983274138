/* ******** React Infinite scroll ***********
Based on: https://github.com/guillaumervls/react-infinite-scroll
Example:
<InfiniteScroll
    pageStart=0
    loadMore={loadFunc}
    hasMore={true || false}
    loader={<div className="loader">Loading ...</div>}>
  {items} // <-- This is the "stuff" you want to load
</InfiniteScroll>
*/

import React, { Component } from 'react';
import PropTypes from "prop-types";

function topPosition(domElt) {
  if (!domElt) {
    return 0;
  }
  return domElt.offsetTop + topPosition(domElt.offsetParent);
}

export default class InfiniteScroll extends Component {
  static propTypes = {
    pageStart: PropTypes.number.isRequired,
    hasMore: PropTypes.bool.isRequired,
    loadMore: PropTypes.func.isRequired,
    threshold: PropTypes.number
  }

  static defaultProps = {
    threshold:100
  }

  constructor(props) {
    super(props);
    this.scrollListener = this.scrollListener.bind(this);
    this.attachScrollListener = this.attachScrollListener.bind(this);
    this.detachScrollListener = this.detachScrollListener.bind(this);
  }

  componentDidMount() {
    this.attachScrollListener();
  }

  componentDidUpdate() {
    this.attachScrollListener();
  }

  componentWillUnmount() {
    this.detachScrollListener();
  }

  scrollListener() {
    var el = this.scrollContainerRef;
    var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    if (topPosition(el) + el.offsetHeight - scrollTop - window.innerHeight < Number(this.props.threshold)) {
      // call loadMore after detachScrollListener to allow
      // for non-async loadMore functions
      if(this.props.loadMore()){
        this.detachScrollListener();
      };
    }
  }

  attachScrollListener() {
    if (!this.props.hasMore) {
      return;
    }
    window.addEventListener('scroll', this.scrollListener);
    window.addEventListener('resize', this.scrollListener);
    this.scrollListener();
  }

  detachScrollListener() {
    window.removeEventListener('scroll', this.scrollListener);
    window.removeEventListener('resize', this.scrollListener);
  }

  render() {
    return <div ref={el => this.scrollContainerRef = el} {...this.props} />;
  }
};
