'use strict';

function refreshUrl(queryString) {
  if (window.history.pushState) {
    let newUrl = window.location.origin + window.location.pathname +
      queryString;
    window.history.pushState({/*path: newUrl*/}, '', newUrl);
  } else {
    window.location = window.location.protocol + "//" + window.location.host +
      window.location.pathname + '#' + queryString;
  }
}

function getQueryString() {
  let qs = window.location.search;
  qs = qs.charAt(0) === '?' ? qs : null;

  let qsHash = window.location.hash;
  qsHash = qsHash.charAt(0) === '#' && qsHash.charAt(1) === '?' ?
    qsHash.substring(1) : null;

  if ((qs && !window.history.pushState) ||
      (qsHash && window.history.pushState)) {
    refreshUrl(qs || qsHash);
  }

  return qs || qsHash;
}

function getParameterByName(name, queryString) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(queryString);
  return results === null ? "" :
    decodeURIComponent(results[1].replace(/\+/g, " "));
}

function composeQueryString(params) {
  let qs = [];

  for(let p in params) {
    if (params.hasOwnProperty(p)) {
      qs.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
    }
  }

  return '?' + qs.join("&");
}

function addNewQueryString(queryString) {
  let newUrl = window.location.origin + window.location.pathname + queryString;

  if (window.history.pushState) {
    window.history.pushState({/*path: newUrl*/}, '', newUrl);
  } else {
    window.location.hash = queryString;
  }
}

function composeUrlWithQueryString(url, queryString) {
	if (window.history.pushState) {
		if (url.indexOf('?') > 0) {
			queryString = queryString.replace("?", "&")
		}
    return url + queryString;
  }
  return url + '#' + queryString;
}

// this function checks which browser supports history API and decides how to
// switch URL's, if it's modern browser, just changing query string will
// redirect, but older browsers use hash tag to pass query string, so if user
// is on the page to which we want to redirect, just changing hash will not
// reload page so we need to manually do that
function goToCrossBrowser(url) {
  let urlPath = url.substr(0, window.location.pathname.length);
  urlPath = (urlPath === '/' || urlPath === url) ? null : urlPath;
  if (window.location.pathname === urlPath && !window.history.pushState) {
    window.location = url;
    return window.location.reload();
  }
  window.location = url;
}

export default {
  getQueryString: getQueryString,
  getParameterByName: getParameterByName,
  composeQueryString: composeQueryString,
  addNewQueryString: addNewQueryString,
  composeUrlWithQueryString: composeUrlWithQueryString,
  goToCrossBrowser: goToCrossBrowser
};
