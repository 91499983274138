﻿'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class NewsListType extends Component {
    static propTypes = {
        item: PropTypes.shape({

            title: PropTypes.string.isRequired,
            summary: PropTypes.string.isRequired,
            readMoreLink: PropTypes.string.isRequired,
            thumbnailImage: PropTypes.string,
            conditions: PropTypes.string

        }).isRequired,
        linkName: PropTypes.string.isRequired,
        itemType: PropTypes.string.isRequired,
        relatedConditionsLabel: PropTypes.string
    }

    constructor(props) {
        super(props);
        const {item}=this.props;
    }

    createMarkup(stringMarkup) {
        return { __html: stringMarkup };
    }

    render() {
        const { item, itemType } = this.props;

        let conditions = <span />;

        if (item.conditions) {
            conditions = <div className="list__item--related-conditions"><b>Related Conditions:</b> {item.conditions}</div>;
        }

        return (
            <div className="list__item list__item--clinicaltrials">
                <div className="list__meta">
                    <span className="list__type">{itemType}</span>
                </div>
                <h4 className="list__title"><a href={item.readMoreLink.toString()}>{item.title}</a></h4>
                <div dangerouslySetInnerHTML={{ __html: item.summary }}></div>
                {conditions}
                <div className="list__item--View-additional-details"><a className="link" href={item.readMoreLink.toString()}>View additional details for this trial</a></div>
            </div>
        );
    }
    
};