'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from '../../../vendor/react-router.js';
import { fetchFilter, fetchList } from '../../actions/clinicalTrialListing';
import Application from '../../constants/application';
import Pagination from '../shared/Pagination';
import ClinicalTrialFilter from './ClinicalTrialFilter';
import UrlState from "./urlState.js";

const { getUrlState, mergeUrlQuery } = UrlState;

const { DefaultPagination } = Application;

@connect(state => ({ clinicalTrialListing: state.clinicalTrialListing }))
@withRouter
export default class ClinicalTrialListing extends Component {
  static propTypes = {
    noResults: PropTypes.string.isRequired,
    paginationShowing: PropTypes.string.isRequired,
    paginationAll: PropTypes.string.isRequired,
    paginationOf: PropTypes.string.isRequired,
    paginationResults: PropTypes.string.isRequired,
    
    router: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };

  constructor() {
    super();
    this.onFilterChange = this.onFilterChange.bind(this);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    this.getFilter();
    dispatch(fetchList());
  }

  getFilter() {
    const { dispatch } = this.props;
    dispatch(fetchFilter());
  }

  shouldShowFilter() {
    return this.props.clinicalTrialListing.filter && this.props.clinicalTrialListing.filter.length > 0;
  }

  onFilterChange(specialty, condition) {
    const { router } = this.props;
    router.push(mergeUrlQuery({ specialty, condition, page: 1 }));
  }

  renderTrial(trial, i) {
    let conditions = <span />;

    if (trial.conditions) {
        conditions = <div className="list__item--related-conditions"><b>Related Conditions:</b> {trial.conditions}</div>;
    }

    return (
      <li className="list__item list__item--event" key={i}>
        <div className="list__meta">
          <span className="list__type">{trial.clinicalTrialLabel}</span>
        </div>
        <h4 className="list__title"><a href={trial.link}>{trial.heading} ({trial.studyID})</a></h4>
        <div dangerouslySetInnerHTML={{ __html: trial.summary }}></div>
        {conditions}
        <div className="list__item--View-additional-details"><a className="link" href={trial.link.toString()}>View additional details for this trial</a></div>
      </li>
    );
  }

  render() {
	  const { clinicalTrialListing, noResults } = this.props;
    const { page, condition, specialty } = getUrlState();
    const { list, listTotal, fetching } = clinicalTrialListing;

    return (
      <div>
        <ClinicalTrialFilter
          conditions={clinicalTrialListing.conditions}
          selectedCondition={condition}
          specialties={clinicalTrialListing.specialties}
          selectedSpecialty={specialty}
          onFilterSubmit={this.onFilterChange}
        />
        <Pagination
          collectionTotal={listTotal}
          currentPage={page}
          paginationCount={DefaultPagination}
          paginationPosition="right"
          paginationShowing="Showing"
          paginationAll="all"
          paginationOf="of"
          paginationResults="results"
          pageParam="page"
        />
        <div className="list list--lg search-results__wrap">
          <ul className="list__items">
            {list.map((item, i) => this.renderTrial(item, i))}
          </ul>
          {fetching && (
            <img src='/assets/images/gif/squares.gif' className='spinner' />
				)}
				{clinicalTrialListing.listTotal === 0 &&					
					<div class="search-results">
						<div class="list list--lg search-results__wrap">
							<p>{noResults}</p>
						</div>
					</div>
				}
        </div>
        <Pagination
          collectionTotal={listTotal}
          currentPage={page}
          paginationCount={DefaultPagination}
          paginationPosition="right"
          paginationShowing="Showing"
          paginationAll="all"
          paginationOf="of"
          paginationResults="results"
          pageParam="page"
			/>
      </div>
    );
  }
};
