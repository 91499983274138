'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from '../../../vendor/react-router.js';
import debounce from 'debounce';
import { fetchFilter, fetchList, loadKeywordChange } from '../../actions/researcherListing';
import Application from '../../constants/application';
import SearchBar from '../shared/SearchBar';
import Pagination from '../shared/Pagination';
import TabFilters from '../shared/TabFilters';
import UrlState from './urlState.js';

const { getUrlState, mergeUrlQuery } = UrlState;

const { DefaultPagination } = Application;

@connect(state => ({ researcherListing: state.researcherListing }))
@withRouter
export default class ResearcherListing extends Component {
  static propTypes = {
    router: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
      noResults: PropTypes.string.isRequired,
      linkName: PropTypes.string.isRequired,
      paginationShowing: PropTypes.string.isRequired,
      paginationAll: PropTypes.string.isRequired,
      paginationOf: PropTypes.string.isRequired,
      paginationResults: PropTypes.string.isRequired,
      filterDefaultValue: PropTypes.string.isRequired,
      researcherListing: PropTypes.object.isRequired
  };

    constructor() {
        super();
    this.onKeywordChange = debounce(this.onKeywordChange.bind(this), 300);
    }

    componentWillMount() {
        const { dispatch } = this.props;
        this.getFilter();
        dispatch(fetchList());
    }

    getFilter() {
        const { dispatch, taxonomyParent, contentType, filterContentType, filterDefaultValue } = this.props;

        dispatch(fetchFilter(taxonomyParent, contentType, filterContentType, filterDefaultValue));
    }

    shouldShowFilter() {
      return this.props.researcherListing.filter && this.props.researcherListing.filter.length > 0;
    }

    onKeywordChange(keyword)  {
    const { router } = this.props;
    router.push(mergeUrlQuery({ keyword, page: 1 }));
    }

    renderProfile(profile, i) {
      const hasPhoto = !!profile.picture;
      const hasEmail = !!profile.email;
      const hasPhone = !!profile.phone;
      const hasPosition = !!profile.position;

      return (
        <li className="list__item list__item--doctor" key={i}>
          <div className="list__meta">
            <span className="list__type">{profile.researcherType}</span>
          </div>
          {hasPhoto && (
            <a href={profile.link} className="list__img">
              <img src={profile.picture} alt={profile.name} />
            </a>
          )}
          <div className={classnames("list__content", {"has-img": hasPhoto}) } >
            <h4 className="list__title">
              <a href={profile.link}>{profile.name}</a>
            </h4>
            <p className="list__content-list">
              { hasPosition && (<span>{profile.position}</span>) }
              { hasEmail && (<span className="list__email"><a href={"mailto:" + profile.email} data-rel="external"><span className="fa fa-envelope"></span>{profile.email}</a></span>) }
              { hasPhone && (<span className="list__phone"><a href={profile.phoneLink}><span className="fa fa-phone"></span> {profile.phone}</a></span>) }
            </p>
            <p dangerouslySetInnerHTML={ { __html: profile.shortBio } }></p>
            <a className="link" href={profile.link}>Read More</a>
          </div>
        </li>
      );
    }


    render() {
        const { researcherListing} = this.props;

    const { list, listTotal, filter=[], pagination, fetching } = researcherListing;

    const tabs = filter.map( (tab) => {
      return Object.assign({}, tab, {
        to: mergeUrlQuery({ page: 1, researcherType: tab.value })
      })
    });

    const { page, researcherType } = getUrlState();

    const paginationComponent = (
        <Pagination
              collectionTotal={listTotal}
          currentPage={page}
              paginationCount={DefaultPagination}
              paginationPosition="right"
              paginationShowing="Showing"
              paginationAll="all"
              paginationOf="of"
              paginationResults="results"
            />
      );

    return (
      <div>
        <SearchBar
          placeholderName="Search by keyword"
          onTextChange={this.onKeywordChange}
          inputValue={researcherListing.keyword || ""} />
        <div>&nbsp;</div>
        {
          this.shouldShowFilter() && (
            <TabFilters
              tabs={tabs}
              activeTabValue={researcherType}
            />
          )
        }
          {paginationComponent}
            <div className="list list--lg search-results__wrap">
              <ul className="list__items">
                { list.map( (item, i) => this.renderProfile(item.profile, i) )}
              </ul>
              {fetching && (
                <img src='/assets/images/gif/squares.gif' className='spinner' />
              )}
            </div>
        {paginationComponent}
          </div>
        );
    }
};
