import React, {PropTypes}	from "react";
import $					from "jquery";

export default React.createClass({
	displayName: "DropDown",

	propTypes: {
		handleChange: PropTypes.func.isRequired,
		activeLink: PropTypes.string.isRequired,
		links: PropTypes.array.isRequired
	},

	// when the user picks a new value
	handleMenuChange: function(value,event){
		// prevent the default action
		event.preventDefault();
		// pass the new value back to the parent
		this.props.handleChange(value);
	},

	render: function() {
		if(this.props.links.length === 1 && this.props.links[0] === ""){
			return (<span>--</span>);
		}

		return (
			<ul className="splash__nav__select" ref={(ref) => {$(ref).dropit();}}>
				<li className="selected-item">
					<a href="javascript:void(0);">{this.props.activeLink}</a>
					<ul className="splash__nav__menu">
						{this.props.links.map((link,index) => 
							<li key={index}><a href="#" onClick={this.handleMenuChange.bind(this,index)}>{link}</a></li>
						)}
					</ul>
				</li>
			</ul>
		);
	}
});