﻿'use strict';

import Actions from '../constants/actions';

const { 
  REQUEST_CLINICALTRIAL_LIST_SUCCESS, 
  REQUEST_CLINICALTRIAL_LIST_FETCHING,
  REQUEST_CLINICALTRIAL_FILTER_SUCCESS,
  REQUEST_CLINICALTRIAL_FILTER_FETCHING
} = Actions;
const initialState = {
  list: [],
  listTotal: -1, // set to -1 before request to track when it's done
  fetching: false,
  fetchingFilter: false,
  specialties: [],
  conditions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CLINICALTRIAL_LIST_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        listTotal: action.listTotal,
        fetching: false
      });
    case REQUEST_CLINICALTRIAL_LIST_FETCHING:
      return Object.assign({}, state, {
        list: [],
        listTotal: -1,
        fetching: true
      });
    case REQUEST_CLINICALTRIAL_FILTER_FETCHING:
      return Object.assign({}, state, {
        fetchingFilter: true
      });
    case REQUEST_CLINICALTRIAL_FILTER_SUCCESS:
      return Object.assign({}, state, {
        specialties: action.filter.filters.specialtyTypes, 
        conditions: action.filter.filters.conditionTypes,
        fetchingFilter: false
      });
    default:
      return state;
  }
}
