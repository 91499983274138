'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class NewsListType extends Component {
  static propTypes = {
    item: PropTypes.shape({
      newsOrBlogLocation: PropTypes.string,
      newsOrBlogPublishedDate: PropTypes.string.isRequired,
      newsOrBlogCategory: PropTypes.string,
      thumbnailImage: PropTypes.string,
      title: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      readMoreLink: PropTypes.string.isRequired
    }).isRequired,
    linkName: PropTypes.string.isRequired,
    itemType: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    const { item } = this.props;

    this.startDate = new Date(item.newsOrBlogPublishedDate);
  }

  getFullMonth(date) {
    let fullMonth = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];

    return fullMonth[date.getMonth()];
  }

  getAltImageValue(title) {
    return `${title} Picture`;
  }

  createMarkup(stringMarkup) {
    return { __html: stringMarkup };
  }

  render() {
    const { item, linkName, itemType } = this.props;

    return (
      <div className='list__item--news'>
        <div className='list__meta'>
          <span className='list__type'>{itemType}</span>
          {item.newsOrBlogLocation && (
            <span className='list__location'>{item.newsOrBlogLocation}</span>
          )}
          <span className='list__date'>
            {this.getFullMonth(this.startDate)}&nbsp;
            {this.startDate.getDate()}, {this.startDate.getFullYear()}
          </span>
          {item.newsOrBlogCategory && (<span>{item.newsOrBlogCategory}</span>)}
        </div>
        {item.thumbnailImage &&
          <a href={item.readMoreLink} className='list__img'>
            <img src={item.thumbnailImage}
              alt={this.getAltImageValue(item.title)} />
          </a>
        }
        <div className={'list__content' + (item.thumbnailImage ?
          ' has-img' : '')}>
          <h4 className='list__title'>
            <a href={item.readMoreLink}>{item.title}</a>
          </h4>
          <p dangerouslySetInnerHTML={this.createMarkup(item.summary)}></p>
          <a className='link' href={item.readMoreLink}>{linkName}</a>
        </div>
      </div>
    );
  }
};
