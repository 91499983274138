'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import Application from '../../constants/application';
import EventListType from './EventListType';
import NewsBlogListType from './NewsBlogListType';
import GeneralContentListType from './GeneralContentListType';
import DoctorListType from './DoctorListType';
import TherapistListType from './TherapistListType';
import LocationListType from './LocationListType';
import Pagination from './Pagination';
import ClinicalTrialsListType from './ClinicalTrialsListType';

const { DefaultPagination } = Application;

export default class DynamicListMainSearch extends Component {
    static propTypes = {
        searchState: PropTypes.shape({
            list: PropTypes.array.isRequired,
            listTotal: PropTypes.number.isRequired,
            wasCorrected: PropTypes.bool,
            correctedKeyword: PropTypes.string,
            originalKeyword: PropTypes.string
        }).isRequired,
    currentPage: PropTypes.number.isRequired,
        labels: PropTypes.object,
        listClassName: PropTypes.string,
        linkName: PropTypes.string.isRequired,
        noResults: PropTypes.string.isRequired,
        paginationShowing: PropTypes.string.isRequired,
        paginationAll: PropTypes.string.isRequired,
        paginationOf: PropTypes.string.isRequired,
        paginationResults: PropTypes.string.isRequired
    };

    renderWhichItem(item) {
        const { linkName, labels } = this.props;

        switch (item.contentType) {
        case 'event':
            return <EventListType event={item} linkName={linkName} />;
        case 'news':
            return <NewsBlogListType item={item} linkName={linkName} itemType='News' />;
        case 'blog':
            return <NewsBlogListType item={item} linkName={linkName} itemType='Blog' />;
        case 'condition':
        case 'treatment':
            return <GeneralContentListType item={item} linkName={linkName} itemType='Conditions & Treatments' />;
        case 'specialty':
        case 'service':
            return <GeneralContentListType item={item} linkName={linkName} itemType='Specialties' />;
        case 'general':
            return <GeneralContentListType item={item} linkName={linkName} itemType='General Content' />
        case 'doctor':
            return <DoctorListType doctor={item} linkName={linkName} labels={labels} />;
        case 'therapist':
            return <TherapistListType doctor={item} linkName={linkName} />;
        case 'location':
            return <LocationListType item={item} linkName={linkName} />;
        case 'practice':
            return <GeneralContentListType item={item} linkName={linkName} itemType="Practice" />;
        case 'patient spotlight':
            return <NewsBlogListType item={item} linkName={linkName} itemType="Patient Spotlight" />;
        case 'clinical trial':
            return <ClinicalTrialsListType item={item} linkName={linkName} itemType="Clinical Trials" />;
        case 'Research':
                return <GeneralContentListType item={item} linkName={linkName} itemType='Research' />;
        default:
            return null;
        }
    }

    renderList(list) {
        return list.map((item, index) => {
            return (
            <li className='list__item' key={index}>
                {this.renderWhichItem(item)}
                </li>
        );
    });
}

render() {
    const { searchState, listClassName, noResults,
      paginationShowing, paginationAll, paginationOf, paginationResults, currentPage } =
        this.props;

    return (
        <div>
        {searchState.fetching &&
            <img src='/assets/images/gif/squares.gif' className='spinner' />
        }
    {searchState.listTotal > 0 &&
        <div>
            <Pagination
        collectionTotal={searchState.listTotal}
        paginationCount={DefaultPagination}
			  currentPage={currentPage}
        paginationPosition='top'
        paginationShowing={paginationShowing}
        paginationAll={paginationAll}
        paginationOf={paginationOf}
        paginationResults={paginationResults}
        pageParam="page"
        wasCorrected={searchState.wasCorrected}
        correctedKeyword={searchState.correctedKeyword}
        originalKeyword={searchState.originalKeyword} />

            <div className={'list list--lg' + (!!listClassName ? ` ${listClassName}` : '')}>
    <ul className='list__items'>
        {this.renderList(searchState.list)}
</ul>
    </div>

    <Pagination
collectionTotal={searchState.listTotal}
paginationCount={DefaultPagination}
              currentPage= {currentPage }
paginationPosition='top'
paginationShowing={paginationShowing}
paginationAll={paginationAll}
paginationOf={paginationOf}
paginationResults={paginationResults}
pageParam="page" />
    </div>
}
{searchState.listTotal === 0 &&
    <div className={'list list--lg' + (!!listClassName ? ` ${listClassName}` : '')}>
<p>{noResults}</p>
    </div>
}
</div>
);
}
};
