﻿'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';
import UrlState from '../components/researcher-listing/urlState.js';
import { calculatePagination } from '../urlState.js';

const { getUrlState } = UrlState;

const { 
    REQUEST_RESEARCHER_LIST_SUCCESS, 
    REQUEST_RESEARCHER_LIST_FETCHING,
    REQUEST_RESEARCHER_FILTER_SUCCESS,
    REQUEST_RESEARCHER_FILTER_FETCHING
} = Actions;

function listGetSuccess(data) {
    return {
        type: REQUEST_RESEARCHER_LIST_SUCCESS,
        list: data.results,
        listTotal: data.totalResults
    };
}

function listFetching() {
    return {
        type: REQUEST_RESEARCHER_LIST_FETCHING
    };
}

function filterGetSuccess(data) {
    return {
        type: REQUEST_RESEARCHER_FILTER_SUCCESS,
        filter: data
    }
}

function filterFetching() {
    return {
        type: REQUEST_RESEARCHER_FILTER_FETCHING,
    }
}

export function filterChange(value) {
    return {
        type: RESEARCHER_FILTER_CHANGE,
        value: value
    }
}

export function fetchList() {
    return (dispatch, getState) => {

        const { page, researcherType, keyword } = getUrlState();
        const { currentStart, currentEnd } = calculatePagination(page);

        let params = {
            Start: currentStart,
            End: currentEnd,
            ResearcherType: researcherType,
            // ResultsSortOrder: sortOrder,
            Keyword: keyword
        };

        dispatch(listFetching());

        $.ajax({
            url: '/api/search/researcherlisting/get',
            cache: false,
            data: $.param(params),
            method: 'POST'
        }).done((response) => {
            dispatch(listGetSuccess(response));
        }).fail(() => {
            dispatch(listGetSuccess({"totalResults":0,"results":[]}));
        });
    };
}

export function fetchFilter() {
    return dispatch => {
        let params = {};

        dispatch(filterFetching());

        $.ajax({
            url: '/api/search/researcherlisting/getfilters',
            cache: false,
            data: params,
            method: 'POST'
        }).done(response => {
            dispatch(filterGetSuccess(response));
        }).fail(() => {
            dispatch(filterGetSuccess([]));
        });
    }
}
