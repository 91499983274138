'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';

const {
  REQUEST_MAIN_SEARCH_SUCCESS, 
  REQUEST_MAIN_SEARCH_FETCHING,
  MAIN_SEARCH_KEYWORD_CHANGED, 
  MAIN_SEARCH_KEYWORD_INITIATED,
  MAIN_SEARCH_CONTENT_TYPES_CHANGED, 
  MAIN_SEARCH_PAGINATION_CHANGED
} = Actions;

function mainSearchGetSuccess(data) {
  return {
    type: REQUEST_MAIN_SEARCH_SUCCESS,
    list: data.results,
    listTotal: data.totalResults,
    wasCorrected: data.wasCorrected,
    correctedKeyword: data.correctedKeyword,
    originalKeyword: data.originalKeyword
  };
}

function mainSearchFetching() {
  return {
    type: REQUEST_MAIN_SEARCH_FETCHING
  };
}

export function contentTypesChanged(contentTypes) {
  return {
    type: MAIN_SEARCH_CONTENT_TYPES_CHANGED,
    contentTypes: contentTypes
  };
};

export function paginationChanged(page, start, end) {
  return {
    type: MAIN_SEARCH_PAGINATION_CHANGED,
    page: page,
    start: start,
    end: end
  };
};

export function keywordChanged(keyword) {
  return {
    type: MAIN_SEARCH_KEYWORD_CHANGED,
    keyword: keyword
  };
};

// counter to track request responses and dissmiss all responses except the one
// from first request
let counter = 0;
export function fetchMainSearch(Start, End, ContentTypes, Keyword = null,
  callback = null) {
  return dispatch => {
    let params = {
      Start: Start,
      End: End,
      ContentTypes: ContentTypes
    };

    if (Keyword) params['Keyword'] = Keyword;

    dispatch(mainSearchFetching());
    request(++counter);

    function request(i) {
      $.ajax({
        url: '/api/search/generalsearch/search',
        cache: false,
        data: params,
        method: 'POST'
      }).done((response) => {
        if (i !== counter) return;
        dispatch(mainSearchGetSuccess(response));

        if (typeof callback === 'function') callback();
      });
    }
  };
};
