'use strict';

import Actions from '../constants/actions';
import Application from '../constants/application';

const {
  REQUEST_SPECIALTIES_SUCCESS, 
  REQUEST_SPECIALTIES_FETCHING,
  REQUEST_DOCTOR_SPECIALTIES_SUCCESS, 
  REQUEST_DOCTOR_SPECIALTIES_FETCHING,
  SPECIALTIES_LETTER_CHANGED, 
  SPECIALTIES_KEYWORD_CHANGED,
  SPECIALTIES_PAGINATION_CHANGED, 
  SPECIALTIES_KEYWORD_INITIATED,
  SPECIALTIES_RESET_SEARCH
} = Actions;

const { DefaultPagination } = Application;

const initialState = {
  list: [],
  listTotal: -1, // set to -1 before request to track when it's done
  doctorList: [],
  doctorListTotal: -1,
  fetching: false,
  fetchingDoctors: false,
  pagination: {
    currentPage: 1,
    currentStart: 1,
    currentEnd: DefaultPagination
  },
  filters: {
    selectedLetter: null,
    keyword: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SPECIALTIES_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        listTotal: action.listTotal,
        fetching: false
      });
    case REQUEST_SPECIALTIES_FETCHING:
      return Object.assign({}, state, {
        list: [],
        listTotal: -1,
        fetching: true
      });
      case REQUEST_DOCTOR_SPECIALTIES_SUCCESS:
        return Object.assign({}, state, {
          doctorList: action.list,
          doctorListTotal: action.listTotal,
          fetchingDoctors: false
        });
      case REQUEST_DOCTOR_SPECIALTIES_FETCHING:
        return Object.assign({}, state, {
          doctorList: [],
          doctorListTotal: -1,
          fetchingDoctors: true
        });
    case SPECIALTIES_LETTER_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: {
          selectedLetter: action.letter,
          keyword: null,
        }
      });
    case SPECIALTIES_KEYWORD_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          keyword: action.keyword
        })
      });
    case SPECIALTIES_KEYWORD_INITIATED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: Object.assign({}, state.filters, {
          selectedLetter: null
        })
      });
    case SPECIALTIES_PAGINATION_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: action.page,
          currentStart: action.start,
          currentEnd: action.end
        }
      });
    case SPECIALTIES_RESET_SEARCH:
      return Object.assign({}, state, {
        list: [],
        listTotal: -1
      });
    default:
      return state;
  }
}
