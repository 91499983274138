'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import CompareButton from '../shared/CompareButton';

(() => {
  let compareBtnElem = document.getElementById('js-compare-button');

  if (!compareBtnElem) return;

  let props = {};
  props['addLabel'] = compareBtnElem.getAttribute('data-add-label');
  props['removeLabel'] = compareBtnElem.getAttribute('data-remove-label');
  props['doctorId'] = compareBtnElem.getAttribute('data-doctor-id');

  ReactDOM.render(
    <CompareButton {...props} />,
    compareBtnElem
  );
})();
