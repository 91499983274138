import { createUrlState } from "../../urlState.js";

const defaults = {
    page: 1,
    specialty: "00000000-0000-0000-0000-000000000000",
    condition: "00000000-0000-0000-0000-000000000000"
};


export default createUrlState(defaults);
