'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import Application from '../../constants/application';

const { CookieName } = Application;

export default class CompareButton extends Component {
  static propTypes = {
    addLabel: PropTypes.string.isRequired,
    removeLabel: PropTypes.string.isRequired,
    doctorId: PropTypes.string.isRequired
  };

  constructor() {
    super();
    this.state = {inStorage: false};
  }

  componentDidMount() {
    const {doctorId} = this.props;
    this.checkDoctorInStorage(doctorId);
  }

  checkDoctorInStorage(id) {
    let cookie = Cookies.get(CookieName);
    if (!cookie) return;

    cookie = cookie.split(',');
    cookie.some((item) => {
      if (item === id) return this.setState({inStorage: true});
    });
  }

  addDoctor(e, id) {
    e.preventDefault();

    let cookie = Cookies.get(CookieName);
    cookie = !!cookie ? cookie.split(',') : [];

    if (this.state.inStorage) {
      cookie.pop(id);
      this.setState({inStorage: false});
    } else {
      cookie.push(id);
      this.setState({inStorage: true});
    }

    return Cookies.set(CookieName, cookie.join(','), {path: '/'});
  }

  render() {
    const {addLabel, removeLabel, doctorId} = this.props;

    return (
      <a className='button button--secondary'
         onClick={(e) => this.addDoctor(e, doctorId)}
         href>
        { this.state.inStorage ?
          <span><i className='fa fa-minus'></i> {removeLabel}</span> :
          <span><i className='fa fa-plus'></i> {addLabel}</span>
        }
      </a>
    );
  }
};
