'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import URL from '../../../common/urlManipulator';

export default class DoctorsSpecialtiesDropdownItem extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    searchLink: PropTypes.string.isRequired
  };

  constructor() {
    super();
    this.linkClicked = this.linkClicked.bind(this);
  }

  linkClicked(e, specialty) {
    e.preventDefault();
    const {searchLink} = this.props;

    let qs = URL.composeQueryString({'keyword': specialty});
    let url = URL.composeUrlWithQueryString(searchLink, qs);
    return URL.goToCrossBrowser(url);
  }

	getLink(specialty){
	const {searchLink} = this.props;

	let qs = URL.composeQueryString({'keyword': specialty});
	let url = URL.composeUrlWithQueryString(searchLink, qs);
	return url;
}
  renderList(list) {
    return list.map((item, index) => {
      return (
        <li className='list__item list__item--simple' key={index}>
          <a href={this.getLink(item.specialtyName)}>
            {item.specialtyName}
          </a>
          <span className='doctors__number'>({item.doctorCount} doctors)</span>
        </li>
      );
    });
  }

  render() {
    const {items} = this.props;

    return (
      <div className='l-2up--2'>
        <div className='list list--sm'>
          <div className='list__header'>
            <h3 className='list__header__title'>Doctors by Specialty</h3>
          </div>
          <ul className='list__items'>
            {this.renderList(items)}
          </ul>
        </div>
      </div>
    );
  }
};
