'use strict';

import Actions from '../constants/actions';
import Application from '../constants/application';

const {
  REQUEST_CONDITIONS_AND_TREATMENTS_SUCCESS,
  REQUEST_CONDITIONS_AND_TREATMENTS_FETCHING,
  CONDITIONS_AND_TREATMENTS_LETTER_CHANGED,
  CONDITIONS_AND_TREATMENTS_KEYWORD_CHANGED,
  CONDITIONS_AND_TREATMENTS_PAGINATION_CHANGED,
  CONDITIONS_AND_TREATMENTS_KEYWORD_INITIATED,
  CONDITIONS_AND_TREATMENTS_RESET_SEARCH
} = Actions;

const { DefaultPagination } = Application;

const initialState = {
  list: [],
  listTotal: -1, // set to -1 before request to track when it's done
  fetching: false,
  pagination: {
    currentPage: 1,
    currentStart: 1,
    currentEnd: DefaultPagination
  },
  filters: {
    selectedLetter: null,
    keyword: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_CONDITIONS_AND_TREATMENTS_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        listTotal: action.listTotal,
        fetching: false
      });
    case REQUEST_CONDITIONS_AND_TREATMENTS_FETCHING:
      return Object.assign({}, state, {
        list: [],
        listTotal: -1,
        fetching: true
      });
    case CONDITIONS_AND_TREATMENTS_LETTER_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: {
          selectedLetter: action.letter,
          keyword: null,
        }
      });
    case CONDITIONS_AND_TREATMENTS_KEYWORD_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          keyword: action.keyword
        })
      });
    case CONDITIONS_AND_TREATMENTS_KEYWORD_INITIATED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: 1,
          currentStart: 1,
          currentEnd: DefaultPagination
        },
        filters: Object.assign({}, state.filters, {
          selectedLetter: null
        })
      });
    case CONDITIONS_AND_TREATMENTS_PAGINATION_CHANGED:
      return Object.assign({}, state, {
        pagination: {
          currentPage: action.page,
          currentStart: action.start,
          currentEnd: action.end
        }
      });
    case CONDITIONS_AND_TREATMENTS_RESET_SEARCH:
      return Object.assign({}, state, {
        list: [],
        listTotal: -1
      });
    default:
      return state;
  }
};
