'use strict';

import $ from 'jquery'
import React, { Component } from 'react';
import PropTypes from "prop-types";
import Application from '../../constants/application';
import ZipCodeBar from '../shared/ZipCodeBar';
import Select from '../shared/Select';
import SelectTwo from '../shared/SelectTwo';
import Checkbox from '../shared/Checkbox-mlhc';
import UrlState from "./urlState.js";

const {
  getUrlState
} = UrlState;

const { DefaultDistanceDropdown } = Application;

export default class DoctorsMoreFilters extends Component {
  static propTypes = {
    labels: PropTypes.shape({
      filterZipcode: PropTypes.string.isRequired,
      filterZipcodePlaceholder: PropTypes.string.isRequired,
      currentLocation: PropTypes.string.isRequired,
      filterBy: PropTypes.string.isRequired,
      mainLineHealthCareDoctorsCheckbox: PropTypes.string.isRequired,
      mainLineHealthPhysicianPartnerCheckbox: PropTypes.string.isRequired,
      mainLineHealthPhysicianPartnerInfo: PropTypes.string.isRequired,
      mainLineHealthPhysicianPartnerReadmoreText: PropTypes.string.isRequired
    }).isRequired,
    filters: PropTypes.object.isRequired,
    filterValues: PropTypes.shape({
      locations: PropTypes.array,
      languages: PropTypes.array,
      genders: PropTypes.array
    }).isRequired,
    onZipChanged: PropTypes.func.isRequired,
    onZipFetch: PropTypes.func.isRequired,
    onDistanceSelected: PropTypes.func.isRequired,
    onLocationSelected: PropTypes.func.isRequired,
    onLanguagesSelected: PropTypes.func.isRequired,
    onGenderSelected: PropTypes.func.isRequired,
    onMlhCheckbox: PropTypes.func.isRequired,
    onMlhppCheckbox: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.filtersAssignEvent();
  }

  filtersAssignEvent() {
    const { filtersBtn, filtersContainer, chevronUp, chevronDown } = this;

    $(filtersBtn).click(function (e) {
      e.preventDefault();

      $(filtersContainer).slideToggle(null, () => {
        if ($(filtersContainer).is(':visible')) {
          $(chevronUp).show();
          return $(chevronDown).hide();
        }
        $(chevronUp).hide();
        $(chevronDown).show();
      });
    });
  }

  isZipDisabled(filters) {
    return !(filters.radius.zip && filters.radius.zipValidity);
  }

  getLanguages(languages, filterValues) {
    if (languages) {
      return [filterValues.languages[0].value].concat(languages);
    }

    return [filterValues.languages[0].value];
  }

  render() {
    const { labels, filters, filterValues, onZipChanged, onZipFetch,
      onDistanceSelected, onLocationSelected, onLanguagesSelected,
      onGenderSelected, onMlhCheckbox, onMlhppCheckbox } = this.props;

    const { distance, gender, location, languages, mlhc, mlhpp } = getUrlState();

    return (
      <div>
        <div className='form-more-options' ref={el => this.filtersContainer = el}>
          <div className='form-inline'>
            <div className='form-inline__label'>
              <span className='label'>{labels.filterZipcode}</span>
            </div>
            <div className='form-inline__group'>
              <div className='form-item field-med'>
                <ZipCodeBar placeholder={labels.filterZipcodePlaceholder}
                  location={labels.currentLocation}
                  zipValue={filters.radius.zip || ''}
                  onZipChange={onZipChanged}
                  fetchWithZip={onZipFetch} />
              </div>
              <div className='form-item field-med'>
                <Select options={DefaultDistanceDropdown}
                  optionSelected={ distance }
                  onOptionSelect={onDistanceSelected}
                  isDisabled={this.isZipDisabled(filters)} />
              </div>
            </div>
          </div>
          <div className='form-inline form-inline--last'>
            <div className='form-inline__label'>
              <span className='label'>{labels.filterBy}</span>
            </div>
            <div className='form-inline__group'>
              {Array.isArray(filterValues.locations) &&
                filterValues.locations.length > 0 &&
                <div className='form-item field-med'>
                  <SelectTwo options={filterValues.locations}
                    optionSelected={ location ||
                      filterValues.locations[0].value}
                    onOptionSelect={onLocationSelected} />
                </div>
              }
              {Array.isArray(filterValues.languages) &&
                filterValues.languages.length > 0 &&
                <div className='form-item field-med'>
                  <SelectTwo options={filterValues.languages}
                    optionSelected={ this.getLanguages(languages, filterValues) }
                    onOptionSelect={onLanguagesSelected}
                    multiSelect={true}
                    multiClass='select2-multi' />
                </div>
              }
              {Array.isArray(filterValues.genders) &&
                filterValues.genders.length > 0 &&
                <div className='form-item field-sm'>
                  <Select options={filterValues.genders}
                    optionSelected={ gender ||
                      filterValues.genders[0].value}
                    onOptionSelect={onGenderSelected} />
                </div>
              }
              <div className='form-item field-lg form-item--checkbox-info'>
                <fieldset >
                  <Checkbox
                    labelName={labels.mainLineHealthCareDoctorsCheckbox}
                    checkedState={ mlhc === "true" }
                    onCheckboxChange={onMlhCheckbox}
                    infoPopup={labels.mainLineHealthCareDoctorsInfo} />

                  <Checkbox
                    labelName={labels.mainLineHealthPhysicianPartnerCheckbox}
                    checkedState={ mlhpp === "true" }
                    onCheckboxChange={onMlhppCheckbox}
                    infoPopup={
                      <div>
                        {labels.mainLineHealthPhysicianPartnerInfo} <a href="#">{labels.mainLineHealthPhysicianPartnerReadmoreText}</a>
                      </div>
                    } />


                </fieldset>
              </div>

            </div>
          </div>
        </div>

        <div className='form-view-filters'>
          <a className='button button--more-options'
            ref={el => this.filtersBtn = el}
            href='#'>
            <i className='fa fa-chevron-down' ref={el => this.chevronDown = el}></i>
            <i className='fa fa-chevron-up'
              ref={el => this.chevronUp = el}
              style={{ display: 'none' }}></i>
            &nbsp;{labels.filterButton}
          </a>
        </div>
      </div>
    );
  }
};
