"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";
import uniqueId from "../../../common/uniqueId";

export default class Checkbox extends Component {
  static propTypes = {
    labelName: PropTypes.string.isRequired,
    checkedState: PropTypes.bool.isRequired,
    onCheckboxChange: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.checkboxClicked = this.checkboxClicked.bind(this);

    this.forId = uniqueId.generate();
  }

  checkboxClicked(e) {
    e.preventDefault();
    const { onCheckboxChange } = this.props;

    onCheckboxChange(e.target.checked);
  }

  render() {
    const { labelName, checkedState } = this.props;

    return (
      <div>
        <input
          type="checkbox"
          id={`checkbox-input-${this.forId}`}
          checked={checkedState}
          onChange={this.checkboxClicked}
        />
        <label htmlFor={`checkbox-input-${this.forId}`}>{labelName}</label>
      </div>
    );
  }
}
