'use strict';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Locations from './Locations';
import store from '../../store';

(() => {
  let locationsElem = document.getElementById('js-locations-search');

  if (!locationsElem) return;

  let props = {};
  props['zipLabel'] = locationsElem.getAttribute('data-zip-label');
  props['zipPlaceholder'] = locationsElem.getAttribute('data-zip-placeholder');
  props['zipLocation'] = locationsElem.getAttribute('data-zip-location');
  props['keywordLabel'] = locationsElem.getAttribute('data-keyword-label');
  props['keywordPlaceholder'] =
    locationsElem.getAttribute('data-keyword-placeholder');
  props['listShowing'] = locationsElem.getAttribute('data-list-showing');
  props['listResults'] = locationsElem.getAttribute('data-list-results');
  props['listDetails'] = locationsElem.getAttribute('data-list-details');
  props['listDirections'] = locationsElem.getAttribute('data-list-directions');
  props['listLoad'] = locationsElem.getAttribute('data-list-load');
  props['noResults'] = locationsElem.getAttribute('data-no-results');


  ReactDOM.render(
    <Provider store={store}>
      <Locations {...props} />
    </Provider>,
    locationsElem
  );
})();
