'use strict';

import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {fetchSpecialtiesList, letterChanged, keywordChanged,
  paginationPageChanged, keywordSearchInitiated, resetSearch}
  from '../../actions/specialties';
import SpecialtiesConditionsTreatmentsSearch
  from '../shared/SpecialtiesConditionsTreatmentsSearch';
import Application from '../../constants/application';

const { SearchComponentClass } = Application;

@connect(state => ({ specialties: state.specialties }))
export default class Specialties extends Component {
  static propTypes = {
    keywordLabel: PropTypes.string.isRequired,
    keywordPlaceholder: PropTypes.string.isRequired,
    alphabetLabel: PropTypes.string.isRequired,
    searchOr: PropTypes.string.isRequired,
    paginationShowing: PropTypes.string.isRequired,
    paginationAll: PropTypes.string.isRequired,
    paginationOf: PropTypes.string.isRequired,
    paginationResults: PropTypes.string.isRequired,
    linkName: PropTypes.string.isRequired,
    noResults: PropTypes.string.isRequired
  };

  constructor() {
    super();
    this.getPaginatedList = this.getPaginatedList.bind(this);
    this.onKeywordChanged = this.onKeywordChanged.bind(this);
    this.onLetterChanged = this.onLetterChanged.bind(this);
    this.onKeywordSearchInitiated = this.onKeywordSearchInitiated.bind(this);
    this.onResetSearch = this.onResetSearch.bind(this);
    this.onPaginationPageChanged = this.onPaginationPageChanged.bind(this);
  }

  getPaginatedList(start, end, letter, keyword, callback) {
    const {dispatch} = this.props;

    dispatch(fetchSpecialtiesList(start, end, letter, keyword, callback));
  }

  onKeywordChanged(keyword) {
    const {dispatch} = this.props;

    let page = Number(this.props.location.query.page || 1);

    dispatch(keywordChanged(keyword));
  }

  onLetterChanged(letter) {
    const {dispatch} = this.props;

    let page = Number(this.props.location.query.page || 1);

    if (this.props.location.query.keyword) {
      this.props.router.push({
        pathname: this.props.location.pathname,
        query: {
          letter: letter,
          page: page
        }
      });
    }

    dispatch(letterChanged(letter));
  }

  onKeywordSearchInitiated() {
    const {dispatch} = this.props;

    dispatch(keywordSearchInitiated());
  }

  onResetSearch() {
    const {dispatch} = this.props;

    dispatch(resetSearch());

    $(SearchComponentClass).trigger('reset');
  }

  onPaginationPageChanged(page, start, end) {
    const {dispatch} = this.props;

    window.scrollTo(0, 200);

    dispatch(paginationPageChanged(page, start, end));
  }

  render() {
    const {dispatch, specialties, ...other} = this.props;

    return <SpecialtiesConditionsTreatmentsSearch
             searchState={specialties}
             getPaginatedList={this.getPaginatedList}
             onKeywordChanged={this.onKeywordChanged}
             onLetterChanged={this.onLetterChanged}
             onKeywordSearchInitiated={this.onKeywordSearchInitiated}
             onResetSearch={this.onResetSearch}
             onPaginationPageChanged={this.onPaginationPageChanged}
             {...other} />;
  }
}
