import React      		from "react";
import ReactDOM   		from "react-dom";
import TaskNavigation 	from "./TaskNavigation.jsx";

// Parent controller for the doctor search app
;(function(){
	// find the location for the code
	var taskNavLocation = document.querySelector('.js-task-navigation'),
		button = "",
		id = "",
		messageA = "",
		messageB = ""

	// if the location doesn't exist
	if (null === taskNavLocation){
		return;
	}

	button = taskNavLocation.getAttribute('data-button-label');
	id = taskNavLocation.getAttribute('data-guid');
	messageA = taskNavLocation.getAttribute('data-message-a');
	messageB = taskNavLocation.getAttribute('data-message-b');
	let lastSelectedPersona = taskNavLocation.getAttribute('data-selection-persona');
	let lastSelectedTask = taskNavLocation.getAttribute('data-selection-task');
	
	function updateBackground(image){
		// find the current background element
		let current = document.querySelector('.js-task-navigation-background');
		// find the parent node
		let parent = current.parentNode;
		// copy the current background element
		let copy = document.createElement('div');
		// add the classes
		copy.className = current.className;
		// add the new background image to the copy
		copy.style.backgroundImage = "url(" + image + ")";
		// insert the copy before the current element
		parent.insertBefore(copy,current);
		// wait for the transition to complete
		setTimeout(function(){
			// remove the current element
			parent.removeChild(current);
		},1000)
	}

	ReactDOM.render(
		<TaskNavigation 
			button={button} 
			id={id} 
			messageA={messageA}
			messageB={messageB}
			updateBackground={updateBackground} 
			lastSelectedPersona={lastSelectedPersona}
			lastSelectedTask={lastSelectedTask}
			/>,
		taskNavLocation
	);

})();
