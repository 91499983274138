'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class GeneralContentListType extends Component {
    static propTypes = {
        item: PropTypes.shape({
            title: PropTypes.string.isRequired,
            summary: PropTypes.string.isRequired,
            readMoreLink: PropTypes.string.isRequired
        }).isRequired,
        linkName: PropTypes.string.isRequired,
        itemType: PropTypes.string.isRequired
    };

    createMarkup(stringMarkup) {
        return { __html: stringMarkup };
    }

    render() {
        const { item, linkName, itemType } = this.props;

        return (
            <div className='list__item--condition'>
                <div className='list__meta'>
                    <span className='list__type'>{itemType}</span>
                </div>
                <div className='list__content'>
                    <h4 className='list__title'>
                        <a href={item.readMoreLink} dangerouslySetInnerHTML={this.createMarkup(item.title)}></a>
                    </h4>
                    <p dangerouslySetInnerHTML={this.createMarkup(item.summary)}></p>
                    <a className='link' href={item.readMoreLink}>{linkName}</a>
                </div>
            </div>
        );
    }
};
