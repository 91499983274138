import Application from "../constants/application.js";
import Actions from "../constants/actions.js";

const { DefaultDistanceDropdown } = Application;
const {
  REQUEST_LOCATIONS_SUCCESS, 
  REQUEST_LOCATIONS_LOAD_MORE_SUCCESS,
  REQUEST_LOCATIONS_FETCHING, 
  REQUEST_LOCATIONS_LOAD_FETCHING,
  LOCATIONS_KEYWORD_CHANGED, 
  LOCATIONS_ZIP_CODE_CHANGED,
  LOCATIONS_LOAD_MORE_RESET, 
  LOCATIONS_DISTANCE_CHANGED,
  LOCATIONS_INCREMENT_LOAD_MORE, 
  LOCATIONS_SELECT_ITEM_CHANGED
} = Actions;

const initialState = {
  list: [],
  listTotal: 0,
  fetching: false,
  loadMoreFetching: false,
  loadMoreCounter: 1, // starts with 1 similar to first page of pagination
  selectedListItem: 0, // starts with 0 as first 'cos of array syntax
  filters: {
    keyword: null,
    radius: {
      zip: null,
      zipValidity: false,
      distance: DefaultDistanceDropdown[0].value
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        listTotal: action.listTotal,
        selectedListItem: 0,
        fetching: false
      });
    case REQUEST_LOCATIONS_LOAD_MORE_SUCCESS:
      return Object.assign({}, state, {
        list: state.list.concat(action.list),
        listTotal: action.listTotal,
        loadMoreFetching: false
      });
    case REQUEST_LOCATIONS_FETCHING:
      return Object.assign({}, state, {
        list: [],
        listTotal: -1,
        fetching: true
      });
    case REQUEST_LOCATIONS_LOAD_FETCHING:
      return Object.assign({}, state, {
        loadMoreFetching: true
      });
    case LOCATIONS_KEYWORD_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          keyword: action.keyword
        })
      });
    case LOCATIONS_LOAD_MORE_RESET:
      return Object.assign({}, state, {
        loadMoreCounter: 1
      });
    case LOCATIONS_ZIP_CODE_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          radius: Object.assign({}, state.filters.radius, {
            zip: action.zipCode,
            zipValidity: action.zipValidity
          })
        })
      });
    case LOCATIONS_DISTANCE_CHANGED:
      return Object.assign({}, state, {
        filters: Object.assign({}, state.filters, {
          radius: Object.assign({}, state.filters.radius, {
            distance: action.distance
          })
        })
      });
    case LOCATIONS_INCREMENT_LOAD_MORE:
      return Object.assign({}, state, {
        loadMoreCounter: state.loadMoreCounter + 1
      });
    case LOCATIONS_SELECT_ITEM_CHANGED:
      return Object.assign({}, state, {
        selectedListItem: action.index
      })
    default:
      return state;
  }
};
