import React, { PropTypes } from "react";
import DropDown from "./DropDown.jsx";
import $ from "jquery";

export default React.createClass({
	displayName: "TaskNavigation",

	propTypes: {
		id: PropTypes.string.isRequired,
		button: PropTypes.string,
		messageA: PropTypes.string,
		messageB: PropTypes.string,
		updateBackground: PropTypes.func.isRequired,
		lastSelectedTask: PropTypes.string,
		lastSelectedPersona: PropTypes.string
	},

	getDefaultProps: function () {
		return {
			button: "Continue"
		};
	},

	getInitialState: function () {
		return {
			currentPersona: 0,
			currentTask: 0,
			data: {},
			currentTasks: [""],
			personaValues: [""],
			buttonLink: "#"
		};
	},

	componentWillMount: function () {
		let self = this;
		// get the GUID
		let guid = this.props.id;
		// fetch the API data
		$.ajax({
			url: '/api/taskNav/get/{' + guid + '}'
		})
			.done((response) => {
				// referencing personas
				let data = response.personas;

				// getting first persona (first or last selected according to xdb)
				let currentPersona = this.getCurrentPersona(data);

				// getting first task (first or last selected according to xdb)
				let currentTask = this.getCurrentTask(data[currentPersona].taskItems);

				// making list of persona labels
				let personas = data.map((persona) => persona.name);

				// getting link for current task
				let buttonLink = data[currentPersona].taskItems[currentTask].link.link;

				// making list of task labels
				let tasks = data[currentPersona].taskItems.map((task) => task.name);

				// update the model
				this.setState({
					data: data,
					personaValues: personas,
					currentTasks: tasks,
					buttonLink: buttonLink,
					currentPersona: currentPersona,
					currentTask: currentTask
				});

				//this.props.updateBackground(this.state.data[currentPersona].bannerImageUrl);

				return data;
			})
			.fail((ex) => { console.log('parsing failed', ex) });
	},

	getCurrentPersona: function (data) {
		for (var i = 0; i < data.length; i++) {
			if (this.props.lastSelectedPersona === data[i].id)
				return i;
		}
		return 0;
	},

	getCurrentTask: function (tasks) {
		for (var i = 0; i < tasks.length; i++) {
			if (this.props.lastSelectedTask === tasks[i].id)
				return i;
		}
		return 0;
	},

	handlePersona: function (index) {
		// if the new persona matches the existing
		if (this.state.currentPersona === index) {
			// do nothing
			return;
		}
		// change the parent's background image
		this.props.updateBackground(this.state.data[index].bannerImageUrl)
		// create the new list of tasks
		let tasks = this.state.data[index].taskItems.map((task) => task.name);
		// get the new button link
		let buttonLink = this.state.data[index].taskItems[0].link.link;
		// update the model with the new state
		this.setState({ currentPersona: index, currentTask: 0, currentTasks: tasks, buttonLink: buttonLink });
	},

	handleTask: function (index) {
		// if the new persona matches the existing
		if (this.state.currentTask === index) {
			// do nothing
			return;
		}
		// get the new button link
		let buttonLink = this.state.data[this.state.currentPersona].taskItems[index].link.link;
		// update the model with the new state
		this.setState({ currentTask: index, buttonLink: buttonLink });
	},

	handleButtonClick: function (event) {
		event.preventDefault();
		var persona = this.state.data[this.state.currentPersona];
		var personaId = persona.id;
		var taskItem = persona.taskItems[this.state.currentTask];
		var taskItemId = taskItem.id;
		var data = { 'PersonaId': personaId, 'TaskItemId': taskItemId };
		// push data to BE
		$.ajax({
			method: 'POST',
			url: 'tasknav/postdatatoxdb',
			data: JSON.stringify(data),
			contentType: 'application/json'
		})
			.done(response => {
				window.location = this.state.buttonLink;
			})
			.fail((ex) => { console.log('push to xDB failed', ex) });
	},

	render: function () {
		return (
			<div className="splash__nav">
				<span className="splash__nav__text">{this.props.messageA}&nbsp;</span>
				<DropDown
					handleChange={this.handlePersona}
					activeLink={this.state.personaValues[this.state.currentPersona]}
					links={this.state.personaValues} />
				<span className="splash__nav__text">&nbsp;{this.props.messageB}&nbsp;</span>
				<DropDown
					handleChange={this.handleTask}
					activeLink={this.state.currentTasks[this.state.currentTask]}
					links={this.state.currentTasks} />
				&nbsp;
				<a className="button" href="#" onClick={this.handleButtonClick}><span className="splash_continue">{this.props.button}</span> <i className="fa fa-chevron-right"></i></a>
			</div>
		);
	}
});