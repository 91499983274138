'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';

const {REQUEST_EVENTS_SUCCESS, 
  REQUEST_EVENTS_LOAD_MORE_SUCCESS,
  REQUEST_EVENTS_FETCHING, 
  REQUEST_EVENTS_LOAD_FETCHING,
  EVENTS_KEYWORD_CHANGED, 
  EVENTS_LOCATION_SELECTED, 
  EVENTS_LOCATION_DESELECTED,
  EVENTS_CATEGORY_CHANGED, 
  EVENTS_CATEGORY_DESELECTED,
  EVENTS_INCREMENT_LOAD_MORE, 
  EVENTS_LOAD_MORE_RESET, 
  EVENTS_SET_LOAD_MORE,
  EVENTS_START_DATE_CHANGED, 
  EVENTS_END_DATE_CHANGED, 
  EVENTS_PAGINATION_CHANGED
} = Actions;

function eventsGetSuccess(data) {
  return {
    type: REQUEST_EVENTS_SUCCESS,
    list: data.events,
    listTotal: data.totalResults
  };
}

function eventsLoadMoreSuccess(data) {
  return {
    type: REQUEST_EVENTS_LOAD_MORE_SUCCESS,
    list: data.events,
    listTotal: data.totalResults
  };
}

function eventsFetching() {
  return {
    type: REQUEST_EVENTS_FETCHING
  };
}

function eventsLoadMoreFetching() {
  return {
    type: REQUEST_EVENTS_LOAD_FETCHING
  };
}

export function keywordChanged(keyword) {
  return {
    type: EVENTS_KEYWORD_CHANGED,
    keyword: keyword
  };
};

export function locationSelected(locationKey) {
  return {
    type: EVENTS_LOCATION_SELECTED,
    locationKey: locationKey
  };
};

export function locationDeselected() {
  return {
    type: EVENTS_LOCATION_DESELECTED
  };
};

export function categoryChanged(category) {
  return {
    type: EVENTS_CATEGORY_CHANGED,
    category: category
  };
};

export function categoryDeselected() {
  return {
    type: EVENTS_CATEGORY_DESELECTED
  };
};

export function dateChanged(dateType, date) {
  if (dateType === 'start') {
    return {
      type: EVENTS_START_DATE_CHANGED,
      date: date
    };
  } else if (dateType === 'end') {
    return {
      type: EVENTS_END_DATE_CHANGED,
      date: date
    };
  }
};

export function incrementLoadMoreCounter() {
  return {
    type: EVENTS_INCREMENT_LOAD_MORE
  };
};

export function loadMoreReset() {
  return {
    type: EVENTS_LOAD_MORE_RESET
  };
};

export function setLoadMoreCounter(counter) {
  return {
    type: EVENTS_SET_LOAD_MORE,
    counter: counter
  };
};

export function paginationPageChanged(page, start, end) {
  return {
    type: EVENTS_PAGINATION_CHANGED,
    page: page,
    start: start,
    end: end
  };
}

function calculateTime(date, dateType) {
  let time = date ? new Date(date.split('-').join('/')) : new Date();

  if (dateType === 'start') {
    //time.setHours(0);
    //time.setMinutes(0);
    //time.setSeconds(0);
    time.setMilliseconds(0);
  } else if (dateType === 'end') {
    time.setHours(23);
    time.setMinutes(59);
    time.setSeconds(59);
    time.setMilliseconds(999);
  }

  return time.toISOString();
}

// counter to track request responses and dissmiss all responses except the one
// from first request
let counter = 0;
export function fetchEvents(Start, End, Keyword = null, Location = null,
  Category = null, StartDate = null, EndDate = null, callback = null) {
  return dispatch => {
    let params = {
      Start: Start,
      End: End
    };

    if (Keyword) params['Keyword'] = Keyword;
    if (Location) params['Location'] = Location;
    if (Category) params['Category'] = Category;

    params['FromDate'] = calculateTime(StartDate, 'start');
    if (EndDate) params['ToDate'] = calculateTime(EndDate, 'end');

    dispatch(eventsFetching());
    request();

    function request() {
      $.ajax({
        url: '/api/search/events/GetEvents',
        cache: false,
        data: params,
        method: 'POST'
      }).done((response) => {
        dispatch(eventsGetSuccess(response));
        if (typeof callback === 'function') callback();
      });
    }
  };
};
