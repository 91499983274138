import { createUrlState } from "../../createUrlState.js";

const defaults = {
  specialty: "",
  keyword: "",
  page: 1,
  letter: "",
  gender: "",
  zip: "",
  distance: 5,
  languages: [],
  location: "",
  accepting: "false",
  scheduling: "false",
  mlhc: "false",
  mlhpp: "false",
  wildcard: "true"
};

export default createUrlState(defaults);
