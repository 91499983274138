'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class PatientSpotlightItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      about: PropTypes.string,
      category: PropTypes.string.isRequired,
      image: PropTypes.string,
      link: PropTypes.string.isRequired,
      linkLabel: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
  }

  render() {
    const {item} = this.props;

    return (
      <div className="spotlight--block">
        {item.image &&
          <div className="spotlight__rec-image">
            <a href={item.link}><img width={item.width} height={item.height} src={item.image} alt={item.name} /></a>
          </div>
        }
        <div className="spotlight__content">
          <h4 className="spotlight__label">{item.category}</h4>
          <h3 className="spotlight__name"><a href={item.link}>{item.name}</a></h3>
          {item.about &&
            <blockquote className="spotlight__quote">
              {item.about} <a href={item.link}>{item.linkLabel}</a>
            </blockquote>
          }
          {!item.about &&
            <a href={item.link}>{item.linkLabel}</a>
          }

        </div>
      </div>
    );
  }
}
