'use strict';

import $ from "jquery";
import removeCookie from "./removeCookie";
import moment from "../vendor/moment.js";

import Pikaday from "pikaday";


import JsCookie from "js-cookie";
window.Cookies = JsCookie;

// set up jQuery and plugins
window.jQuery = global.jQuery = $;
window.jQuery.noConflict();

window.moment = global.moment = moment;

require("../vendor/jquery.dropdown.js");
//import Moment from './vendor/moment.js';

require("../vendor/combodate.js");
require("jquery-placeholder");
require("jquery-match-height");
require("flexslider");
require("../vendor/dropit-mod.js");
require("bxslider/dist/jquery.bxslider.min.js");

require("../vendor/bower_components/jQuery.dotdotdot/src/js/jquery.dotdotdot.js");

// flag the page as loaded - assuming file included at bottom of page
window.jQuery("html").addClass("is-dom-ready");

//find devices for hover touch  problems
if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
    $('html').addClass("ios");
}
var isIE = !!window.MSInputMethodContext && !!document.documentMode;
if (isIE) // If Internet Explorer, return version number
{
    $('.site-wrapper__header').addClass('site-wrapper__header-ie-sticky');
}
// Compare Doctors - remove doctor from list
function removeCompareDoctor() {
    // when a remove doctor button is clicked
    $('.js-remove-doctor').on('click', function (event) {
        event.preventDefault();
        let $el = $(this),  // record the current element
            id = $el.data('doctor-id'), // get the ID of the doctor
            // find the parent rows
            $parents = $('.js-doctor-object[data-doctor-id="' + id + '"]');

        // remove the row
        $parents.fadeOut('slow');
        // remove the doctor's id from the cookie
        removeCookie('doctorCompare', id);
    });
}

// calculate the distance from our location to a specified lat/lng coordinate
function distancefromHere() {
    $('.js-distance-from-here').each(function () {
        let lat = $(this).data('lat'),
            lng = $(this).data('lng'),
            distance,
            $el = $(this);

        // if lat and lng are not found
        if (typeof (lat) === 'undefined' || lat.length === 0 ||
            typeof (lng) === 'undefined' || lng.length === 0) {
            // do nothing
            return;
        }

        getDistance(lat, lng)
            .then(function (distance) {
                $el.text(parseInt(distance) + ' miles');
            })
            .catch(function (err) {
                console.log(err.message);
            }
            );
    });
}

// Google webfont loader
function loadFonts() {
    var wf = document.createElement('script'),
        scripts = document.scripts[0];

    // Set the fonts to load
    window.WebFontConfig = {
        google: {
            families: [
                'Montserrat:400,700:latin',
                'Roboto:400,400italic,700,700italic,300italic,300:latin',
                'Arvo::latin'
            ]
        }
    };

    // Add the Google Fonts JS file
    wf.src = '//ajax.googleapis.com/ajax/libs/webfont/1.5.18/webfont.js';
    scripts.parentNode.insertBefore(wf, scripts);
}

// jQuery DotDotDot truncation
function truncate() {
    // for every truncated item on the page
    $('.js-truncate').each(function () {
        var $el = $(this);
        // apply the truncated class to the element
        $el.parent().addClass('is-truncated');
        // apply dotdotdot to the element
        $el.dotdotdot({
            watch: true,
            callback: function (isTruncated, orgContent) {
                if (isTruncated) {
                    $el.parent().find('.js-untruncate').show();
                } else {
                    $el.parent().find('.js-untruncate').hide();
                }
            }
        });
        // bind a click event to undo this
        $el.parent().on('click', '.js-untruncate', function () {
            $el.parent().removeClass('is-truncated');
            $el.trigger('destroy');
            // remove this link from the content
            $(this).remove();
        });
    });
}

function newsletterForm() {
    let emailRegx = /.+?@.+?[.].+/;

    // for each newsletter form on the page
    $('.js-newsletter-form').each(function () {
        let $el = $(this),
            $input = $(this).find('input'),
            $button = $(this).find('button'),
            id = $el.data('id'),
            successMessage = $el.data('success'),
            timer;

        // When the user starts typing
        $input.on('keyup', function (event) {
            // record what the user has entered
            let value = $(this).val();
            // prevent previous data validation
            window.clearTimeout(timer);
            // if the field is empty
            if (value.length === 0) {
                // reset the flags and exit
                $el.removeClass('is-valid is-invalid');
                return;
            }
            // if the enter key is pressed
            if (event.keyCode == 13) {
                $button.click();
                return;
            }
            // when the user pauses typing
        });
        // When the user submits the form
        $button.on('click', function () {
            // record what the user has entered
            let value = $input.val();
            // prevent previous data validation
            window.clearTimeout(timer);
            // prevent the user from submitting another email
            $(this).prop('disabled', true);
            $input.prop('disabled', true);
            // reset the flags
            // $el.removeClass('is-valid is-invalid');
            // send the value to the api
            $.ajax({
                url: '/api/marketo/post/' + value,
                method: 'POST'
            })
                .done((response) => {
                    if (response.status == 400) {
                        $el.removeClass('is-valid').addClass('is-invalid');
                        return false;
                    }
                    // show the success flag
                    $el.removeClass('is-invalid').addClass('is-valid');
                    // display the success message and slowly fade it out
                    $input
                        .val(successMessage)
                        .css({ 'transition': 'color ease 10s', 'color': '#444' });
                    // After the message has faded
                    setTimeout(() => {
                        // reset the input value
                        value = '';
                        $input.val(value).removeAttr('style').prop('disabled', false);
                        // reactivate the button
                        $(this).prop('disabled', false);
                        // remove the success flag
                        $el.removeClass('is-valid');
                    }, 10000);
                })
                .fail((e) => {
                    // console.log('fetch failed', e)
                    // reactivate the input value
                    $input.prop('disabled', false);
                    // show the failed flag
                    $el.addClass('is-invalid');
                    // reactivate the button
                    $(this).prop('disabled', false);
                });
        });
    });

}

/* *********** PUBLIC Functions ************************* */

// return the distance in miles from the current location to the desired one
export function getDistance(lat1, lng1) {
    return new Promise(function (resolve, reject) {
        var dist = 0;

        if (navigator.geolocation) {
            // appears to be async.
            navigator.geolocation.getCurrentPosition(
                function success(position) {
                    //http://www.geodatasource.com/developers/javascript
                    let lat2 = position.coords.latitude,
                        lng2 = position.coords.longitude,
                        radlat1 = Math.PI * lat1 / 180,
                        radlat2 = Math.PI * lat2 / 180,
                        radlng1 = Math.PI * lng1 / 180,
                        radlng2 = Math.PI * lng2 / 180,
                        theta = lng1 - lng2,
                        radtheta = Math.PI * theta / 180;

                    dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) *
                        Math.cos(radlat2) * Math.cos(radtheta);
                    dist = Math.acos(dist);
                    dist = dist * 180 / Math.PI;
                    dist = dist * 60 * 1.1515;
                    resolve(dist);
                },
                function error(err) {
                    reject(new Error('location data incorrect'));
                }
            );
        } else {
            reject(new Error('Geolocation not supported'));
        }
    });
}

function initDateInputs() {
    $('.js-date-input').each(function () {
        if (window.innerWidth > 667) {
            const options = {
                field: $(this)[0],
                position: 'bottom right',
                format: 'MM/DD/YYYY'
            };
            const picker = new Pikaday(options);
        } else {
            $(this).attr("type", "date");
        }
    });
}

function showOrhidePrivacyModal() {
    if (getCookie("privacyCookie") != "true" || getCookie("privacyCookie") == "") {
        $('#privacyModal').css('display', 'block');
    }
}

$('#btnClose').click(function () {
    createCookie("privacyCookie", "true", "")
    closeModal();

});
$('#btnAccept').click(function () {
    closeModal();
    createCookie("privacyCookie", "true", 90);
});

function closeModal() {
    $('#privacyModal').css('display', 'none');
}

function showorhideNorificationBanner() {
    if (getCookie("notificationBannerCookie") != "true" || getCookie("notificationBannerCookie") == "") {
        $('.notification-banner').css('display', 'block');
    }
    else {
        $('.notification-banner').css('display', 'none');
    }
}

$(".notification-banner__close").click(function () {
    createOneHrCookie("notificationBannerCookie", "true", "");
});

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function createCookie(name, value, days) {
    var date, expires;
    if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function createOneHrCookie(name, value, minutes) {
    var date, expires;
    if (minutes) {
        date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

$('#btnHealthBot').on('click', function () {
    if ($('#healthbotIframe').length) {
        $('#healthbotIframe').attr('src', 'https://mlhhealthbot.azurewebsites.net');
    }

    $('#healthBotModal').css('display', 'block');

});

$('#healthBotModal-btnClose').on('click', function () {
    $('#healthbotIframe').attr('src', '');
    $('#healthBotModal').css('display', 'none');
});

//Feedback Modal
// Get the modal
var modal = document.getElementById("feedback-modal");

// Get the button that opens the modal
var feedbackBtn = document.getElementById("feedback-link");

// Get the <span> element that closes the modal
var feedbackClose = document.getElementById("feedback-close");

// When the user clicks the button, open the modal
if (feedbackBtn !== null) {
    feedbackBtn.onclick = function () {
        modal.style.display = "block";
    }
}


// When the user clicks on <span> (x), close the modal
if (feedbackBtn !== null) {
    feedbackClose.onclick = function () {
        modal.style.display = "none";
    }
}


// When the user clicks anywhere outside of the modal, close it
window.onclick = function (event) {
    if (event.target == modal) {
        modal.style.display = "none";
    }
}
//End  Feedback Modal

export function init() {
    // get the google webFonts
    loadFonts();
    //truncate();
    distancefromHere();
    removeCompareDoctor();
    newsletterForm();
    initDateInputs();
    showOrhidePrivacyModal();
    showorhideNorificationBanner();
    //jumpToProfileReviewTab();


}

export default {
    init: init,
    getDistance: getDistance

};

window.DS_OPT = {

    buildSummaryHTML: function (data, ctx) {
        // nrc rating data pullled from Sitecofe rather than NRC widget
    },
    initCommentsWidget: function (data, ctx) {
        if (data && data.valid) {
            //set variable to the profile's create date
            DS_OPT.commentCount = data.profile.bodycount;
            console.log(DS_OPT.commentCount);
            DS_OPT.minimumCommentCount = (DS_OPT.commentCount > 4);
            console.log(DS_OPT.minimumCommentCount);
        } else {
            console.log("data not valid");
        }


    },

    buildCommentsPrefixHTML: function (data, ctx) {
        if (DS_OPT.minimumCommentCount) {
            var profile = data.profile;
            var avgRating = profile.averageRatingStr;
            var revCount = profile.reviewBodyCountStr;
            var x = '<div class="ds-commentprefix"><span class="ds-xofy"><span class="ds-average">' + avgRating + '</span><span class="ds-average-max">out of 5</span></span><span class="ds-ratingcount">(' + revCount + ')</span></div>';

            if (ctx.info.distribution && ctx.info.distribution >= 1 && ctx.info.distribution <= 5) {
                x += '<span class="ds-filter-info">Showing ' + ctx.info.distribution + ' star comments - <span style="color:blue; cursor:pointer;" class="ds-filter-clear-btn" data-ds-rating="clear">show all</span></span>';
            }

            x += '<hr>';
            return x;
        } else {
            return "";
        }
    },

    buildCommentsEntryHTML: function (data, ctx) {

        var visitDate = data.formattedReviewDate;
        var visit = '<span class="ds-pubs"></span>';
        var pubsOn = '';
        var publishDate = '';
        if (ctx.view === 'scheduled') {
            publishDate = data.formattedPublishDate;
            pubsOn = '<br><span class="ds-pubs">Publishes On</span>';
        }

        var bodyMarkup = '';
        var bodyMarkup2 = '';
        var bodyMarkup3 = '';
        if (data.bodyForDisplay && data.bodyForDisplay.length > 0) {
            bodyMarkup = '<p class="ds-body ds-body-full"><b>' + data.bodyText + '</b><br>' + data.bodyForDisplay + '</p>';
        }
        if (data.body2ForDisplay && data.body2ForDisplay.length > 0) {
            bodyMarkup2 = '<p class="ds-body ds-body-full"><b>' + data.bodyText2 + '</b><br>' + data.body2ForDisplay + '</p>';
        }
        if (data.body3ForDisplay && data.body3ForDisplay.length > 0) {
            bodyMarkup3 = '<p class="ds-body ds-body-full"><b>' + data.bodyText3 + '</b><br>' + data.body3ForDisplay + '</p>';
        }

        var templateData = {
            stars: data.rating,
            visitdate: visitDate,
            bodyMarkup: bodyMarkup,
            bodyMarkup2: bodyMarkup2,
            bodyMarkup3: bodyMarkup3,
            author: data.formattedDisplayName,
            visit: visit,
            pubsOn: pubsOn,
            publishDate: publishDate
        };

        var template = [
            '<div class="ds-comment">',
            '<div class="ds-lcol">',
            '<span class="ds-stars ds-stars{{stars}}"></span>',
            '{{visit}}',
            '<span class="ds-date">{{visitdate}}</span>',
            '<br>',
            '{{pubsOn}}',
            '<span class="ds-date">{{publishDate}}</span>',
            '</div>',
            '<div class="ds-rcol">',
            '{{bodyMarkup}}',
            '{{bodyMarkup2}}',
            '{{bodyMarkup3}}',
            '</div>',
            '</div>',
            '<div class="ds-clear"></div>',
            '<hr class="ds-divider">'
        ].join('');

        if (DS_OPT.minimumCommentCount) {
            return ctx.tmpl(template, templateData);

        } else {
            return "";
        }

    },
    //hides the view more link once first ineligible comment appears
    buildCommentsLoadMoreHTML: function (data, ctx) {
        if (DS_OPT.minimumCommentCount) {
            var templateData = {
                moreUrl: data.reviewMeta.moreUrl
            };

            var template = [
                '<div class="ds-comments-more ds-comments-more-placeholder">',
                '<a href="#ds-loadmore" class="ds-comments-more-link" data-more-comments-url="{{moreUrl}}">View More</a>',
                '<span class="ds-comments-more-loading" style="display:none;">Loading...</span>',
                '</div>'
            ].join('');

            return ctx.tmpl(template, templateData);
        } else {
            return "";
        }
    },

    buildCommentsSuffixHTML: function (data, ctx) {
        if (DS_OPT.minimumCommentCount) {
            return '<div class="ds-poweredby"><span>Ratings and Comments Powered by </span><a  target="_blank" href="https://nrchealth.com/platform/star-ratings/">NRC Health</a></div>';
        } else {
            return "";
        }
    }
};

window.onbeforeprint = function () { $('.accordion-menu input[type=checkbox]').prop("checked", false); };
window.onafterprint = function () { $('.accordion-menu input[type=checkbox]').prop("checked", true); };

