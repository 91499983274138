"use strict";

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryStringApp from "query-string";
import DoctorAcceptingScheduling from "./DoctorAcceptingScheduling";
import {
  fetchList,
  fetchFiltersAndLabels,
  zipCodeChanged
} from "../../actions/doctors";
import { fetchDoctorSpecialtiesList } from "../../actions/specialties";
import Application from "../../constants/application";
import SearchBar from "../shared/SearchBarStateless";
import AlphabetPicker from "../shared/AlphabetPicker";
import DoctorsMoreFilters from "./DoctorsMoreFilters";
import Pagination from "../shared/Pagination";
import DoctorsItem from "./DoctorsItem";
import SelectTwo from "../shared/SelectTwo";
import UrlState from "./urlState.js";
// import R from "ramda";

const { getUrlState, getDefaults, mergeUrlQuery } = UrlState;

const { DefaultPagination } = Application;

@connect(state => ({
  doctors: state.doctors,
  specialties: state.specialties
}))
export default class DoctorSearch extends Component {
  static propTypes = {
    compareLink: PropTypes.string.isRequired
  };

  constructor() {
    super();

    this.state = {};

    this.keywordChanged = this.keywordChanged.bind(this);
    this.keywordFetch = this.keywordFetch.bind(this);
    this.letterSelected = this.letterSelected.bind(this);
    this.zipChanged = this.zipChanged.bind(this);
    this.zipFetch = this.zipFetch.bind(this);
    this.distanceSelected = this.distanceSelected.bind(this);
    this.locationSelected = this.locationSelected.bind(this);
    this.languagesSelected = this.languagesSelected.bind(this);
    this.genderSelected = this.genderSelected.bind(this);
    this.mlhCareCheckboxClick = this.mlhCareCheckboxClick.bind(this);
    this.mlhppCheckboxClick = this.mlhppCheckboxClick.bind(this);
    this.acceptingCheckboxClick = this.acceptingCheckboxClick.bind(this);
    this.schedulingCheckboxClick = this.schedulingCheckboxClick.bind(this);
    this.paginationPageChanged = this.paginationPageChanged.bind(this);
    this.createMoreFilterLink = this.createMoreFilterLink.bind(this);
    this.filterChanged = this.filterChanged.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { zip } = getUrlState();

    if (zip) {
      dispatch(zipCodeChanged(zip, true));
    }

    this.fetchFiltersAndLabels(() => {
      this.fetchPaginatedSpecialties();
    });

    dispatch(fetchList());
  }

  fetchFiltersAndLabels(callback) {
    const { dispatch } = this.props;

    dispatch(fetchFiltersAndLabels(callback));
  }

  /**
   * filterChanged takes to string values
   * value is the string value for the url or null
   * param is the name of the param to populate or remove
   */
  filterChanged(param, value = null) {
    const { page } = getDefaults();
    this.props.router.push(
      mergeUrlQuery({
        [param]: value,
        page
      })
    );
  }

  keywordChanged(keyword) {
    const { page, specialty, letter } = getDefaults();

    this.props.router.push(
      mergeUrlQuery({
        keyword,
        page,
        specialty,
        letter
      })
    );
  }

  keywordFetch(keyword) {
    // handled in change
  }

  letterSelected(letter) {
    // letter is self contained
  }

  zipChanged(zip, zipValidity) {
    if (zipValidity) {
      this.filterChanged("zip", zip);
    }

    const { dispatch } = this.props;
    dispatch(zipCodeChanged(zip, zipValidity));
  }

  zipFetch(zip, zipValidity) {
    // handled in change
  }

  distanceSelected(option) {
    this.filterChanged("distance", option);
  }

  locationSelected(option) {
    this.filterChanged("location", option);
  }

  languagesSelected(options) {
    const query = queryStringApp.parse(location.search);

    if (!options || options.length === 0) {
      query.languages = [];
    } else {
      query.languages = options;
    }

    this.props.router.push({
      pathname: window.location.pathname,
      query
    });
  }

  genderSelected(option) {
    this.filterChanged("gender", option);
  }

  mlhCareCheckboxClick(checked) {
    this.filterChanged("mlhc", checked);
  }

  mlhppCheckboxClick(checked) {
    this.filterChanged("mlhpp", checked);
  }

  acceptingCheckboxClick(checked) {
    this.filterChanged("accepting", checked);
  }
  schedulingCheckboxClick(checked) {
    this.filterChanged("scheduling", checked);
  }
  paginationPageChanged(start, end, page) {
    // pagination is self contained
  }

  /**
   * @param (option) type string
   */
  specialtySelected(option) {
    const { page, letter, keyword } = getDefaults();

    let specialty =
      option != "00000000-0000-0000-0000-000000000000"
        ? option
        : getDefaults().specialty;

    this.props.router.push(
      mergeUrlQuery({
        keyword,
        page,
        specialty,
        letter
      })
    );
  }

  createMoreFilterLink() {
    const { page, letter, keyword, specialty } = getDefaults();

    return mergeUrlQuery({
      keyword,
      page,
      specialty,
      letter
    }).query;
  }

  fetchPaginatedSpecialties() {
    const { dispatch, doctors } = this.props;

    dispatch(
      fetchDoctorSpecialtiesList(
        1,
        999,
        null,
        null,
        // dispatch(fetchSpecialtiesList(1, 999, null, null,
        () => {
          const any = {
            name: doctors.labels.anySpecialty,
            value: "00000000-0000-0000-0000-000000000000"
          };
          if (
            !this.props.specialties.doctorList.find(
              obj => obj.name === doctors.labels.anySpecialty
            )
          ) {
            this.props.specialties.doctorList.unshift(any);
          }
          this.setState({
            specialties: this.props.specialties.doctorList
          });
        }
      )
    );
  }

  renderPagination(position, doctors) {
    const { page } = getUrlState();

    return (
      <Pagination
        collectionTotal={doctors.listTotal}
        currentPage={Number(page)}
        paginationCount={DefaultPagination}
        paginationPosition={position}
        paginationShowing="Showing"
        paginationAll="all"
        paginationOf="of"
        paginationResults="results"
      />
    );
  }

  renderList(list, compareLink, labels) {
    return list.map((item, index) => {
      return (
        <DoctorsItem
          key={index}
          item={item}
          compareLink={compareLink}
          labels={labels}
        />
      );
    });
  }

  render() {
    const { doctors, compareLink } = this.props;
    const { specialties } = this.state;

    const { specialty, letter, keyword } = getUrlState();
    let labels = doctors.labels;
    let filters = doctors.filters;
    let filterValues = doctors.filterValues;
    // let AcceptSchdeuleFilters = R.pick(filters.acceting)
    const keywordValue = specialty || letter ? "" : keyword;

    return (
      <div>
        <div className="l-block l-block-top">
          <div className="container">
            {labels && specialties ? (
              <div className="form-center">
                <div className="form-inline form-flex">
                  <div className="form-left">
                    <SelectTwo
                      options={specialties || []}
                      optionSelected={
                        specialty || "00000000-0000-0000-0000-000000000000"
                      }
                      onOptionSelect={option => this.specialtySelected(option)}
                      labelName={labels.serviceSpecialty}
                    />
                    <DoctorAcceptingScheduling
                      labels={labels}
                      filters={filters}
                      acceptingCheckboxClick={this.acceptingCheckboxClick}
                      schedulingCheckboxClick={this.schedulingCheckboxClick}
                    />
                  </div>
                  <div className="form-middle">
                    {/* <span>
                      {labels.filterOr}
                    </span> */}

                    <SearchBar
                      labelName={labels.filterKeyword}
                      placeholderName={labels.filterKeywordPlaceholder}
                      onTextChange={this.keywordChanged}
                      fetchWithText={this.keywordFetch}
                      inputValue={keywordValue}
                    />
                  </div>
                  <div className="form-right">
                    <AlphabetPicker
                      labelName={labels.filterLastName}
                      onLetterSelect={this.letterSelected}
                      selectedLetter={letter || ""}
                      path={window.location.pathname}
                      filters={this.createMoreFilterLink()}
                    />
                  </div>
                </div>
                <DoctorsMoreFilters
                  labels={labels}
                  filters={filters}
                  filterValues={filterValues}
                  onZipChanged={this.zipChanged}
                  onZipFetch={this.zipFetch}
                  onDistanceSelected={this.distanceSelected}
                  onLocationSelected={this.locationSelected}
                  onLanguagesSelected={this.languagesSelected}
                  onGenderSelected={this.genderSelected}
                  onMlhCheckbox={this.mlhCareCheckboxClick}
                  onMlhppCheckbox={this.mlhppCheckboxClick}
                />
              </div>
            ) : (
              <img src="/assets/images/gif/squares.gif" className="spinner" />
            )}
          </div>
        </div>
        <div className="l-block l-block--gray l-block--border-top">
          <div className="container">
            {doctors.labels ? (
              <div className="results">
                {doctors.fetching && (
                  <img
                    src="/assets/images/gif/squares.gif"
                    className="spinner"
                  />
                )}
                {doctors.list.length > 0 && (
                  <div>
                    {this.renderPagination("top", doctors)}

                    {this.renderList(doctors.list, compareLink, labels)}

                    {this.renderPagination("bottom", doctors)}
                  </div>
                )}
                {doctors.listTotal === 0 && (
                  <div className="results__empty">
                    <p>No results found</p>
                  </div>
                )}
              </div>
            ) : (
              <img src="/assets/images/gif/squares.gif" className="spinner" />
            )}
          </div>
        </div>
      </div>
    );
  }
}
