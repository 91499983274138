'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {fetchBlogArticle, keywordChanged, categoryChanged, categoryDeselected,
  incrementLoadMoreCounter, loadMoreReset, setLoadMoreCounter, paginationPageChanged}
  from '../../actions/blogArticle';
import NewsBlogEventsSearch from '../shared/NewsBlogEventsSearch';
import NewsBlogArticleSearchItem from '../shared/NewsBlogArticleSearchItem';

@connect(state => ({
  blogArticle: state.blogArticle
}))

export default class BlogArticle extends Component {
  static propTypes = {
    blogContentTypes: PropTypes.array.isRequired,
    keywordLabel: PropTypes.string.isRequired,
    keywordPlaceholder: PropTypes.string.isRequired,
    searchButton: PropTypes.string.isRequired,
    noResults: PropTypes.string.isRequired,
    linkName: PropTypes.string.isRequired,
    categoryLabel: PropTypes.string.isRequired,
    paginationShowing: PropTypes.string.isRequired,
    paginationAll: PropTypes.string.isRequired,
    paginationOf: PropTypes.string.isRequired,
    paginationResults: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.fetchPaginatedBlogArticle = this.fetchPaginatedBlogArticle.bind(this);
    this.onKeywordChanged = this.onKeywordChanged.bind(this);
    this.onCategoryChanged = this.onCategoryChanged.bind(this);
    this.onCategoryDeselected = this.onCategoryDeselected.bind(this);
    this.onPaginationPageChanged = this.onPaginationPageChanged.bind(this);
  }

  // Our shared NewsBlogArticleSearch component will return location, startDate
  // and endDate parameters so we have to expect although we're not using them
  fetchPaginatedBlogArticle(start, end, keyword, location, category, startDate,
    endDate, cb) {
      const {dispatch} = this.props;

      dispatch(fetchBlogArticle(start, end, keyword, category, cb));
  }

  onKeywordChanged(keyword) {
    const {dispatch} = this.props;

    dispatch(keywordChanged(keyword));
  }

  onCategoryChanged(category) {
    const {dispatch} = this.props;

    dispatch(categoryChanged(category));
  }

  onCategoryDeselected() {
    const {dispatch} = this.props;

    dispatch(categoryDeselected());
  }

  onIncrementLoadMoreCounter() {
    const {dispatch} = this.props;

    dispatch(incrementLoadMoreCounter());
  }

  onPaginationPageChanged(page, start, end) {
    const {dispatch} = this.props;

    window.scrollTo(0, 200);

    dispatch(paginationPageChanged(page, start, end));
  }

  renderList(list, linkName) {
    return list.map((item, index) => {
      return (
        <li className='list__item list__item--blog' key={index}>
          <NewsBlogArticleSearchItem article={item} linkName={linkName} />
        </li>
      );
    });
  }

  render() {
    const {dispatch, blogArticle, blogContentTypes, linkName, ...other} =
      this.props;

    return <NewsBlogEventsSearch
             searchState={blogArticle}
             collectionTypes={blogContentTypes}
             fetchPaginatedList={this.fetchPaginatedBlogArticle}
             onKeywordChanged={this.onKeywordChanged}
             onCategoryChanged={this.onCategoryChanged}
             onCategoryDeselected={this.onCategoryDeselected}
             onIncrementLoadMoreCounter={this.onIncrementLoadMoreCounter}
             onPaginationPageChanged={this.onPaginationPageChanged}
             {...other}>
             {this.renderList(blogArticle.list, linkName)}
           </NewsBlogEventsSearch>
  }
};
