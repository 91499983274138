'use strict';

import $ from 'jquery';
import Actions from '../constants/actions';

const {
  REQUEST_NEWS_ARTICLE_SUCCESS, 
  REQUEST_NEWS_ARTICLE_LOAD_MORE_SUCCESS,
  REQUEST_NEWS_ARTICLE_FETCHING, 
  REQUEST_NEWS_ARTICLE_LOAD_FETCHING,
  NEWS_ARTICLE_KEYWORD_CHANGED, 
  NEWS_ARTICLE_LOCATION_SELECTED,
  NEWS_ARTICLE_LOCATION_DESELECTED, 
  NEWS_ARTICLE_CATEGORY_CHANGED,
  NEWS_ARTICLE_CATEGORY_DESELECTED, 
  NEWS_ARTICLE_INCREMENT_LOAD_MORE,
  NEWS_ARTICLE_LOAD_MORE_RESET, 
  NEWS_ARTICLE_SET_LOAD_MORE,
  NEWS_ARTICLE_PAGINATION_CHANGED 
} = Actions;

function newsArticleGetSuccess(data) {
  return {
    type: REQUEST_NEWS_ARTICLE_SUCCESS,
    list: data.articles,
    listTotal: data.totalResults
  };
}

function newsArticleLoadMoreSuccess(data) {
  return {
    type: REQUEST_NEWS_ARTICLE_LOAD_MORE_SUCCESS,
    list: data.articles,
    listTotal: data.totalResults
  };
}

function newsArticleFetching() {
  return {
    type: REQUEST_NEWS_ARTICLE_FETCHING
  };
}

function newsArticleLoadMoreFetching() {
  return {
    type: REQUEST_NEWS_ARTICLE_LOAD_FETCHING
  };
}

export function keywordChanged(keyword) {
  return {
    type: NEWS_ARTICLE_KEYWORD_CHANGED,
    keyword: keyword
  };
}

export function locationSelected(locationKey) {
  return {
    type: NEWS_ARTICLE_LOCATION_SELECTED,
    locationKey: locationKey
  };
}

export function locationDeselected() {
  return {
    type: NEWS_ARTICLE_LOCATION_DESELECTED
  };
}

export function categoryChanged(category) {
  return {
    type: NEWS_ARTICLE_CATEGORY_CHANGED,
    category: category
  };
}

export function categoryDeselected() {
  return {
    type: NEWS_ARTICLE_CATEGORY_DESELECTED
  };
}

export function incrementLoadMoreCounter() {
  return {
    type: NEWS_ARTICLE_INCREMENT_LOAD_MORE
  };
}

export function loadMoreReset() {
  return {
    type: NEWS_ARTICLE_LOAD_MORE_RESET
  };
}

export function setLoadMoreCounter(counter) {
  return {
    type: NEWS_ARTICLE_SET_LOAD_MORE,
    counter: counter
  };
}

export function paginationPageChanged(page, start, end) {
  return {
    type: NEWS_ARTICLE_PAGINATION_CHANGED,
    page: page,
    start: start,
    end: end
  };
}

// counter to track request responses and dissmiss all responses except the one
// from first request
export function fetchNewsArticle(Start, End, Keyword = null, Location = null,
  Category = null, callback = null) {
  return dispatch => {
    let params = {
      Start: Start,
      End: End
    };

    if (Keyword) params['Keyword'] = Keyword;
    if (Location) params['Location'] = Location;
    if (Category) params['Category'] = Category;

    dispatch(newsArticleFetching());
    request();

    function request() {
      $.ajax({
        url: '/api/search/articles/getnews',
        cache: false,
        data: params,
        method: 'POST'
      }).done((response) => {
        dispatch(newsArticleGetSuccess(response));
        if (typeof callback === 'function') callback();
      });
    }
  };
}
