'use strict';

import reducer from '../reducers/index';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';


export const finalCreateStore = compose(
  applyMiddleware(thunkMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? 
    window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
)(createStore);

export const enhancerWithRoutingMiddleware = (routingMiddleware) => {
    const composeEnhancers =
      typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

    return composeEnhancers(
    applyMiddleware(routingMiddleware, thunkMiddleware)
    );
};

const store = finalCreateStore(reducer);
export default store;