'use strict';

let id = 0;
export function generate() {
  return ++id;
}

export default {
  generate: generate
};
