export default Object.freeze({
  DefaultPagination: 10, // should be integer value 1 or higher
  DefaultSmartphonePagination: 2, // should be integer value 1 or higher
  DefaultTimeout: 300, // in milliseconds
  DefaultDistanceDropdown: [
    {
      name: '5 Miles',
      value: 5 // should be unique, no duplicate values in other objects
    }, {
      name: '10 Miles',
      value: 10
    }, {
      name: '20 Miles',
      value: 20
    }
  ], // should always contain at least 2 objects of {name, value} format
  DefaultZipCodeLength: 5,
  DefaultKeywordFetchLength: 3, // fetch after this number of letters
  DefaultGoogleMapZoom: 15,
  DefaultSmartphoneBreakpoint: 600, // in pixels
  SearchComponentClass: '.js-search-component',
  CookieName: 'doctorCompare'
});
