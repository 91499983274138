'use strict';

import Actions from '../constants/actions';

const {
  REQUEST_MEGA_MENU_SUCCESS,
  MEGA_MENU_KEYWORD_CHANGED,
  MEGA_MENU_RESET_LIST,
  MEGA_MENU_ACTIVE_TAB_CHANGED,
  MEGA_MENU_SPECIALTY_CHANGED
} = Actions;

const initialState = {
  list: [],
  listTotal: 0,
  doctorsBySpecialty: [],
  keyword: null,
  activeTab: null,
  specialty: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MEGA_MENU_SUCCESS:
      return Object.assign({}, state, {
        list: action.list,
        listTotal: action.listTotal,
        doctorsBySpecialty: action.doctorsBySpecialty || []
      });
    case MEGA_MENU_KEYWORD_CHANGED:
      return Object.assign({}, state, {
        keyword: action.keyword
      });
    case MEGA_MENU_SPECIALTY_CHANGED:
      return Object.assign({}, state, {
        specialty: action.specialty,
        keyword: action.specialty
      });
    case MEGA_MENU_RESET_LIST:
      return Object.assign({}, state, {
        list: [],
        listTotal: 0,
        doctorsBySpecialty: []
      });
    case MEGA_MENU_ACTIVE_TAB_CHANGED:
      return Object.assign({}, state, {
        activeTab: action.tab,
        list: [],
        listTotal: 0,
        doctorsBySpecialty: []
      });
    default:
      return state;
  }
};
