import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UrlState from "./urlState.js";
import Checkbox from "../shared/Checkbox";

const {
    getUrlState
} = UrlState;
export default class DoctorAcceptingScheduling extends Component {
  render() {
    const labels = this.props.labels;

    const { accepting, scheduling } = getUrlState();
    return (
      <div>
        <fieldset>
          <Checkbox
            labelName={labels.doctorsAcceptingCheckbox}
            checkedState={accepting === "true"}
            onCheckboxChange={this.props.acceptingCheckboxClick}
          />
          <Checkbox
            labelName={labels.doctorSchedulingCheckbox}
            checkedState={scheduling === "true"}
            onCheckboxChange={this.props.schedulingCheckboxClick}
          />
        </fieldset>
      </div>
    );
  }
}
