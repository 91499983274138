'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {fetchEvents, keywordChanged, locationSelected, locationDeselected,
  categoryChanged, categoryDeselected, incrementLoadMoreCounter, loadMoreReset,
  setLoadMoreCounter, dateChanged, paginationPageChanged} from '../../actions/events';
import NewsBlogEventsSearch from '../shared/NewsBlogEventsSearch';
import EventsItem from './EventsItem';

@connect(state => ({
  events: state.events
}))

export default class Events extends Component {
  static propTypes = {
    locationsDictionary: PropTypes.array.isRequired,
    eventSearchTypes: PropTypes.array.isRequired,
    keywordLabel: PropTypes.string.isRequired,
    keywordPlaceholder: PropTypes.string.isRequired,
    fromDateLabel: PropTypes.string.isRequired,
    toDateLabel: PropTypes.string.isRequired,
    locationsLabel: PropTypes.string.isRequired,
    locationsDefault: PropTypes.string.isRequired,
    searchButton: PropTypes.string.isRequired,
    noResults: PropTypes.string.isRequired,
    toLabel: PropTypes.string.isRequired,
    fromLabel: PropTypes.string.isRequired,
    costLabel: PropTypes.string.isRequired,
    seatsLabel: PropTypes.string.isRequired,
    categoryLabel: PropTypes.string.isRequired,
    paginationShowing: PropTypes.string.isRequired,
    paginationAll: PropTypes.string.isRequired,
    paginationOf: PropTypes.string.isRequired,
    paginationResults: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.fetchPaginatedEvents = this.fetchPaginatedEvents.bind(this);
    this.onKeywordChanged = this.onKeywordChanged.bind(this);
    this.onLocationSelected = this.onLocationSelected.bind(this);
    this.onLocationDeselected = this.onLocationDeselected.bind(this);
    this.onCategoryChanged = this.onCategoryChanged.bind(this);
    this.onCategoryDeselected = this.onCategoryDeselected.bind(this);
    this.onDateChanged = this.onDateChanged.bind(this);
    this.onPaginationPageChanged = this.onPaginationPageChanged.bind(this);
  }

  fetchPaginatedEvents(start, end, keyword, location, category, startDate,
    endDate, cb) {
      const {dispatch} = this.props;

      dispatch(fetchEvents(start, end, keyword, location, category, startDate,
        endDate, cb));
  }

  onKeywordChanged(keyword) {
    const {dispatch} = this.props;

    dispatch(keywordChanged(keyword));
  }

  onLocationSelected(location) {
    const {dispatch} = this.props;

    dispatch(locationSelected(location));
  }

  onLocationDeselected() {
    const {dispatch} = this.props;

    dispatch(locationDeselected());
  }

  onCategoryChanged(category) {
    const {dispatch} = this.props;

    dispatch(categoryChanged(category));
  }

  onCategoryDeselected() {
    const {dispatch} = this.props;

    dispatch(categoryDeselected());
  }

  onDateChanged(dateType, date) {
    const {dispatch} = this.props;

    dispatch(dateChanged(dateType, date));
  }

  onPaginationPageChanged(page, start, end) {
    const {dispatch} = this.props;

    window.scrollTo(0, 200);

    dispatch(paginationPageChanged(page, start, end));
  }

  renderList(list, toLabel, fromLabel, costLabel, seatsLabel) {
    return list.map((item, index) => {
      return (
        <li className='list__item list__item--event' key={index}>
          <EventsItem event={item}
                      toLabel={toLabel}
                      fromLabel={fromLabel}
                      costLabel={costLabel}
                      seatsLabel={seatsLabel} />
        </li>
      );
    });
  }

  render() {
    const {dispatch, events, locationsDictionary, eventSearchTypes, toLabel,
      fromLabel, costLabel, seatsLabel, ...other} = this.props;

    return <NewsBlogEventsSearch
             searchState={events}
             locationsDictionary={locationsDictionary}
             collectionTypes={eventSearchTypes}
             fetchPaginatedList={this.fetchPaginatedEvents}
             onSetLoadMoreCounter={this.onSetLoadMoreCounter}
             onLoadMoreReset={this.onLoadMoreReset}
             onKeywordChanged={this.onKeywordChanged}
             onLocationSelected={this.onLocationSelected}
             onLocationDeselected={this.onLocationDeselected}
             onCategoryChanged={this.onCategoryChanged}
             onCategoryDeselected={this.onCategoryDeselected}
             onIncrementLoadMoreCounter={this.onIncrementLoadMoreCounter}
             onDateChanged={this.onDateChanged}
             onPaginationPageChanged={this.onPaginationPageChanged}
             {...other}>
             {this.renderList(events.list, toLabel, fromLabel, costLabel,
               seatsLabel)}
           </NewsBlogEventsSearch>
  }
};
