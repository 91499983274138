'use strict';

import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class EventListType extends Component {
  static propTypes = {
    event: PropTypes.shape({
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string.isRequired,
      eventLocation: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
      readMoreLink: PropTypes.string.isRequired
    }).isRequired,
    linkName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    const { event } = this.props;

    this.startDate = new Date(event.startTime);
    this.endDate = new Date(event.endTime);
  }

  getShortTime(date) {
    let hr = date.getHours();
    let mins = date.getMinutes();

    let amPm = hr < 12 ? 'AM' : 'PM';
    hr = hr % 12;
    hr = hr === 0 ? 12 : hr;
    mins = mins < 10 ? ('0' + mins) : mins;

    return hr + ':' + mins + amPm;
  }

  getShortMonth(date) {
    let month = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    return month[date.getMonth()];
  }

  createMarkup(stringMarkup) {
    return { __html: stringMarkup };
  }

  render() {
    const { event, linkName } = this.props;

    return (
      <div className='list__item--event'>
        <div className='list__meta'>
          <span className='list__type'>Events</span>
          <span className='list__location'>{event.eventLocation}</span>
          <span className='list__time'>
            {this.getShortTime(this.startDate)}
            &nbsp;to&nbsp;
            {this.getShortTime(this.endDate)}
          </span>
        </div>
        <span className='list__event-date'>{this.getShortMonth(this.startDate)}
          <span className='list__event-date__day'>
            {this.startDate.getDate()}
          </span>
          <span className='list__event-date__year'>
            {this.startDate.getFullYear()}
          </span>
        </span>
        <div className='list__content'>
          <h4 className='list__title'>
            <a href={event.readMoreLink}>{event.title}</a>
          </h4>
          <p dangerouslySetInnerHTML={this.createMarkup(event.summary)}></p>
          <a className='link' href={event.readMoreLink}>{linkName}</a>
        </div>
      </div>
    );
  }
};
