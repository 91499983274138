import React, { Component } from 'react';
import Application from '../../constants/application';
import uniqueId from '../../../common/uniqueId';
import URL from '../../../common/urlManipulator';
import $ from 'jquery';

import PropTypes from "prop-types";

const { 
  DefaultTimeout, 
  DefaultKeywordFetchLength 
} = Application;

export default class SearchBar extends Component {
  static propTypes = {
    labelName: PropTypes.string,
    placeholderName: PropTypes.string.isRequired,
    onTextChange: PropTypes.func.isRequired,
    fetchWithText: PropTypes.func,
    noAutomaticFetch: PropTypes.bool,
    inputValue: PropTypes.string.isRequired,
    hideSearchButton: PropTypes.bool,
    magnifyingGlassLink: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.inputChanged = this.inputChanged.bind(this);
    this.enterPressed = this.enterPressed.bind(this);
    this.searchButtonPressed = this.searchButtonPressed.bind(this);

    this.forId = uniqueId.generate();
  }

  inputChanged(e) {
    e.preventDefault();

    let value = e.target.value;

    const { onTextChange, fetchWithText, noAutomaticFetch } = this.props;

    onTextChange(value);

    if (noAutomaticFetch || !fetchWithText) {
      return;
    }

    if (value.length < DefaultKeywordFetchLength && value.length !== 0) {
      return;
    }

    if (this.timeoutFunc) {
      clearTimeout(this.timeoutFunc);
    }

    this.timeoutFunc = setTimeout(() => {
      fetchWithText(value);
    }, DefaultTimeout);
  }

  searchButtonPressed(isEnter) {
    const { inputValue, fetchWithText, noAutomaticFetch, magnifyingGlassLink } =
      this.props;

    if (magnifyingGlassLink) {
      return URL.goToCrossBrowser(magnifyingGlassLink);
    }

    if (!isEnter && noAutomaticFetch) {
      return;
    }

    if (inputValue.length < DefaultKeywordFetchLength && inputValue.length !== 0) {
      return;
    }

    fetchWithText(inputValue);
  }

  enterPressed(e) {
    if (e.charCode === 13) {
      this.searchButtonPressed(true);
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.isActive === true) {
      setTimeout(() => {
        // document.activeElement.blur();
        this.box.focus();
      }, 900);
    }
  }

  render() {
    const { labelName, placeholderName, inputValue, hideSearchButton } =
      this.props;

    return (
      <div>
        {!!labelName &&
          <label className='label' htmlFor={`search-bar-${this.props.tabId}`}>{labelName}</label>
        }
        <div className='search-wrap'>
          <input id={`search-bar-${this.props.tabId}`}
            type='text'
            placeholder={placeholderName}
            title={placeholderName}
            onChange={this.inputChanged}
            value={inputValue}
            onKeyPress={this.enterPressed}
            autoFocus={true}
            ref={el => { this.box = el; }} />
          {!hideSearchButton &&
            <button className='button postfix-overlay'
              onClick={this.searchButtonPressed}>
              <i className='fa fa-search'></i>
            </button>
          }
        </div>
      </div>
    );
  }
}
